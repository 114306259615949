<!--
 * @Author: your name
 * @Date: 2021-07-21 09:37:11
 * @LastEditTime: 2021-07-21 11:42:34
 * @LastEditors: Please set LastEditors
 * @Description: 整车
 * @FilePath: /information-vue/code/src/views/announcement/announcementSearch/Vehicle.vue
-->
<template>
  <router-view v-if="$route.name == '选装内容' || $route.name == '历史'" />
  <PageContainer v-else>
    <!--            <VnotLogin v-if="!show" title="公告" text="公告栏目围绕公告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新公告" :showTips="showTips" />-->
    <template>
      <div>
        <el-form class="g-form" label-width="96px">
          <UnfurlAndFold title="公告信息">
            <el-row>
              <el-col :span="16">
                <el-form-item label="公告批次：">
                  <div flex="c">
                    <el-radio-group v-model="form.pc" @change="pcRadio">
                      <el-radio label="">不限</el-radio>
                      <el-radio v-for="item in pcData" :key="item" :label="item" />
                    </el-radio-group>
                    <el-input
                      v-model="pcinput"
                      size="small"
                      placeholder="输入批次"
                      style="width: 90px"
                      @input="pcFun"
                    />
                  </div>
                </el-form-item>
              </el-col>
              <!--                <el-col :span="8">-->
              <!--                  <el-form-item label="撤销情况：">-->
              <!--                    <el-radio-group v-model="form.cxbj">-->
              <!--                      <el-radio label="">不限</el-radio>-->
              <!--                      <el-radio :label="item.value" v-for="item in cxlx" :key="item.value">{{ item.label }}</el-radio>-->
              <!--                    </el-radio-group>-->
              <!--                  </el-form-item>-->
              <!--                </el-col>-->
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="推荐车型：">
                  <el-checkbox v-model="tjcx" label="在目录中" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="道路达标：">
                  <el-checkbox v-model="dldb" label="在目录中" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="免征购置税：">
                  <el-checkbox v-model="mzgzs" label="在目录中" size="small" />
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="生产企业">
            <el-row>
              <el-col :span="8">
                <el-form-item label="名称：">
                  <el-input v-model="form.qymc" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="地址">
                  <el-input v-model="form.scdz" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>

            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="整车信息">
            <el-row>

              <el-col :span="8">
                <el-form-item label="产品名称：">
                  <el-input v-model="form.cpmc" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="产品型号：">
                  <el-input v-model="form.cpxh" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <!--                              <el-col :span="8">-->
              <!--                                <el-form-item label="产品状态：">-->
              <!--                                  <el-input v-model="form.bgkz" placeholder="请输入内容" size="small"></el-input>-->
              <!--                                </el-form-item>-->
              <!--                              </el-col>-->
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="燃料种类：">
                  <el-input v-model="form.rlzl" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="产品商标：">
                  <el-input v-model="form.cpsb" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="产品号：">
                  <el-input v-model="form.cph" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="车辆类型：">
                  <div style="display: flex">
                    <el-checkbox v-model="cllx" label="不限" @change="carcheckAll" />
                    <el-checkbox-group v-model="form.cllx" @change="carcheck">
                      <el-checkbox v-for="item in cllxData" :key="item.value" :label="item.value">{{ item.label }}
                      </el-checkbox>
                    </el-checkbox-group>
                    <!-- <el-checkbox-group v-model="form.cllx" @change="carcheck">
  <el-checkbox
    :label="item"
    v-for="item in cllxData"
    :key="item"
  ></el-checkbox>
</el-checkbox-group> -->
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="底盘信息">
            <el-row>
              <el-col :span="8">
                <el-form-item label="底盘企业：">
                  <el-input v-model="form.mdpqy" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="底盘ID：">
                  <el-input v-model="form.mdpid" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="底盘型号：">
                  <el-input v-model="form.mdpxh" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="底盘类别：">
                  <div style="display: flex">
                    <el-checkbox v-model="mdplb" label="不限" @change="mdplbAll" />
                    <el-checkbox-group v-model="form.mdplb" @change="mdplbcheck">
                      <el-checkbox v-for="item in mdplbData" :key="item" :label="item" />
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="发动机信息">
            <el-row>
              <el-col :span="8">
                <el-form-item label="生产企业：">
                  <el-input v-model="form.mfqy" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="型号：">
                  <el-input v-model="form.mfdj" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="更多查询条件">
            <el-row class="small-input">
              <el-col :span="8">
                <el-form-item label="外形长：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.changStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.changEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;mm</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="外形宽：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.kuanStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.kuanEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;mm</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="外形高：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.gaoStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.gaoEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;mm</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row class="small-input">
              <el-col :span="8">
                <el-form-item label="总质量：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.zzlStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.zzlEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kg</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="额定载质量：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.edzlStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.edzlEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kg</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="整备质量：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.zbzlStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.zbzlEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kg</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row class="small-input">
              <el-col :span="8">
                <el-form-item label="发动机排量：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.mfplStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.mfplEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;ml</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发动机功率：">
                  <el-row type="flex" align="middle">
                    <el-col :span="6">
                      <el-input
                        v-model="form.mfglStart"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2" class="text-center">-</el-col>
                    <el-col :span="6">
                      <el-input
                        v-model="form.mfglEnd"
                        size="small"
                        oninput="value=value.replace(/[^0-9.]/g,'')"
                      />
                    </el-col>
                    <el-col :span="2">&nbsp;&nbsp;kw</el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="轴数：">
                  <div style="display: flex">
                    <el-checkbox v-model="ghzhsh" label="不限" @change="ghzhshAll" />
                    <el-checkbox-group v-model="form.ghzhsh" @change="ghzhshcheck">
                      <el-checkbox v-for="item in ghzhshData" :key="item" :label="item" />
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item label="其他参数：">
                  <el-select v-model="form.otherParam" placeholder="请选择" size="small">
                    <el-option v-for="item in other" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="参数值：">
                  <el-input v-model="form.otheParamValue" placeholder="请输入内容" size="small" />
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <div class="text-center" style="margin-top: 20px">
            <el-button class="reset-button" @click="reset">重置</el-button>
            <el-button class="search-button" @click="searchForm">查询</el-button>
          </div>
        </el-form>
      </div>
      <div>
        <div flex="sb">
          <span class="table_title" />
          <div>
            <el-button type="primary" plain @click="contrast">一键对比（{{ contrastData.length }}）</el-button>
            <el-button v-has-role="'freeusers'" type="primary" plain @click="excelBtn">导出（{{
              exportData.length
            }}）
            </el-button>
            <el-button
              v-if="isShow"
              id="exportBt"
              type="primary"
              plain :loading="loadingExport"
              @click="exportValidGGZc"
            >导出有效车型
            </el-button>
          </div>
        </div>
        <el-table
          ref="newTable"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA', color: '#000' }"
          stripe
          @select="selectionChange"
          @selection-change="selectionChange"
          @sort-change="tableSort"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column sortable="custom" prop="qymc" label="企业名称" min-width="150" />
          <el-table-column prop="cpmc" label="产品名称" min-width="100" />
          <el-table-column sortable="custom" prop="cph" label="产品号" min-width="100" />
          <el-table-column sortable="custom" prop="cpxh" label="产品型号" min-width="100" />
          <el-table-column sortable="custom" prop="pc" label="批次" min-width="80" />
          <el-table-column sortable="custom" prop="sxrq" label="生效日期" min-width="100" />
          <!-- <el-table-column sortable="custom" prop="tsrq" label="停售日期" width="120px" /> -->
          <el-table-column sortable="custom" prop="bgkz" label="产品状态" min-width="100">
            <template slot-scope="scope">
              {{ ggSate(scope.row.bgkz,scope.row.cxbj) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <div class="table-text-btn">
                <el-popover placement="left" width="300" trigger="hover">
                  <img :src="scope.row.ybzp || defaultImg" alt="" width="100%">
                  <el-button
                    slot="reference"
                    type="text"
                    @click="viewDetail('/announcement/announcementSearch/optional/',scope.row.id,'选装')"
                  >选装
                  </el-button>
                </el-popover>

                <span class="line">|</span>
                <el-popover placement="left" width="300" trigger="click" @show="ggZCHistoryPc(scope.row.cpxh)">
                  <div class="checkbox-button-box">
                    <el-checkbox-button
                      v-model="checkboxGroupAll"
                      label="全部"
                      @change="checkboxGroupAllChange"
                    />
                    <el-checkbox-group v-model="checkboxGroup1" @change="changeFun">
                      <el-checkbox-button v-for="item in hitoryPc" :key="item" :label="item">{{
                        item
                      }}
                      </el-checkbox-button>
                    </el-checkbox-group>
                  </div>
                  <div class="hitoryBtn">
                    <el-button class="search-button" @click="searchHistory(scope.row.cpxh)">查询</el-button>
                  </div>
                  <el-button slot="reference" type="text">历史</el-button>
                </el-popover>

                <span class="line">|</span>
                <el-button
                  type="text"
                  @click="viewDetail('/announcement/announcementSearch/VehicleDetails/',scope.row.id,'查看')"
                >查看
                </el-button>
                <span class="line">|</span>
                <el-button type="text" @click="printFun(scope.row.id)">打印</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-right">
          <el-pagination
            v-if="pageShow"
            :current-page="pageData.pages.page"
            :page-sizes="[10, 20, 30, 40,100]"
            :page-size="pageData.pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import UnfurlAndFold from '@/components/UnfurlAndFold.vue'
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { download } from '@/utils/download.js'
import ggcpzt from '@/mixin/ggcpzt.js'
import {
  categoryPage,
  getAllZcPc,
  exportGGZc,
  getZcQueryList,
  ggZCHistoryPc,
  exportValidGGZc
} from '@/api/announcement.js'
import { buryPoint } from '@/api/common'

export default {
  components: {
    // VnotLogin,
    UnfurlAndFold
  },
  mixins: [ggcpzt],
  props: {
    category: ''
  },
  data() {
    return {
      defaultImg: require('@/assets/img/nonecar.jpg'),
      input: '',
      show: false,
      showTips: [
        {
          title: '公告动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '公告库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '公告梳理',
          text: '专家梳理整合，真正读懂公告脉络'
        },
        {
          title: '公告大数据',
          text: '用数据深度理解公告结构和趋势'
        }
      ],
      checkList: [],
      tableData: [],
      form: {
        cph: '',
        // 产品号
        cpmc: '',
        // 产品名称
        cpxh: '',
        // 产品商标
        cpsb: '',
        // 产品型号
        cxbj: '',
        // 撤销情况
        cllx: [],
        // 车型类别
        edzlEnd: '',
        // 额定载质量结束
        edzlStart: '',
        // 额定载质量开始
        changEnd: '',
        // 外形长结束
        changStart: '',
        // 外形长开始
        gaoEnd: '',
        // 外形高开始
        gaoStart: '',
        // 外形高结束
        kuanEnd: '',
        // 外形宽开始
        kuanStart: '',
        // 外形宽结束
        ghzhsh: [],
        // 轴数
        zzlEnd: '',
        // 总质量结束
        zzlStart: '',
        // 总质量开始
        mdpid: '',
        // 底盘ID
        mdplb: [],
        // 底盘类别
        mdpqy: '',
        // 底盘企业
        mdpxh: '',
        // 底盘型号
        mfdj: '',
        // 发动机型号
        mfglEnd: '',
        // 发动机功率结束
        mfglStart: '',
        // 发动机功率开始
        mfplEnd: '',
        // 发动机排量结束
        mfplStart: '',
        // 发动机排量开始
        mfqy: '',
        // 发动机生产企业
        pc: '',
        // 批次
        qymc: '',
        // 企业名称
        scdz: '',
        // 生产地址
        rlzl: '',
        // 燃料种类
        yjbz: '',
        // 排放标准
        zbzlEnd: '',
        // 整备载质量结束
        zbzlStart: '',
        // 整备载质量开始
        otherParam: '',
        // 其它参数
        otheParamValue: '',
        // 参数值,
        tjcx: '',
        dldb: '',
        mzgzs: '',
        orderType: ''
      },
      // 批次输入
      pcinput: '',
      // 车辆类型
      cllx: true,
      // 底盘类型
      mdplb: true,
      // 轴数
      ghzhsh: true,
      cxbjData: [],
      cllxData: [
        { value: '1', label: '载货汽车' },
        { value: '2', label: '越野汽车' },
        { value: '3', label: '自卸汽车' },
        { value: '4', label: '牵引汽车' },
        { value: '5', label: '专用汽车' },
        { value: '6', label: '客车' },
        { value: '7', label: '轿车' },
        { value: '9', label: '半挂车及专用半挂汽车' }
      ],
      ghzhshData: [],
      mdplbData: [],
      tjcx: false,
      dldb: false,
      mzgzs: false,
      pageData: {
        pages: {
          page: 1,
          pageSize: 100
        },
        total: 0
      },
      isShow: false,
      pcData: [],
      contrastData: [],
      exportData: [],
      checkboxGroup1: [],
      hitoryPc: [],
      checkboxGroupAll: false,
      other: [
        { value: 'ghyjbz', label: '排放依据标准' },
        { value: 'mfpl', label: '发动机排量' },
        { value: 'zxxs', label: '转向型式' },
        { value: 'hxc', label: '货箱栏板内长' },
        { value: 'hxg', label: '货箱栏板内高' },
        { value: 'hxk', label: '货箱栏板内宽' },
        { value: 'zhj', label: '轴距' },
        { value: 'thps', label: '钢板弹簧片数(前/后)' },
        { value: 'ltgg', label: '轮胎规格' },
        { value: 'lts', label: '轮胎数' },
        { value: 'qlj', label: '前轮距' },
        { value: 'hlj', label: '后轮距' },
        { value: 'gczl', label: '准拖挂车总质量' },
        { value: 'zzhl', label: '载质量利用系数' },
        { value: 'bgaz', label: '半挂车鞍座最大允许承载质量' },
        { value: 'edzk', label: '额定载客' },
        { value: 'qpck', label: '驾驶室准乘人数' },
        { value: 'jjlqj', label: '接近角/离去角' },
        { value: 'qxhx', label: '前悬/后悬' },
        { value: 'zgcs', label: '最高车速' },
        { value: 'zhh', label: '轴荷' },
        { value: 'mdplb', label: '底盘类别' },
        { value: 'sbdh', label: '车辆识别代号' },
        { value: 'qt', label: '其它' }
      ],
      cxlx: [
        { value: 'y', label: '已撤' },
        { value: 'n', label: '未撤' }
      ],
      loading: false,
      loadingExport: false,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '公告',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
      if (getUserInfo().userName == '韩洪臣' || getUserInfo().userName == 'sqsyc2' || getUserInfo().userName == 'sqsyc1' || getUserInfo().userName == 'SQSYC' || getUserInfo().userName == '344655' || getUserInfo().userName == '170502' || getUserInfo().userName == '卢晓林' || getUserInfo().userName == 'tianjian2024') {
        this.isShow = true
      }
    } else {
      this.show = false
    }
    if (this.$store.state.notice.pc > 0) {
      this.form.pc = this.pcinput =
          this.$store.state.notice.pc.toString()
    }
    this.init()
    this.getAllZcPc()
    this.getZcQueryList()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    viewDetail(path, id, buttonName) {
      const newUrl = this.$router.resolve({
        path: path + id
      })
      if (getUserInfo()) {
        logRecords('公告', '整车查询', '3', 'gg_yzk', '', '', '', '', buttonName)
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 获取列表
    categoryPage(parmas) {
      this.loading = true
      categoryPage(parmas).then((res) => {
        this.tableData = res.data.list
        this.pageData.total = res.data.count
        this.loading = false
      })
    },
    // 分页
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.page = val
        this.init()
      }
    },
    // 获取批次
    getAllZcPc() {
      getAllZcPc().then((res) => {
        this.pcData = res.data
      })
    },
    // 初始化
    init() {
      const data = Object.assign({}, this.form, this.pageData.pages)

      data.cllx = data.cllx.join(',')
      data.mdplb = data.mdplb.join(',')
      data.ghzhsh = data.ghzhsh.join(',')
      data.cplb = this.category
      data.tjcx = this.tjcx ? 'yes' : ''
      data.dldb = this.dldb ? 'yes' : ''
      data.mzgzs = this.mzgzs ? 'yes' : ''

      this.categoryPage(data)
    },
    // 查询
    searchForm() {
      if (getUserInfo()) {
        logRecords('公告', '整车查询', '3', 'gg_yzk', '', '', '', '', '查询')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            this.pageData.pages.page = 1
            this.init()
          }
        } else {
          this.pageData.pages.page = 1
          this.init()
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 重置
    reset() {
      for (const key in this.form) {
        if (key == 'cllx') {
          this.form[key] = []
        } else if (key == 'ghzhsh') {
          this.form[key] = []
        } else if (key == 'mdplb') {
          this.form[key] = []
        } else {
          this.form[key] = ''
        }
      }
      this.pcinput = ''
      this.cllx = true
      this.mdplb = true
      this.ghzhsh = true
      this.tjcx = false
      this.dldb = false
      this.mzgzs = false
      this.pageData.pages.page = 1
      this.form.orderType = ''
      this.$refs.newTable.clearSort()
      this.init()
    },
    // 批次输入
    pcFun(e) {
      this.form.pc = e
    },
    // 批次选择
    pcRadio() {
      this.pcinput = ''
    },
    // 车辆类型
    carcheck(e) {
      if (e.length <= 0) {
        this.cllx = true
      } else {
        this.cllx = false
      }
    },
    // 车辆类型全选
    carcheckAll() {
      this.cllx = true
      this.form.cllx = []
    },
    // 底盘类型
    mdplbAll() {
      this.mdplb = true
      this.form.mdplb = []
    },
    mdplbcheck(e) {
      if (e.length <= 0) {
        this.mdplb = true
      } else {
        this.mdplb = false
      }
    },
    // 轴数
    ghzhshAll() {
      this.ghzhsh = true
      this.form.ghzhsh = []
    },
    ghzhshcheck(e) {
      if (e.length <= 0) {
        this.ghzhsh = true
      } else {
        this.ghzhsh = false
      }
    },

    // 下载
    excelBtn() {
      if (getUserInfo()) {
        if (this.exportData.length <= 0) {
          this.$message({
            showClose: true,
            message: '请选择导出数据',
            type: 'error'
          })
          return false
        }
        if (this.exportData.length > 100) {
          this.$message({
            showClose: true,
            message: '最多选择100条数据',
            type: 'error'
          })
          return false
        }

        const ids = this.exportData.map((item) => {
          return item.id
        })
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
        logRecords('公告', '整车查询', '3', 'gg_yzk', '', '', '', '', '导出')

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              exportGGZc(ids).then((res) => {
                download(res, '整车数据', 'xls')
              })
            }
          }
        } else {
          exportGGZc(ids).then((res) => {
            download(res, '整车数据', 'xls')
          })
        }
      } else {
        this.showLogin()
      }
    },
    // 获取字典类型
    getZcQueryList() {
      getZcQueryList().then((res) => {
        const data = res.data
        this.cxbjData = data.cxbj
        // this.cllxData = data.cllx;
        this.ghzhshData = data.zhsh
        this.mdplbData = data.mdplb
      })
    },
    // 选择
    selectionChange(selection) {
      this.contrastData = selection
      this.exportData = selection
    },
    // 一键比对
    contrast() {
      if (getUserInfo()) {
        if (this.contrastData.length <= 0) {
          this.$message({
            showClose: true,
            message: '请选择对比数据',
            type: 'error'
          })
          return false
        }
        if (this.contrastData.length > 10) {
          this.$message({
            showClose: true,
            message: '最多选择10条数据',
            type: 'error'
          })
          return false
        }
        const ids = this.contrastData.map((item) => {
          return item.id
        })
        const newUrl = this.$router.resolve({
          path: '/announcement/announcementSearch/VehicleDetailsComparison/' +
              ids.join(',') +
              '/hitoryPc/false'
        })
        logRecords('公告', '整车查询', '3', 'gg_yzk', '', '', '', '', '一键对比')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.insertBuryPoint()
            } else {
              window.open(newUrl.href, '_blank')
            }
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    ggZCHistoryPc(parmas) {
      ggZCHistoryPc({ cpxh: parmas }).then((res) => {
        this.checkboxGroup1 = []
        this.checkboxGroupAll = false
        this.hitoryPc = res.data
      })
    },
    // 全选
    checkboxGroupAllChange(v) {
      if (v) {
        this.checkboxGroup1 = this.hitoryPc
      } else {
        this.checkboxGroup1 = []
      }
    },
    changeFun(v) {
      if (v.length == this.hitoryPc.length) {
        this.checkboxGroupAll = true
      } else {
        this.checkboxGroupAll = false
      }
    },
    // 查询历史
    searchHistory(str) {
      if (getUserInfo()) {
        logRecords('公告', '整车查询', '3', 'gg_yzk', '', '', '', '', '历史')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            if (this.checkboxGroup1.join(',')) {
              const newUrl = this.$router.resolve({
                path: '/announcement/announcementSearch/VehicleDetailsHistory',
                query: { id: this.checkboxGroup1.join(','), hitoryPc: str }
              })
              window.open(newUrl.href, '_blank')
            } else {
              this.$message({
                showClose: true,
                message: '批次不能为空',
                type: 'error'
              })
            }
          }
        } else {
          if (this.checkboxGroup1.join(',')) {
            const newUrl = this.$router.resolve({
              path: '/announcement/announcementSearch/VehicleDetailsHistory',
              query: { id: this.checkboxGroup1.join(','), hitoryPc: str }
            })
            window.open(newUrl.href, '_blank')
          } else {
            this.$message({
              showClose: true,
              message: '批次不能为空',
              type: 'error'
            })
          }
        }
      } else {
        this.showLogin()
      }
    },
    // 打印printFun
    printFun(id) {
      if (getUserInfo()) {
        logRecords('公告', '整车查询', '3', 'gg_yzk', '', '', '', '', '打印')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('公告')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open('/api/exportZcPdf/ggYzkPdf?cpId=' + id)
          }
        } else {
          window.open('/api/exportZcPdf/ggYzkPdf?cpId=' + id)
        }
      } else {
        this.showLogin()
      }
    },
    // 导出有效车型
    exportValidGGZc() {
      logRecords('公告', '整车查询', '3', 'gg_yzk', '', '', '', '', '导出全部')

      const data = Object.assign({}, this.form, this.pageData.pages)

      data.cllx = data.cllx.join(',')
      data.mdplb = data.mdplb.join(',')
      data.ghzhsh = data.ghzhsh.join(',')
      data.cplb = this.category
      data.tjcx = this.tjcx ? 'yes' : ''
      data.dldb = this.dldb ? 'yes' : ''
      data.mzgzs = this.mzgzs ? 'yes' : ''

      this.loadingExport = true
      this.loading = true
      exportValidGGZc(data).then((res) => {
        this.loadingExport = false
        this.loading = false
        download(res, '有效车型数据', 'xls')
      })
    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-text-btn {
  .el-button--text {
    padding: 0;
  }

  .line {
    padding: 0 5px;
  }

  .el-button--text,
  .line {
    color: #0d57bc;
  }
}

.checkbox-button-box {
  display: flex;
}

.hitoryBtn {
  margin-top: 16px;
  text-align: center;
}

/deep/ {
  .el-checkbox-button,
  .el-checkbox-button__inner {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .el-checkbox-button__inner {
    border: 2px solid #828282;
    border-radius: 1px;
    color: #828282;
  }

  .el-checkbox-button:first-child .el-checkbox-button__inner,
  .el-checkbox-button:last-child .el-checkbox-button__inner {
    border: 2px solid #828282;
    border-radius: 1px;
  }

  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background: #fff;
    color: #0d57bc;
    border-color: #0d57bc;
    box-shadow: none;
  }

  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
