<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-04 11:29:02
 * @LastEditors: 林子健
 * @LastEditTime: 2023-11-02 11:40:41
-->
<!--市场动态列表-->
<template>
  <div class="info-item">
    <!--    <div class="img-box">-->
    <!--      <img-->
    <!--        v-if="data.coverUrl"-->
    <!--        style="width: 100%; height: 100%"-->
    <!--        :src="data.coverUrl"-->
    <!--        alt=""-->
    <!--      />-->
    <!--    </div>-->
    <div class="info-box" @click="toHref(data)">
      <div class="title-box">
        <div class="title">
          <span class="title-span">{{ data.title }}</span>
          <template v-for="(item, index) in data.contentCategory">
            <span v-if="item == 8" :key="index" :class="`title-tag-${item}`">
              其它
            </span>
            <span v-if="item == 2" :key="index" :class="`title-tag-${item}`">
              英
            </span>
            <span v-if="item == 3" :key="index" :class="`title-tag-${item}`">
              券商
            </span>
            <span v-if="item == 4" :key="index" :class="`title-tag-${item}`">
              消费者
            </span>
            <span v-if="item == 5" :key="index" :class="`title-tag-${item}`">
              原创
            </span>
            <span v-if="item == 6" :key="index" :class="`title-tag-${item}`">
              乘用车
            </span>
            <span v-if="item == 7" :key="index" :class="`title-tag-${item}`">
              商用车
            </span>
            <!-- <img v-if="item==1" :key="index" class="bq-img" src="@/assets/img/international/re.png"> -->
            <!-- <img v-if="item==2" :key="index" class="bq-img" src="@/assets/img/international/ying.png"> -->
            <!-- <img v-if="item==3" :key="index" class="bq-img" src="@/assets/img/international/quanshang.png"> -->
            <!-- <img v-if="item==4" :key="index" class="bq-img" src="@/assets/img/international/feiquanshang.png"> -->
          </template>
        </div>
      </div>
      <!--      <div class="text-box">-->
      <!--        {{ data.introduction }}-->
      <!--      </div>-->
      <div class="tag-box">
        <!--        <el-tag type="success">标签二</el-tag>-->
        <!--        <el-tag type="info">标签三</el-tag>-->
        <!--        <el-tag type="warning">标签四</el-tag>-->
        <template v-for="(item, index) in data.belongAreas">
          <el-tag :key="index" type="danger">{{ item }}</el-tag>
        </template>
        <span class="time">{{ data.updateTime }}</span>
        <span class="author">{{ data.author }}</span>
      </div>
    </div>
    <div v-if="data.attachName != null" class="download-box" >

      <el-button
          v-if="data.attachUrl"
        type="primary"
        :loading="fileLoading"
        @click="getFileLoading()"
      >下载
        <span v-for="(item, index) in data.attachUrl.split(',')"  :key="index">
          <img
            v-if="data.attachName.split(',')[index].includes('ppt') || data.attachName.split(',')[index].includes('pptx')"
            class="img-file"
            src="@/assets/img/international/PPT.png"
            @click.stop="downBtn(data,index)"
          >
          <img
            v-if="data.attachName.split(',')[index].includes('pdf')"
            class="img-file"
            src="@/assets/img/international/pdf.png"
            @click.stop="downBtn(data,index)"
          >
          <img
            v-if="data.attachName.split(',')[index].includes('doc') || data.attachName.split(',')[index].includes('docx')"
            class="img-file"
            src="@/assets/img/international/doc.png"
            @click.stop="downBtn(data,index)"
          >
        </span>

      </el-button>
    </div>
  </div>
</template>
<script>
import { getUserInfo, logRecords, openSy } from '@/utils/auth.js'
import { buryPoint, download } from '@/api/common'
import { downloadFile } from '@/utils/download'
export default {
  name: 'MassageItem',
  components: {},
  props: {
    // 数据
    data: {
      type: Object,
      default: () => {}
    },
    tag: {
      type: Boolean,
      default: false
    },
    linkUrl: {
      type: String,
      default: '/international/details/'
    },
    logRecordsColumn1: {
      type: String,
      default: ''
    },
    logRecordsColumn2: {
      type: String,
      default: ''
    },
    logRecordsTableName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
       formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '国际化',
        userId: ''
      },
      fileLoading: false
    }
  },
  created() {},
  methods: {
    /**
     * @description: 页面跳转
     * @param {*} item
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:28:56
     * @LastEditTime: Do not edit
     */
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      if (!getUserInfo() && this.logRecordsColumn2 == '研究报告') {
        this.$Vlogin2({ flag: true })
        return
      }
      // debugger;
      const newUrl = this.$router.resolve(this.linkUrl + item.id)
      // debugger;
      if (getUserInfo()) {
        logRecords(
          this.logRecordsColumn1,
          this.logRecordsColumn2,
          '1',
          this.logRecordsTableName,
          item.id,
          '',
          item.title,
          ''
        )
        if (
          getUserInfo().roleKey == 'paidrole' ||
            getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.$Vlogin2({ flag: true })
        return
      }
    },
    getFileLoading() {
      if (getUserInfo()) {
        if (
          getUserInfo().roleKey == 'paidrole' ||
            getUserInfo().roleKey == 'trialrole'
        ) {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.sqsyLogin()
          } else {
            return
          }
        } else {
          return
        }
      } else {
        return
      }
    },
    // 下载(修改的部分)
    downBtn(item, index) {
      const attachName = item.attachName.split(',')
      const attachUrl = item.attachUrl.split(',')
      if (getUserInfo()) {
        logRecords(
          '国际化',
          '研究报告',
          '2',
          'tt_international_report',
          item.id,
          attachName[index],
          item.title
        )
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('国际化')) {
            this.$Vlogin3({ flag: true })
            this.formLog.userId = getUserInfo().userId
            buryPoint(this.formLog)
          } else {
            if (getUserInfo().roleKey == 'trialrole') {
              openSy()
              this.formLog.userId = getUserInfo().userId
              buryPoint(this.formLog)
            } else {
              this.fileLoading = true
              download({
                fileUrl: attachUrl[index],
                articleId: item.id,
                column1: '国际化',
                column2: '研究报告'
              }).then((res) => {
                this.fileLoading = false
                var reader = new FileReader()
                reader.readAsText(res)
                reader.addEventListener('loadend', function(e) {
                  try {
                    JSON.parse(e.target.result)
                    const msg = JSON.parse(e.target.result)
                    self.$message({
                      showClose: true,
                      message: msg.message,
                      type: 'error'
                    })
                  } catch (err) {
                    downloadFile(res, attachName[index])
                  }
                })
              })
            }
          }
        } else {
          this.fileLoading = true
          download({
            fileUrl: attachUrl[index],
            articleId: item.id,
            column1: '国际化',
            column2: '研究报告'
          }).then((res) => {
          this.fileLoading = false
            var reader = new FileReader()
            reader.readAsText(res)
            reader.addEventListener('loadend', function(e) {
              try {
                JSON.parse(e.target.result)
                const msg = JSON.parse(e.target.result)
                self.$message({
                  showClose: true,
                  message: msg.message,
                  type: 'error'
                })
              } catch (err) {
                downloadFile(res, attachName[index])
              }
            })
          })
        }
      } else {
        this.$Vlogin2({ flag: true })
      }
    }
  }
}
</script>
<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
</style>
<style lang="scss" scoped>
$BassZoom: 1;
.info-item {
  width: 100%;
  height: 2.333rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .img-box {
    height: 100%;
    width: 12.333rem;
    background: url(~@/assets/img/overview/card-item-test-1.png) no-repeat;
    background-size: 100% 100%;
  }
  .download-box {
    height: 100%;
    width: 6rem;
    text-align: right;
    .img-file {
      margin-left: 10px;
      height: 20px;
      background-color: white;
    }
  }
  .bq-img {
    padding-left: 5px;
    height: 20px;
    margin-bottom: 5px;
  }
  .time {
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: calc(14px * #{$BassZoom});
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: calc(400 * #{$BassZoom});
    color: rgba(0, 0, 0, 0.4);
  }
  .author {
    padding-left: 10px;
    height: 1.2rem;
    line-height: 1.2rem;
    font-size: calc(14px * #{$BassZoom});
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: calc(400 * #{$BassZoom});
    color: rgba(0, 0, 0, 0.4);
  }
  .info-box {
    flex: 1;
    height: 100%;
    //padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    .title-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 1.2rem;
      .title {
        flex: 1;
        height: 20px;
        line-height: 20px;
        font-size: calc(16px * #{$BassZoom});
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: calc(400 * #{$BassZoom});
        .title-span {
          max-width: 85%;
          color: #333333;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -moz-box;
          -moz-line-clamp: 1;
          -moz-box-orient: vertical;
          overflow-wrap: break-word;
          word-break: break-all;
          white-space: normal;
        }
        overflow: hidden;
        display: flex;
        align-items: center;

        .title-tag-1,
        .title-tag-2,
        .title-tag-3,
        .title-tag-4,
        .title-tag-5,
        .title-tag-6,
        .title-tag-7,
        .title-tag-8{
          display: -webkit-box;
          -webkit-line-clamp: 1;
          display: -moz-box;
          -moz-line-clamp: 1;
          padding: 0px 10px;
          height: 20px;
          color: #fff;
          border-radius: 14px 0 14px 0;
          font-size: 12px;
          margin: 0 8px;
          user-select: none;
        }

        .title-tag-8 {
          background-color: rgb(220, 93, 23);
        }

        .title-tag-2 {
          background-color: rgb(54, 66, 172);
        }

        .title-tag-3 {
          background-color: rgb(127, 71, 28);
        }

        .title-tag-4 {
          background-color: rgb(134, 201, 245);
        }

        .title-tag-5 {
          background-color: #183762;
        }
        .title-tag-6 {
          background-color: #27ae60;
        }
        .title-tag-7 {
          background-color: #eb5757;
        }
      }
    }
    .text-box {
      width: 100%;
      // height: 4rem;
      margin-top: 0.5rem;
      font-size: calc(14px * #{$BassZoom});
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: calc(400 * #{$BassZoom});
      color: #333333;
      text-overflow: ellipsis;

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      display: -moz-box;
      -moz-line-clamp: 4;
      -moz-box-orient: vertical;

      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
    }
    .tag-box {
      width: 100%;
      height: 1rem;
      /deep/ .el-tag {
        height: 0.7rem;
        line-height: 0.6rem;
        margin-right: 0.3rem;
      }
      .logo {
        display: inline-block;
        float: right;
        width: 1rem;
        height: 1rem;
        background: url(~@/assets/img/overview/logo-test.png) no-repeat;
        background-size: 100% 100%;
      }
      .name {
        display: inline-block;
        float: right;
        height: 1rem;
        margin-left: 0.2rem;
      }
    }
  }
}
</style>
