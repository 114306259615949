<template>
  <PageContainer breadcrumb>
    <!--      <VlistTitle bigTtitle="最新政策" smallTip="会员专享精华内容推荐" :bgurl="bgurl"/>-->
    <div class="list-filter" style="width: 100%;background: #fff;">
      <el-row>
        <!--          <el-col :span="24">-->
        <!--            <div style="margin: 20px">-->
        <!--              <div class="zcTitle">政策类别</div>-->
        <!--              <el-radio-group size="small">-->
        <!--                <div style="display: inherit;margin-right: 10px;margin-bottom: 10px;" v-for="item in policyCategoryData" :key="item.id">-->
        <!--                  <el-radio :label="item.name" border >-->
        <!--                    {{item.name}}-->
        <!--                  </el-radio>-->
        <!--                </div>-->

        <!--              </el-radio-group>-->
        <!--            </div>-->
        <!--          </el-col>-->
        <el-col :span="24">
          <div style="margin: 20px">

            <div class="zcTitle">热门领域
              <span style="float: right">
                <el-input
                  v-model="title"
                  style="float: right"
                  placeholder="搜索名称"
                  size="small"
                  @keyup.enter.native="newPolicy"
                >
                  <i
                    slot="suffix"
                    class="el-input__icon el-icon-search"
                    style="cursor: pointer;"
                    @click="newPolicy"
                  />
                </el-input>
              </span>

            </div>

            <el-checkbox-group v-model="belongAreas" size="small" @change="handleChange">
              <el-checkbox v-for="item in belongAreasData" :key="item.dictValue" :label="item.dictValue" border>
                {{ item.dictLabel }}
              </el-checkbox>
            </el-checkbox-group>
          </div>

        </el-col>
        <!--          <el-col :span="24">-->
        <!--            <div style="margin: 20px">-->

        <!--              <div class="zcTitle">政策类别</div>-->

        <!--                <span v-for="item in policyCategoryData" :key="item.id">{{item.name}}-->

        <!--                  <el-select v-model="value" clearable placeholder="请选择">-->
        <!--                    <el-checkbox v-model="checked" @change='selectAll'>全选</el-checkbox>-->

        <!--                    <el-option-->
        <!--                        v-for="items in item.children"-->
        <!--                        :key="items.id"-->
        <!--                        :label="items.name"-->
        <!--                        :value="items.id">-->
        <!--                    </el-option>-->
        <!--                  </el-select>-->
        <!--                </span>-->
        <!--            </div>-->

        <!--          </el-col>-->
      </el-row>

    </div>
    <div style="width: 100%;background: #fff;padding-left: 20px;font-weight: bold;text-align: center">
      <span>共计<span style="color: red">{{ newPolicyPage.total }}</span>条</span>
      <el-button style="margin-left: 10px;height: 30px;" @click="reset">重置</el-button>
    </div>

    <div class="box">
      <ul class="tab-box">
        <li
          v-for="item in tabData"
          :key="item.value"
          class="tab-list"
          :class="{'ac':ac == item.value}"
          @click="tabBtn(item.value)"
        >{{ item.label }}
        </li>

      </ul>

    </div>
    <!--      <Vlist :list="newPolicyDate" :pageData="newPolicyPage" @handleSizeChange="handleSizeChange"-->
    <!--             @handleCurrentChange="handleCurrentChange" @toHref="toHref" class="list" v-loading="loading"/>-->
    <div>
      <ul v-loading="loading" class="newUl">
        <li v-for="item in newPolicyDate" :key="item.id">
          <div class="newUl-link" style="cursor: pointer" @click="toHref('/policy/dynamic/contentNewPolicyDyn/'+item.id,item)">
            <template v-if="item.areas">
              <div v-if="item.areas == '1'" class="newUl-areas " style="margin-bottom: 10px">国家</div>
              <div v-else class="newUl-areas df" style="margin-bottom: 10px">地方</div>
            </template>
            <template v-if="item.province!='' && item.province!=null">
              <div class="newUl-areas df" style="margin-bottom: 10px;background-color: #f1dde1;color:#823D00">{{ item.province }}</div>
            </template>
            <!--              <template v-if="item.belongAreas">-->
            <!--                <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>-->
            <!--              </template>-->
            <div class="newUl-title" style="margin-bottom: 10px">{{ item.title }}</div>
            <div class="newUl-time" style="margin-bottom: 10px">{{ item.publicDate }}</div>
          </div>
          <div class="newUl-link" style="margin-top: 4px;display: inline;color: grey;">
            <template v-if="item.belongAreas">
              <div v-for="item in strSplit(item.belongAreas)" :key="item" class="newUl-tag " style="margin-bottom: 5px">{{ item }}</div>
            </template>
            来源：{{ item.source }}&nbsp;&nbsp;|&nbsp;&nbsp;政策类别：{{ item.policyCategory }}&nbsp;&nbsp;|&nbsp;&nbsp;发布类型：
            <template v-if="item.publishType=='1'">正式发布稿</template>
            <template v-else>征求意见稿</template>

          </div>
        </li>
      </ul>
      <div class="pages">
        <el-pagination
          v-if="pageShow"
          :current-page="newPolicyPage.pages.page"
          :page-size="newPolicyPage.pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="newPolicyPage.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </PageContainer>
</template>
<script>
// import VlistTitle from '@/components/listTitle.vue'
// import Vlist from '@/components/list.vue'
import { newPolicy } from '@/api/policy.js'
// import ListFiltering from '@/components/ListFiltering.vue'
import { selectDictDataByType, ttDemension } from '@/api/dict.js'
import { htmlRestore } from '@/utils/htmlRestore.js'
import { getUserInfo, logRecords,openSy } from '@/utils/auth'
import { buryPoint } from '@/api/common'

export default {
  // components: {
  //   VlistTitle,
  //   Vlist,
  //   ListFiltering
  // },
  data() {
    return {
      bgurl: require('@/assets/img/Group299.png'),
      newPolicyDate: [],
      newPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      checked: false,
      policyCategoryData: [],
      activeName: '',
      title: '',
      belongAreasData: [],
      belongAreas: [],
      tabData: [
        { value: '', label: '全部政策' },
        { value: '1', label: '国家政策' },
        { value: '2', label: '地方政策' }
      ],
      ac: '',
      areas: '',
      all: true,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '政策',
        userId: ''
      },
      values: '',
      loading: false,
      value: '',
      pageShow: true

    }
  },
  created() {
    const title = this.$route.query.title
    if (title != "''") {
      this.belongAreas.push(title)
    }

    const ac = this.$route.query.ac
    if (ac != "''") {
      this.ac = ac
      this.areas = ac
    }

    this.newPolicy()
    this.selectDictDataByType()
    this.ttDemension({ code: 'policyType' })
  },
  methods: {

    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 字符串转数组
    strSplit(str) {
      return str.split(',')
    },
    selectAll() {
      this.value = []
      if (this.checked) {
        this.options.map((item) => {
          this.value.push(item.id)
        })
      } else {
        this.value = []
      }
    },
    // 最新政策
    newPolicy() {
      if (!getUserInfo() && this.newPolicyPage.pages.page > 1) {
        this.showLogin()
      } else {
        const form = {
          ...this.newPolicyPage.pages,
          areas: this.areas,
          belongAreas: this.belongAreas.join(','),
          title: this.title,
          flag: 1
        }
        if (this.title != null && this.title != '') {
          logRecords('政策', '最新政策', '4', 'tt_policy', '', '', '', this.title)
        }
        this.loading = true
        newPolicy(form).then((res) => {
          if (res.ok) {
            this.newPolicyDate = res.data.list
            this.newPolicyDate.forEach((element) => {
              element.title = htmlRestore(element.title)
              element.updateTime = element.publicDate
            })
            this.newPolicyPage.total = res.data.count
            this.loading = false
          }
        })
      }
    },
    reset() {
      this.areas = '',
      this.belongAreas = [],
      this.title = ''
      this.newPolicy()
    },
    handleClick(tab) {
      console.log(tab)
    },
    handleSizeChange(val) {
      this.newPolicyPage.pages.pageSize = val
      this.newPolicy()
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.newPolicyPage.pages.page = val
        this.newPolicy()
      }
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item) {
      const newUrl = this.$router.resolve(url)

      if (getUserInfo()) {
        logRecords('政策', '最新政策', '1', 'tt_policy', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 数据字典
    selectDictDataByType() {
      selectDictDataByType({ dictType: 'policy_hot_field' }).then(
        (res) => {
          if (res.ok) {
            this.belongAreasData = res.data
          }
        }
      )
    },
    ttDemension(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.policyCategoryData = res.data
        }
      })
    },
    // 切换
    handleChange(e) {
      if (getUserInfo()) {
        if (e.length <= 0) {
          this.all = true
        } else {
          this.all = false
        }
        this.newPolicy()
      } else {
        this.showLogin()
      }
    },
    allbtn() {
      this.belongAreas = []
      this.newPolicy()
    },
    tabBtn(e) {
      if (getUserInfo()) {
        this.ac = e
        this.areas = e
        this.newPolicy()
      } else {
        this.showLogin()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.list {
  /deep/ .list-box {
    margin-top: 0;
  }
}

.list-filter {
  //padding: 24px;
  background: #ffffff;

  ::v-deep .el-checkbox__input {
    display: none;
  }

  ::v-deep .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
  }

  ::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.box {
  padding: 0 24px;
  background: #fff;
}

.tab-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  .tab-list {
    cursor: pointer;
    font-size: 15px;
    line-height: 10px;
    color: #4f4f4f;
    margin-right: 24px;
    margin-bottom: 18px;

    &.ac {
      position: relative;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -18px;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }

  /deep/ .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

.zcTitle {
  margin: 20px 0;
  font-weight: bold;
  font-size: 16px
}

::v-deep .el-radio__inner {
  display: none;
}

::v-deep .el-timeline-item__node--large {
  left: -1px;

}

::v-deep .el-radio__label {
  padding: 0;
}

::v-deep .el-radio {
  margin-right: 0;
}

::v-deep .el-checkbox__label {
  padding-left: 5px;
}

::v-deep .el-button {
  padding: 5px 20px;
  background-color: #0d57bc;
  color: #fff;
}
.list-box {
  //margin-top: 16px;
  li {
    background: #fff;
    padding: 24px;
    border-bottom: 1px solid #f2f2f2;
    &:last-child {
      border-bottom: none;
    }
    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .list-l {
        padding-right: 20px;
        flex: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #141414;
      }
      .list-r {
        font-size: 16px;
        line-height: 18px;
        color: #828282;
      }
    }
  }
}
.newUl .newUl-title {
  font-weight: bold;
}
.newUl .newUl-time {
  font-size: 16px;

}
.newUl {
  position: relative;
  z-index: 1;
  padding-top: 10px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
