var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-container"},[_c('div',{staticClass:"bottom-all"},[_c('div',{staticClass:"bottom-all-left"},[_vm._m(0),_c('ul',{staticClass:"bottom-all-left-list"},_vm._l((_vm.hyzxData),function(item){return _c('router-link',{key:'hyzx'+item.id,attrs:{"to":{
                          path: '/index/detail/IndustryDetail/' + item.id,
                          query: {
                            id: item.id,
                            releaseColumn: item.releaseColumn,
                            category: 'industry',
                          },
                        },"tag":"li"}},[_c('img',{attrs:{"src":item.coverUrl,"alt":"","width":"285","height":"177"}}),_c('div',{staticClass:"bottom-all-left-inner"},[(item.shortTitle !=null && item.shortTitle !='')?_c('h1',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(_vm.htmlRestore(item.shortTitle)))]):_c('h1',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(_vm.htmlRestore(item.title)))]),_c('p',{staticClass:"text-overflow-2"},[_vm._v(_vm._s(_vm.htmlRestore(item.introduction)))]),_c('div',{staticClass:"bottom-all-left-text"},[_c('span',[_vm._v("所属领域："+_vm._s(item.releaseColumn))]),_c('span',[_vm._v(_vm._s(item.updateTime))])])])])}),1),_c('div',{staticClass:"jz"},[_c('div',{staticClass:"jz-more",on:{"click":function($event){return _vm.jzMoreBtn()}}},[_c('span',[_vm._v(_vm._s(_vm.loadinginName)+" "),_c('i',{class:_vm.loadingIcon})])])])])]),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-all-left-title"},[_c('h1',[_vm._v("行业资讯")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('img',{attrs:{"src":require("@/assets/img/Group 446.png"),"alt":"","width":"201"}}),_c('h1',[_vm._v("富奥公司版权所有，未经书面授权，所有页面内容不得以任何介质擅自进行复制或镜像。")])])}]

export { render, staticRenderFns }