<!--
 * @Author: your name
 * @Date: 2021-07-22 13:25:42
 * @LastEditTime: 2021-07-22 15:41:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/thematicAnalysis/index.vue
-->

<template>
  <PageContainer>
    <!--    <div class="not-login-box" v-if="!show">-->
    <!--      <VnotLogin title="专题分析" text="专题分析栏目围绕专题分析本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新专题分析"-->
    <!--        :showTips="showTips" />-->
    <!--    </div>-->
    <template slot="actionNav">
      <div class="tabs">
        <el-tabs v-model="form.contentCategory" :before-leave="leaveTab" @tab-click="tabClick">
          <el-tab-pane :key="'qb'" label="全部(原创+第三方)" name=" " />
          <el-tab-pane :key="'yc'" label="精选原创" name="yc" />
        </el-tabs>
      </div>
    </template>
    <template slot="right">
      <div class="index-banner-title">
        <div v-if="form.contentCategory === 'yc'" class="index-banner-new">
          <div class="title-tag">
            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/newIssue.png" alt=""></span>{{ tagText }}
            </h3>
            <div class="more" @click="moreClick">查看更多 <i class="el-icon-arrow-right" /></div>
          </div>
          <ul class="index-new-timeLine">
            <li v-for="item in tableDataRight" :key="item.id">
              <div class="index-new-timeLine-content">
                <div
                  style="cursor: pointer"
                  class="index-new-link"
                  @click="toHref('/thematicAnalysis/details/' + item.id, item)"
                >
                  <div flex="c">
                    <!-- <img src="@/assets/img/bg.png" alt="" style="padding-right: 5px;" /> -->
                    <div v-if="item.contentCategory == 'yc'" class="ycDiv">原创</div>
                    <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.title" />
                    <div class="publishDate">{{ item.publishDate }}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="index-banner-new">
          <div class="title-tag">
            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/newIssue.png" alt=""></span>{{ tagText }}
            </h3>
            <div class="more" @click="moreClick">查看更多 <i class="el-icon-arrow-right" /></div>
          </div>
          <ul class="index-new-timeLine">
            <li v-for="item in tableDataRight" :key="item.id">
              <div class="index-new-timeLine-content">
                <div
                  style="cursor: pointer;padding: 0"
                  class="index-new-link"
                  @click="toHref('/thematicAnalysis/details/' + item.id, item)"
                >
                  <div class="right-img-box" flex="c">
                    <div class="img-box">
                      <img :src="'https://www.autoinfo.org.cn'+item.imgUrl" alt="">
                    </div>
                    <div class="text-box">
                      <div>
                        <span v-if="item.contentCategory == 'yc'" class="ycDiv">原创</span>
                        {{ item.title }}
                        <!-- <TextOverTooltip refName="testName1" className="newUl-title" :content="item.title"></TextOverTooltip> -->
                      </div>
                      <div class="updateTime">{{ item.publishDate }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="index-banner-hot">
          <div class="title-tag">
            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/rankingList.png" alt=""></span>月榜排行</h3>
          </div>
          <ul class="index-new-timeLine">
            <li v-for="(item, index) in selectToList" :key="item.id">
              <div class="index-new-timeLine-content">
                <div
                  style="cursor: pointer"
                  class="index-new-link"
                  @click="rankingtoHref('/thematicAnalysis/details/' + item.article_id, item)"
                >
                  <div flex="c">
                    <span v-if="index === 0" class="one">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span v-else-if="index === 1" class="two">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span v-else-if="index === 2" class="three">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span v-else class="ranking">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.article_name" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <div class="box">
      <div class="tabbox">

        <div class="title_box">
          <el-tabs v-model="form.articleCategory" style="width: 550px;" @tab-click="handleClick">
            <el-tab-pane label="全部" name="全部" />
            <el-tab-pane v-for="item in ditcData" :key="item.dictValue" :label="item.dictLabel" :name="item.dictValue" />
          </el-tabs>
          <div class="search-btn-box" style="padding-right: 20px;">
            <el-input
              v-model="keyword"
              class="search-controller"
              size="medium"
              clearable
              prefix-icon="el-icon-search"
              placeholder="请输入关键字"
              @clear="search"
              @keyup.enter.native="search"
            >
              <el-select slot="prepend" v-model="selectVal" placeholder="请选择">
                <el-option label="标题" value="title" />
                <el-option label="内容" value="htmlContent" />
              </el-select>
              <template slot="append" class="search-btn">
                <span @click="search">搜索</span>
              </template>
            </el-input>
          </div>
        </div>
        <div flex="c" style="    padding-left: 20px;padding-top: 18px ">
          <div class="lable-box">
            <el-checkbox-button
              v-model="xflbAll"
              class="labelCheck allBtn"
              label="全部"
              border
              size="small"
              @change="kckAll"
            />
          </div>
          <el-checkbox-group v-model="form.categoryIds" class="lable-box" size="small" @change="kcheck">
            <el-checkbox-button
              v-for="item in xflbData"
              :key="item.dictValue"
              class="labelCheck"
              :label="item.dictValue"
              border
            >
              {{ item.dictLabel }}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
        <searchList
          v-if="pageShow"
          v-loading="loading"
          show-bar
          :down-id="downId"
          :tablespecific="tablespecific"
          :table-data="list"
          :page-data="pageData"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          @toHref="toHref"
          @clickDown="clickDown"
        />
      </div>
    </div>
    <template slot="actionBar">
      <div v-if="downReport > 0" class="downBox">
        <span> 已选中<span class="sum">{{ downReport }}</span>篇报告（{{ downReport >= 10 ? '每次最多下载10篇' : '可继续添加' }}）</span>
        <el-button :disabled="downdisable" type="primary" :icon="downIcon" @click="downAll('专题分析','分析报告')">{{ downText }}</el-button>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 专题分析动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { buryPoint } from '@/api/common'
import fileMixin from '@/mixin/newFile.js'
import { Message } from 'element-ui'
import { fmtDate } from '@/utils/date.js'
import { selectDictDataByType } from '@/api/dict.js'
import searchList from '@/components/searchList.vue'
import TextOverTooltip from '@/components/title/index'
import { getAllTages, page, selectTop } from '@/api/thematicAnalysis.js'
import { getUserInfo, logRecords, openSy  } from '@/utils/auth.js'
export default {
  components: {
    TextOverTooltip,
    searchList
  },
  mixins: [fileMixin],
  data() {
    return {
      defaultUrl: require('@/assets/img/default-img.png'),
      activeName: '全部',
      selectVal: 'title',
      downText: '立即下载',
      downdisable: false,
      downIcon: 'el-icon-download',
      keyword: '',
      checkData: [],
      downReport: 0,
      downId: [],
      tablespecific: true,
      showTips: [
        {
          title: '专题分析动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '专题分析库查询',
          text: '权威数据库，多条件筛查关联比对'
        },
        {
          title: '专题分析梳理',
          text: '专家梳理整合，真正读懂专题分析脉络'
        },
        {
          title: '专题分析大数据',
          text: '用数据深度理解专题分析结构和趋势'
        }
      ],
      tagText: '原创推荐',
      loading: false,
      list: [],
      tableDataRight: [],
      selectToList: [],
      show: false,
      showP: false,
      form: {
        categoryIds: [], // 关键字
        articleCategory: '全部', // 文章类型
        contentCategory: ' '
      },
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      formLog: {
        eventName: '开通卡片点击',
        eventType: '',
        columnName: '专题分析',
        userId: ''
      },
      ditcData: [],
      xflbData: [],
      xflbAll: true,
      pageShow: true

    }
  },
  watch: {
    downId: {
      deep: true,
      // immediate: true,
      handler(newVal, oldVal) {
        this.downReport = newVal.length
      }
    },
    'form.categoryIds': {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.xflbAll = !(newVal.length > 0)
      }
    },
    selectVal: {
      deep: true,
      handler(newVal, oldVal) {
        this.page()
      }
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
      // this.getAllTages();
    } else {
      this.show = false
    }
    this.page()
    this.pageRight()
    this.selectTop()
    this.selectDictDataByTypewz()
    this.selectDictDataByTypexflb()
  },
  methods: {
    selectTop() {
      selectTop({ column1: '专题分析', column2: '分析报告' }).then(res => {
        this.selectToList = res.data
      })
    },
    leaveTab(activeName, oldActiveName) {
      if (!getUserInfo()) {
        return false
      }
    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    reset() {
      this.downId = []
      this.pageData = {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      }
      this.keyword = ''
      this.form.categoryIds = []
    },
    // 搜索
    search() {
      const { keyword } = this
      if (keyword) {
        logRecords('专题分析', '分析报告', '4', 'tt_special', '', '', '', keyword)
      }
      this.page()
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    clickDown(item, id) {
      if (this.downReport >= 10 && item.check !== true) {
        Message({
          showClose: true,
          message: '每次最多下载10篇',
          type: 'error'
        })
        return
      } else {
        if (!this.downId.includes(id)) {
          this.downId.push(id)
        } else {
          const index = this.downId.indexOf(id)
          if (index > -1) {
            this.downId.splice(index, 1)
          }
        }
        this.checkFun(item)
      }
    },
    toHref(url, item) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('专题分析', '分析报告', '1', 'tt_special', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('专题分析')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    rankingtoHref(url, item) {
      this.formLog.eventType = '1'
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('专题分析', '分析报告', '1', 'tt_special', item.article_id, '', item.article_name, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {

          const menuName = getUserInfo().menuName
          if (!menuName.includes('专题分析')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.page()
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.page = val
        this.page()
      }
    },
    handleClick(tab) {
      this.form.articleCategory = tab.name
      this.selectDictDataByTypexflb()
      this.page()
    },
    tabClick(tab) {
      if (getUserInfo()) {
        this.reset()
        if (tab.name == 'yc') {
          this.tagText = '最新发布'
        } else if (tab.name == ' ') {
          this.tagText = '原创推荐'
        }
        this.page()
        this.pageRight()
      } else {
        // this.form.contentCategory = "yc"
        this.showLogin()
      }
    },
    moreClick(tab, event) {
      if (getUserInfo()) {
        this.reset()
        if (this.form.contentCategory == 'yc') {
          this.tagText = '原创推荐'
          this.form.contentCategory = ' '
        } else if (this.form.contentCategory == ' ') {
          this.tagText = '最新发布'
          this.form.contentCategory = 'yc'
        }
        this.page()
        this.pageRight()
      } else {
        // this.form.contentCategory = "yc"
        this.showLogin()
      }
    },
    // 获取列表
    page() {
      const form = Object.assign({}, this.form, this.pageData.pages, { [this.selectVal]: this.keyword })
      form.articleCategory =
        form.articleCategory == '全部' ? '' : form.articleCategory
      form.categoryIds = form.categoryIds.join(',')
      this.loading = true
      page(form).then((res) => {
        this.list = res.data.list
        this.pageData.total = res.data.count
        this.loading = false
      })
    },

    // 获取右侧列表
    pageRight() {
      const form = Object.assign({}, this.form, this.pageData.pages)

      form.articleCategory =
        form.articleCategory == '全部' ? '' : form.articleCategory
      form.categoryIds = form.categoryIds.join(',')
      if (form.contentCategory == 'yc') {
        form.contentCategory = ''
      } else if (form.contentCategory == ' ') {
        form.contentCategory = 'yc'
        form.pageSize = 3
      }
      page(form).then((res) => {
        this.tableDataRight = res.data.list.map((item) => {
          item.publishDate = fmtDate(
            'YYYY-mm-dd',
            item.publishDate
          )
          return item
        })
      })
    },
    // 字典获取文章类别
    selectDictDataByTypewz() {
      selectDictDataByType({ dictType: 'ztfx_wzlb' }).then((res) => {
        this.ditcData = res.data
      })
    },
    // 细分类别
    selectDictDataByTypexflb() {
      selectDictDataByType({ dictType: 'ztfx_xflb' }).then((res) => {
        this.xflbData = res.data
      })
    },
    // 获取标签
    getAllTages() {
      const articleCategory =
        this.form.articleCategory == '全部'
          ? ''
          : this.form.articleCategory
      getAllTages({ articleCategory }).then((res) => {
        this.xflbData = res.data
      })
    },
    // 关键字
    kcheck(e) {
      this.page()
    },
    // 关键字全选
    kckAll() {
      this.form.categoryIds = []
      this.page()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === '专题分析详情') {
      // 详情页路由
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    next()
  }
}
</script>
<style lang="scss" scoped>
.right-img-box {
  width: 100%;
  padding: 5px 0;
  position: relative;
  .img-box {
    width: 50%;
    padding-top: 130px;
    position: relative;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px;
    padding: 5px 0 5px 10px;
    flex: 1;
    font-size: 18px;
  }
}
.tabs {
  ::v-deep {
    .el-tabs__nav-wrap::after {
      width: 0;
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item {
      padding: 0 24px;
      color: #666;
      height: 48px;
      line-height: 48px;
      font-weight: normal;

      &:hover {
        font-weight: normal;
      }

      &.is-active {
        color: #3f76ea;
        font-weight: bold;
      }
    }

    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 0;
    }
  }
}

.ycDiv {
  display: inline-block;
  color: #0d57bc;
  font-size: 12px;
  line-height: 16px;
  padding: 2px;
  background: rgba(13, 87, 188, 0.08);
  margin-right: 4px;
}

::v-deep {
  .el-checkbox__input {
    display: none;
  }

  .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
  }

  .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0;
  }
}

.box {
  ::v-deep {
    .search-btn-box .el-input__inner {
      min-width: 200px;
    }

    .allBtn .el-checkbox-button__inner {
      padding: 8px 18px;
      border-radius: 4px;
    }

    .is-checked .el-checkbox-button__inner {
      background: #3f76ea;
      font-weight: normal;
      border: 0;
    }

    .title_box .el-select .el-input__inner {
      text-align: center;
      //background: #fff;
      min-width: 80px;
      padding: 0 30px 0 12px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .tabbox {
    background: #fff;
    padding-top: 8px;
    margin-bottom: 14px;

    ::v-deep {
      .el-tabs {
        padding: 0 22px;
      }

      .el-tabs__active-bar {
        height: 1px;
      }

      .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f3f5f7;
      }

      .el-tabs__header {
        margin: 0;
      }

      .el-tabs__item {
        padding: 0 16px;
        color: #606266;
        font-weight: normal;

        &:hover {
          font-weight: normal;
        }

        &.is-active {
          color: #3f76ea;
          font-weight: bold;
        }
      }

      .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
      .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
      .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
      .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        padding-left: 0;
      }
    }
  }
}

.title_box {
  padding-bottom: 1px;
  //border-bottom: 1px solid #DCDFE6;
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  //background: #f3f5f7;

  ::v-deep {
    .allBtn .el-checkbox-button__inner {
      padding: 8px 18px;
      border-radius: 4px;
    }
  }

  [flex="c"] {
    align-items: flex-start;
    padding-top: 3px;
  }
}

.search-controller {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);

  ::v-deep .el-input__inner {
    border-color: #eaeef7;
  }

  ::v-deep .el-input-group__prepend {
    padding-left: 12px;
    padding-right: 12px;
    color: #333;
    background-color: #fff;
    border-color: #eaeef7;

    .el-select {
      position: relative;
      margin-left: -12px;
      margin-right: -12px;

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 0;
        width: 1px;
        background-color: #f3f5f7;
      }

      .el-input__suffix {
        right: 3px;
      }
    }
  }

  ::v-deep .el-input__prefix {
    font-size: 16px;
  }

  ::v-deep .el-input-group__append {
    padding-left: 16px;
    padding-right: 16px;
    color: #fff;
    background-color: #3f76ea;
    border-color: #3f76ea;
    cursor: pointer;

    .el-button {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      border: none;
      font-weight: normal;
    }
  }
}

.lable-box {
  margin-top: -8px;

  ::v-deep .labelCheck {
    margin-right: 10px;
    margin-top: 8px;

    .el-checkbox-button__inner {
      color: #333;
      border: 0;
      border-radius: 4px;
    }

    &.is-checked {
      .el-checkbox-button__inner {
        color: #fff;
      }
    }
  }
}

::v-deep {
  .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
    height: 57px;
    width: 41px;
    padding-top: 15px;
    writing-mode: vertical-lr;
    padding-left: 0;
    padding-right: 0;
  }

  // .el-tabs__nav-wrap::after {
  //   width: 0;
  // }
  .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
    background-color: #0d57bc;
    color: #ffffff;
  }
}

.index-banner-title {
  .index-banner-new {
    background: #fff;
    padding-bottom: 18px;

    .title-tag {
      background: linear-gradient(#f2f5fd, #fff);
    }
  }

  .index-banner-hot {
    margin-top: 10px;
    padding-bottom: 18px;
    background: #fff;

    .title-tag {
      background: linear-gradient(#fff3ee, #fffbf9);
    }
  }
}

.title-tag {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 12px;
  padding: 22px 20px 0;

  .title-tag-text {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    span {
      padding-right: 10px;
      font-weight: bold;
      color: #0d57bc;
    }
  }

  .more {
    margin-right: -4px;
    color: #9ab7f4;
  }
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.index-new-timeLine {
  margin-left: 20px;
  margin-right: 20px;

  li {

    // position: relative;
    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 7px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: #fff;
    //   border: 2px solid #0d57bc;
    //   z-index: 1;
    // }
    .index-new-timeLine-content {
      position: relative;

      .index-new-link {
        padding: 8px 0;
        display: block;
        color: #000;

        // padding-left: 20px;
        .publishDate {
          color: #bbbbbb;
        }
        .updateTime {
          color: #bbbbbb;
        }
        span {
          font-size: 16px;
          padding-right: 10px;
          font-weight: 600;
        }

        .one {
          color: #ff0000;
        }

        .two {
          color: #ff6000;
        }

        .three {
          color: #fdd58a;
        }

        .ranking {
          color: #cccccc;
        }

        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.newUl-title {
  flex: 1;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding-right: 24px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
