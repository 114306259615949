/*
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-08-15 14:54:12
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-12 09:33:30
 */
// 数据
import $http from "@/plugins/http.js";

// 国内产销数据列表查询
export function getOverallSales() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    overallSales: null,
  });
}
// 乘用车市场表现-全部数据
export function getPassengerMarketPerformanceAll() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance: "passengerMarketPerformance-all",
  });
}
// 乘用车市场表现-乘用车分地区销量-全部
export function getPassengerMarketPerformanceRegionOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance: "passengerMarketPerformance-region-only",
  });
}
// 乘用车市场表现-乘用车分地区销量-新能源
export function getPassengerMarketPerformanceRegionNewEnergy() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance:
      "passengerMarketPerformance-region-only;passengerMarketPerformance-region-newEnergy",
  });
}
// 乘用车市场表现-企业销量榜单
export function getPassengerMarketPerformanceEnterpriseOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance: "passengerMarketPerformance-enterprise-only",
  });
}
// 乘用车市场表现-获取车型榜单
export function getPassengerMarketPerformanceVehicleModelOnly(data) {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance: `passengerMarketPerformance-vehicleModel-only${data}`,
  });
}
// 乘用车市场表现-获取车型榜单-新能源
export function getPassengerMarketPerformanceVehicleModelNewEnergy() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance:
      "passengerMarketPerformance-vehicleModel-newEnergy",
  });
}
// 乘用车市场表现-获取车型榜单-纯电动
export function getpassengerMarketPerformanceVehicleModelBev() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance: "passengerMarketPerformance-vehicleModel-bev",
  });
}
// 乘用车市场表现-获取车型榜单-插电混
export function getpassengerMarketPerformanceVehicleModelPhev() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance: "passengerMarketPerformance-vehicleModel-phev",
  });
}
// 乘用车市场表现-获取车型榜单-甲醇
export function getpassengerMarketPerformanceVehicleModelMethanol() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance:
      "passengerMarketPerformance-vehicleModel-methanol",
  });
}
// 乘用车市场表现-获取车型榜单-燃料电池
export function getpassengerMarketPerformanceVehicleModelFuelCell() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    passengerMarketPerformance:
      "passengerMarketPerformance-vehicleModel-fuelCell",
  });
}
// 新能源专区-全部数据
export function getNewEnergyAll() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy: "newEnergy-all",
  });
}
// 新能源专区-公共类充电桩保有量
export function getNewEnergyChargingStationOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy: "newEnergy-chargingStation-only",
  });
}
// 新能源专区-动力电池装机量走势图
export function getNewEnergyBatteryOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy: "newEnergy-battery-only",
  });
}
// 新能源专区-省份充电桩保有量
export function getNewEnergyProvinceChargingChargingPoint() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy:
      "newEnergy-provinceCharging-only;newEnergy-provinceCharging-chargingPoint",
  });
}
// 新能源专区-省份充电站保有量
export function getNewEnergyProvinceChargingChargingStation() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy:
      "newEnergy-provinceCharging-only;newEnergy-provinceCharging-chargingStation",
  });
}
// 新能源专区-省份换电站保有量
export function getNewEnergyProvinceChargingPowerExchangeStation() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy:
      "newEnergy-provinceCharging-only;newEnergy-provinceCharging-powerExchangeStation",
  });
}
// 新能源专区-运营商充电桩保有量
export function getNewEnergyEnterpriseChargingChargingPoint() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy:
      "newEnergy-enterpriseCharging-only;newEnergy-enterpriseCharging-chargingPoint",
  });
}
// 新能源专区-运营商充电站保有量
export function getNewEnergyEnterpriseChargingChargingStation() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy:
      "newEnergy-enterpriseCharging-only;newEnergy-enterpriseCharging-chargingStation",
  });
}
// 新能源专区-运营商换电站保有量
export function getNewEnergyEnterpriseChargingPowerExchangeStation() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy:
      "newEnergy-enterpriseCharging-only;newEnergy-enterpriseCharging-powerExchangeStation",
  });
}
// 新能源专区-动力电池装机量TOP10企业
export function getnewEnergyEnterpriseBatteryOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    newEnergy: "newEnergy-enterpriseBattery-only",
  });
}

// 全球数据-全部数据
export function getGlobalDataAll() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    globalData: "globalData-all",
  });
}
// 全球数据-进、出口量
export function getGlobalDataCarExportOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    globalData: "globalData-carExport-only",
  });
}
// 全球数据-进、出口金额
export function getGlobalDataAmountOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    globalData: "globalData-amount-only",
  });
}
// 全球数据-获取出口量TOP10数据
export function getGlobalDataExportOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    globalData: "globalData-export-only",
  });
}
// 全球数据-获取主流国家销量走势
export function getGlobalDataCountryOnly() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    globalData: "globalData-country-only",
  });
}
// 全球数据-获取出口量TOP10数据下，筛选 企业
export function getGlobalDataExportEnterprise() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    globalData: "globalData-export-only;globalData-export-enterprise",
  });
}
// 全球数据-获取出口量TOP10数据下，筛选 车型
export function getGlobalDataExportVehicleModel() {
  return $http.post("/api/newData/displayReport/displayReportData", {
    globalData: "globalData-export-only;globalData-export-vehicleModel",
  });
}
// 充电基础设施-获取月度趋势展示数据接口
export function getChargingInfrastructure() {
  return $http.post("/api/newData/displayData/chargingPoints", {
    classify: "monthlyData",
  });
}
// 充电基础设施-获取省份展示数据接口
export function getProvinceData() {
  return $http.post("/api/newData/displayData/chargingPoints", {
    classify: "provinceData",
  });
}

// 充电基础设施-获取运营商展示数据接口
export function getEnterpriseData() {
  return $http.post("/api/newData/displayData/chargingPoints", {
    classify: "enterpriseData",
  });
}
// 产品价格-原材料价格
export function getMaterialPrice() {
  return $http.post("/api/newData/displayData/materialPrice", {});
}

// 数据栏目进入某个拖拉拽查询页面时的初始化接口
export function draggingPageInit(data) {
  // return $http.post('/draggingPageInit', data)
  return $http.post("/api/newData/searchData/draggingPageInit", data);
}

// 数据栏目获取某个拖拉拽页面的某个维度的下拉选项
export function getDimensionSelectOptions(data) {
  return $http.post("/api/newData/searchData/getDetailQueryOptionList", data);
}

// 数据栏目拖拉拽查询
export function draggingQuery(data) {
  return $http.post("/api/newData/searchData/draggingQuery", data);
}

// 数据栏目拖拉拽页面，保存查询模板
export function saveDraggingQueryTemplate(data) {
  return $http.post("/api/newData/searchData/addSearchModel", data);
}

// 数据栏目拖拉拽页面，删除查询模板
export function deleteDraggingQueryTemplate(data) {
  return $http.post("/api/newData/searchData/deleteSearchModel", data);
}

// 数据栏目拖拉拽下载数据 POST
export function exportDraggingQuery(data) {
  return $http.DownloadDb("/api/newData/searchData/exportDraggingQuery", data);
}

// 国际化进入某个拖拉拽查询页面时的初始化接口
export function internationalDraggingPageInit(data) {
  // return $http.post('/draggingPageInit', data)
  return $http.post("/api/international/searchData/draggingPageInit", data);
}

// 国际化获取某个拖拉拽页面的某个维度的下拉选项
export function internationalGetDimensionSelectOptions(data) {
  return $http.post(
    "/api/international/searchData/getDetailQueryOptionList",
    data
  );
}

// 国际化拖拉拽查询
export function internationalDraggingQuery(data) {
  return $http.post("/api/international/searchData/draggingQuery", data);
}

// 国际化拖拉拽页面，保存查询模板
export function saveInternationalDraggingQueryTemplate(data) {
  return $http.post("/api/international/searchData/addSearchModel", data);
}

// 国际化拖拉拽页面，删除查询模板
export function deleteInternationalQueryTemplate(data) {
  return $http.post("/api/newData/searchData/deleteSearchModel", data);
}

// 国际化拖拉拽下载数据 POST
export function internationalExportDraggingQuery(data) {
  return $http.DownloadDb(
    "/api/international/searchData/exportDraggingQuery",
    data
  );
}

// mock 拖拉拽查询
export function mockDraggingQuery(url, data) {
  return $http.post(`/mock${url}`, data);
}

// PPT报告
export function dataReportList(data) {
  return $http.post(
    `/api/newData/displayReport/dataReportList?page=1&pageSize=1000&${data}`,
    {}
  );
}
// 获取PPT报告日期
export function selectReportDate(data) {
  return $http.post("/api/newData/displayReport/selectReportDate", {});
}
// 获取PPT报告类型
export function selectDictDataByType(data) {
  return $http.post(
    "/applet/selectDictDataByType?dictType=data_report_type",
    {}
  );
}

// 国际化 国际资讯-热点
export function gethoutInformation(parma, data) {
  return $http.post("/api/international/information/page" + parma, data);
}
// 国际化 国际资讯-详情
export function getInformationInfo(id) {
  return $http.get("/api/international/information/getInfo/" + id);
}
// 国际化政务口岸-List
export function getPortInformation(parma, data) {
  return $http.post("/api/international/portInformation/page" + parma, data);
}
// 国际化政务口岸-info
export function getPortInformationInfo(id) {
  return $http.get("/api/international/portInformation/getInfo/" + id);
}
// 国际化政务口岸-HotList
export function getHotData() {
  return $http.get("/api/international/portInformation/getHotData");
}
// 国际化研究报告HotList
export function getinternationalReportHotData() {
  return $http.get("/api/international/internationalReport/getHotData");
}
// 国际化研究报告-获取三个榜单
export function getRank() {
  return $http.get("/api/international/internationalReport/getRank");
}
// 国际研究报告-info
export function getInternationalReportInfo(id) {
  return $http.get("/api/international/internationalReport/getInfo/" + id);
}
// 国际化研究报告-list
export function getInternationalReport(parma, data) {
  return $http.post(
    "/api/international/internationalReport/page" + parma,
    data
  );
}
// 国际化研究报告-list
export function internationalGetDate(url) {
  return $http.get("/api" + url);
}

// 查询是否需要进行新人引导
export function guideRecordCheck(data) {
  return $http.post("/api/newData/searchData/guideRecordCheck", data);
}

// 记录用户完成新人引导
export function markGuideRecord(data) {
  return $http.post("/api/newData/searchData/markGuideRecord", data);
}

// 获取数据定制/API接入的需求类型
export function selectDictDataByRequirementType() {
  return $http.post("/applet/selectDictDataByType?dictType=requirement_type");
}

// 提交数据定制/API接入的需求登记
export function insertRequirementRecord(data) {
  return $http.post("/api/newData/displayReport/insertRequirementRecord", data);
}
// 获取全球国家热力图
export function getSaleData(data) {
  return $http.get("/api/international/abroadSale/getSaleData", data);
}

// 获取乘用车销量数据公共接口
export function getDisplayDataSaleData(data) {
  return $http.post("/api/newData/displayData/salesData", data);
}
// 获取乘用车销量数据公共导出接口
export function exportSalesData(data) {
  return $http.DownloadDb("/api/newData/displayData/exportSalesData", data);
}
// 乘用车销量数据年分
export function zbQueryYear() {
  return $http.get("/api/newData/displayData/queryYear");
}
// 乘用车销量数据月份
export function ykbJjzbQueryMonth(params) {
  return $http.get("/api/newData/displayData/queryMonth", params);
}
// 充电基础设施公共导出接口
export function exportChargingPoints(data) {
  return $http.DownloadDb(
    "/api/newData/displayData/exportChargingPoints",
    data
  );
}
// 原材料价格公共导出接口
export function exportMaterialPrice(data) {
  return $http.DownloadDb("/api/newData/displayData/exportMaterialPrice", data);
}
