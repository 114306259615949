import { render, staticRenderFns } from "./historyInfo.vue?vue&type=template&id=630e2c3d&scoped=true&"
import script from "./historyInfo.vue?vue&type=script&lang=js&"
export * from "./historyInfo.vue?vue&type=script&lang=js&"
import style0 from "./historyInfo.vue?vue&type=style&index=0&id=630e2c3d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630e2c3d",
  null
  
)

export default component.exports