<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="公告" text="公告栏目围绕公告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新公告" :showTips="showTips">-->
    <!--            <slot slot="table">-->
    <!--                &lt;!&ndash; <el-table-->
    <!--            :data="tableData"-->
    <!--            :header-cell-style="{ background: '#FAFAFA', color: '#000' }"-->
    <!--            style="width: 100%; margin-bottom: 20px"-->
    <!--          >-->
    <!--            <el-table-column prop="nat" label="不限地区" width="108" sortable />-->
    <!--            <el-table-column prop="nat" label="公告名称" fit />-->
    <!--            <el-table-column prop="nat" label="公告类别" width="108" sortable />-->
    <!--            <el-table-column prop="nat" label="颁布时间" width="108" sortable />-->
    <!--            <el-table-column prop="nat" label="实施时间" width="108" sortable />-->
    <!--            <el-table-column prop="nat" label="废止时间" width="108" sortable />-->
    <!--          </el-table> &ndash;&gt;-->
    <!--            </slot>-->
    <!--        </VnotLogin>-->
    <template>
      <div class="dynamic-top">
        <div flex="sb-noa">
          <div class="flex_top">
            <div flex="sb">
              <span class="flex_top_num" @click="notice(infoCountData.ggDataNewPc,1)">{{
                  infoCountData.ggDataNewPc
                }}</span>
              <!-- <span class="flex_top_text">{{ LastUpdateTime }} 更新</span> -->
            </div>
            <p class="flex_top_text">公告数据已更新至该批次</p>
            <div flex="c" style="text-align: center">
              <div class="flex_top_car" @click="cplbBtn('整车')">
                <span>{{ infoCountData.ggZCCount }}</span>条
                <p>整车</p>
              </div>
              <div class="flex_top_car" @click="cplbBtn('摩托车')">
                <span>{{ infoCountData.ggMotorCount }}</span>条
                <p>摩托车</p>
              </div>
              <div class="flex_top_car" @click="cplbBtn('底盘')">
                <span>{{ infoCountData.ggDPCount }}</span>条
                <p>底盘</p>
              </div>
              <div class="flex_top_car" @click="cplbBtn('三轮汽车')">
                <span>{{ infoCountData.ggTricycleCount }}</span>条
                <p>三轮汽车</p>
              </div>
            </div>
          </div>
          <div style="width: 50%; padding-left: 30px">
            <div flex="sb">
                            <span class="flex_top_num" @click="notice(infoCountData.ggPublicNewPc)">{{
                                infoCountData.ggPublicNewPc
                              }}</span>
              <!-- <span class="flex_top_text">{{ PublicLastUpdateTimec }} 更新</span> -->
            </div>
            <p class="flex_top_text">公告公示数据已更新至该批次</p>
          </div>
        </div>
      </div>
      <div flex="sb-noa">
        <div style="width: 50%">
          <ItemTitle title="公告动态" class="title-padding" toHref="/announcement/dynamic/ggPublicList"/>

          <el-card style="margin: 0 10px 10px 0" v-for="item in ggPublicData" :key="item.id">
            <div flex="sb-noa">
              <div style="padding-right:28px" flex='1' class="hover text-overflow-4" @click="toHref(item)">
                {{ item.dynamicTitle }}
              </div>
              <div style="width: 115px; text-align: center">
                {{ item.publishDate }}
              </div>
            </div>
          </el-card>
        </div>
        <div style="width: 50%">
          <ItemTitle title="公告批文" class="title-padding" toHref="/announcement/dynamic/ggApprovalcList"/>

          <el-card style="margin: 0 10px 10px 0" v-for="item in ggApprovalData" :key="item.id">
            <div flex="sb-noa">
              <div style="padding-right:28px" flex='1' class="hover text-overflow-4" @click="toHref2(item)">
                {{ item.dynamicTitle }}
              </div>
              <div style="width: 115px; text-align: center">
                {{ item.publishDate }}
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {
  getInfoCount,
  ggPublic,
  ggApproval,
  getggLastUpdateTime,
  getggPublicLastUpdateTime,
} from "@/api/announcement.js";
import {fmtDate} from "@/utils/date.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      showTips: [
        {
          title: "公告动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "公告库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "公告梳理",
          text: "专家梳理整合，真正读懂公告脉络",
        },
        {
          title: "公告大数据",
          text: "用数据深度理解公告结构和趋势",
        },
      ],
      data1: [
        {
          id: 1,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大两会政",
          time: "2021-3-27",
          important: true,
        },
        {
          id: 2,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
        },
        {
          id: 3,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
          important: true,
        },
        {
          id: 4,
          title: "两会政府工作报告中的六大发发发府工作报告中的六大发",
          time: "2021-3-27",
        },
      ],
      loading: false,
      tableData: [],
      pagination: {},
      currentPage: 0,
      show: false,
      infoCountData: {
        ggDPCount: 0,
        ggDataNewPc: 0,
        ggMotorCount: 0,
        ggTricycleCount: 0,
        ggZCCount: 0,
        ggPublicNewPc: 0,
      },
      ggPublicData: [],
      ggApprovalData: [],
      LastUpdateTime: "",
      PublicLastUpdateTimec: "",
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "公告",
        userId: ""
      },
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

      // this.getggLastUpdateTime();
      // this.getggPublicLastUpdateTime();
    } else {
      this.show = false;
    }
    this.getInfoCount();
    this.ggPublic({page: 1, pageSize: 5});
    this.ggApproval({page: 1, pageSize: 5});
  },
  computed: {
    nowTime() {
      return fmtDate("YYYY-mm-dd", new Date());
    },
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(item) {
      let newUrl = this.$router.resolve("/announcement/dynamic/dynamicApprovalContent/" + item.id);
      if (getUserInfo()) {

        logRecords('公告','公告动态','1','tt_dynamic_approval',item.id,'',item.dynamicTitle,'')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')){
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        }else{
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    toHref2(item) {
      let newUrl = this.$router.resolve("/announcement/dynamic/ggApprovalcContent/" + item.id);
      if (getUserInfo()) {

        logRecords('公告','公告批文','1','tt_dynamic_approval',item.id,'',item.dynamicTitle,'')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')){
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        }else{
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    handleSizeChange() {
    },
    handleCurrentChange() {
    },
    //公告数量
    getInfoCount() {
      getInfoCount().then((res) => {
        this.infoCountData = res.data;
      });
    },
    //公告公示
    ggPublic(parmas) {
      ggPublic(parmas).then((res) => {
        this.ggPublicData = res.data.list;
      });
    },
    //公告批文
    ggApproval(parmas) {
      ggApproval(parmas).then((res) => {
        this.ggApprovalData = res.data.list;
      });
    },
    notice(num, type) {
      this.$store.dispatch("notice/SET_PC", num);
      if (type == 1) {
        this.$router.push("/announcement/announcementSearch/index");
      } else {
        this.$router.push("/announcement/search/index");
      }
    },
    //跳转
    cplbBtn(name) {
      this.$store.dispatch("notice/SET_CPLB", name);
      this.$router.push("/announcement/announcementSearch/index");
    },
    getggLastUpdateTime() {
      getggLastUpdateTime().then((res) => {
        if (res.ok) {
          this.LastUpdateTime = res.data;
        }
      });
    },
    getggPublicLastUpdateTime() {
      getggPublicLastUpdateTime().then((res) => {
        if (res.ok) {
          this.PublicLastUpdateTimec = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.flex_top {
  width: 50%;
  border-right: 1px solid #efefef;
  padding-right: 30px;

  &_num {
    font-size: 30px;
    color: #0d57bc;
    font-weight: 600;
  }

  &_text {
    color: #989898;
  }

  &_car {
    margin: 20px 20px 0 0;

    span {
      font-size: 20px;
      font-weight: 600;
    }

    p {
      color: #0d57bc;
    }
  }
}

.title-padding {
  padding: 20px 30px;
}

.hover:hover {
  color: #0d57bc;
  cursor: pointer;
}

.dynamic-top {
  padding: 20px 32px;
  margin-bottom: 12px;
  background: #fff;

  .dynamic-top-original-box {
    display: flex;
    margin-top: 32px;
    margin-left: -26px;
    cursor: pointer;

    .dynamic-top-original {
      display: flex;
      flex: 1;
      margin-left: 26px;

      .dynamic-top-original-img {
        width: 177px;
        height: 99px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          box-shadow: 0px 0px 7.31074px 3.65537px rgba(225, 225, 225, 0.25);
        }
      }

      .dynamic-top-original-inner {
        margin-left: 6px;

        p {
          line-height: 21px;
          color: #000;
          font-weight: 500;
          height: 64px;
        }

        span {
          color: #828282;
        }
      }
    }
  }
}

.flex_top_num,
.flex_top_car {
  cursor: pointer;
}

/deep/ {
  .el-card__body {
    padding: 0;
    margin: 16px 24px;
    height: 91px;
    // white-space: normal;
    // text-overflow: -o-ellipsis-lastline;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 4;
    // line-clamp: 4;
    // -webkit-box-orient: vertical;
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
