import { render, staticRenderFns } from "./list-syc.vue?vue&type=template&id=840d7b82&scoped=true&"
import script from "./list-syc.vue?vue&type=script&lang=js&"
export * from "./list-syc.vue?vue&type=script&lang=js&"
import style0 from "./list-syc.vue?vue&type=style&index=0&id=840d7b82&lang=scss&scoped=true&"
import style1 from "./list-syc.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "840d7b82",
  null
  
)

export default component.exports