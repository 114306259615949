<template>
  <div class="table-container">
    <el-table
      v-show="visible"
      ref="tableRef"
      :data="resultData.dataList"
      border
      stripe
      :span-method="getTableSpanMethod"
    >
      <table-child-column
        v-for="item in resultData.columnList"
        :key="draggingTableName + resultDataSeq + item.label"
        :result-data-seq="resultDataSeq"
        :dragging-table-name="draggingTableName"
        :column-item="item"
      />
      <!-- <template >
        <el-table-column
          v-else
          :key="resultDataSeq + parentColumnItem.label"
          align="center"
          :label="parentColumnItem.label"
        >
          <table-child-column
            v-for="childColumnItem in parentColumnItem.columnList"
            :key="resultDataSeq + childColumnItem.prop"
            :column-item="childColumnItem"
          />
        </el-table-column>
      </template> -->
    </el-table>
    <el-pagination
      v-if="visible && pageShow"
      background
      layout="total, prev, pager, next, sizes, jumper"
      :total="resultData.total"
      :current-page="pageParams.pageNo"
      :page-size="pageParams.pageSize"
      :page-sizes="[10, 30, 50, 100]"
      @size-change="handleSizeChange"
      @current-change="handlePageNoChange"
    />
  </div>
</template>

<script>
import TableChildColumn from './tableChildColumn'
import { getUserInfo, openSy } from '@/utils/auth.js'
import { buryPoint } from '@/api/common'

// 表格展示组件
export default {
  name: 'Table',
  components: {
    TableChildColumn
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    resultData: {
      type: Object,
      required: true
    },
    pageParams: {
      type: Object,
      required: true
    },
    // v-for 的 key 会用到
    draggingTableName: {
      type: String,
      required: true
    },
    // 是否正在以交叉表的形式展示
    isDisplayWithCrossTable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      // 用于防止表格列的 key 重复, 而导致列的排序不对
      resultDataSeq: -999999,
      pageShow: true
    }
  },
  computed: {
    getTableSpanMethod() {
      // 如果是交叉表，则进行合并单元格
      return this.isDisplayWithCrossTable ? this.tableSpanMethod : undefined
    }
  },
  watch: {
    resultData: {
      deep: true,
      handler() {
        this.resultDataSeq++
      }
    }
  },
  methods: {
    handleSizeChange(pageSize) {
      const menu = this.$route.meta.acName
      // 如果是试用用户
      if (getUserInfo()?.roleKey === 'trialrole' && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        this.doDialogBuryPoint()
        openSy()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      }
      if (this.pageParams.pageNo * pageSize > this.resultData.total) {
        // 防止触发 current-change 进而导致连续触发两次 page 事件
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        this.$emit('page', {
          ...this.pageParams,
          pageSize,
          pageNo: 1
        })
        return
      }
      this.$emit('page', {
        ...this.pageParams,
        pageSize
      })
    },
    handlePageNoChange(pageNo) {
      const menu = this.$route.meta.acName
      // 如果是试用用户
      if (getUserInfo()?.roleKey === 'trialrole' && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.doDialogBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      }
      this.$emit('page', {
        ...this.pageParams,
        pageNo
      })
    },
    // 弹窗埋点
    doDialogBuryPoint() {
      buryPoint({
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '数据',
        userId: getUserInfo()?.userId
      })
    },
    // 表格的合并方法
    tableSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 用于判断当前单元格是否需要进行合并
      let needSpan = false
      // 将 columnIndex 作为索引值，在后端返回的 columnList 中找对应的列对象
      const columnItem = this.resultData.columnList[columnIndex]
      // 如果找到了，且该列对象的 categorical 为 true
      if (columnItem && columnItem.categorical) {
        const nextColumnItem = this.resultData.columnList[columnIndex + 1]
        // 并且下一列的 categorical 也是 true，则判定该列需要合并
        needSpan = Boolean(nextColumnItem && nextColumnItem.categorical)
      }
      if (needSpan) {
        const { dataList } = this.resultData
        // 如果当前行不是第一行，且当前行的该列值与上一行的该列值相等
        if (
          rowIndex > 0 &&
          dataList[rowIndex - 1] &&
          dataList[rowIndex - 1][column.property] === row[column.property]
        ) {
          // 则不展示该单元格
          return [0, 0]
        }
        let rowSpan = 0
        for (let i = rowIndex; i < dataList.length; i++) {
          const nextRow = dataList[i]
          if (nextRow[column.property] === row[column.property]) {
            rowSpan++
          }
        }
        return [rowSpan, 1]
      }
    },
    // 是否展示 减少 的箭头
    isShowDecreaseArrow(value) {
      return value && value.startsWith('-')
    },
    getAbsValue(value) {
      return value && Math.abs(value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.table-container {
  padding: 0 $dragging-result-container-external-width;
}

::v-deep {
  .el-table {
    th {
      background-color: #FAFAFA;
    }
    thead {
      color: #333;
    }
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D57BC;
  }

  .categorical {
    color: #333 !important;
    font-weight: bold;
    background-color: #F5F7FA !important;
  }
}

// 需要展示箭头的单元格
::v-deep .arrow-cell {
  display: flex;
  align-items: center;
  color: #F53F3F;

  img {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    pointer-events: none;
  }

  // 展示下降箭头
  &.decrease {
    color: #00B42A;
  }
}
</style>
