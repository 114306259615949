var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"common-table-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"left"},[_c('h3',{staticClass:"title-tag-text",style:({
                fontFamily: 'MicrosoftYaHei-Bold',
                fontSize: '18px',
                fontWeight: 600,
                display: 'inline-block'
            })},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),(_vm.$slots.cardSelect)?_c('span',[_vm._t("cardSelect")],2):_vm._e()]),(_vm.$slots.headPaginator)?_c('span',[_vm._t("headPaginator")],2):_vm._e()]),((_vm.sourceData.tableInfoStr && _vm.showStr) || _vm.$slots.tablePaginator)?_c('div',{staticClass:"table-head"},[(_vm.showStr)?_c('div',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.sourceData.tableInfoStr)+" ")]):_vm._e(),(_vm.$slots.tablePaginator)?_c('div',[_vm._t("tablePaginator")],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"table-content"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"custom-table-style",style:({ width: '100%', borderRadius: '4px' }),attrs:{"data":_vm.sourceData.tableData,"border":"","cell-style":_vm.cellStyle,"header-cell-style":_vm.headerCellStyle,"span-method":_vm.mergeAdjacentRowsByDeliveryStatus},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('el-empty',{attrs:{"description":"暂无数据"}})]},proxy:true}])},_vm._l((_vm.sourceData.tableHead),function(head){return _c('el-table-column',{key:head.prop,attrs:{"align":'center',"prop":head.prop,"label":head.label,"show-overflow-tooltip":(_vm.progressBar && head.prop !== _vm.progressBar) ||
                    !_vm.notTooltipList.includes(head.prop)},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.progressBar && head.prop === _vm.progressBar)?_c('div',{staticClass:"progressBar-content"},[_c('div',{staticClass:"progressBar-num"},[_vm._v(" "+_vm._s(scope.row[("" + _vm.progressBar)])+" ")]),_c('div',{staticClass:"progress-bar-container"},[_c('div',{staticClass:"progress-bar",style:({
                                width: _vm.calculateWidth(scope.row[("" + _vm.progressBar)]) + '%'
                            })})])]):(_vm.foldList.includes(head.prop) &&
                        scope.row[("" + (head.prop))] &&
                        scope.row[("" + (head.prop))].length > 25
                        )?_c('div',{staticClass:"content"},[(_vm.isCollapsed || _vm.toggleIndex !== scope.$index)?_c('div',[_vm._v(" "+_vm._s(scope.row[("" + (head.prop))].slice(0, 25) + "...")+" "),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toggle(scope)}}},[_c('span',{style:({
                                fontSize: '12px',
                                color: '#3f76ea',
                                marginRight: '10px'
                            })},[_vm._v("展开")]),_c('i',{staticClass:"el-icon-arrow-down",style:({ color: '#a9b9dc', fontSize: '12px' })})])],1):_c('div',[_vm._v(" "+_vm._s(scope.row[("" + (head.prop))])+" "),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toggle(scope)}}},[_c('span',{style:({
                                    fontSize: '12px',
                                    color: '#3f76ea',
                                    marginRight: '10px'
                                })},[_vm._v(" 收起 ")]),_c('i',{staticClass:"el-icon-arrow-up",style:({ color: '#a9b9dc', fontSize: '12px' })})])],1)]):_c('span',{staticClass:"hover-text"},[_vm._v(" "+_vm._s(scope.row[("" + (head.prop))])+" ")])]}}],null,true)})}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }