var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fab-container"},[_c('el-popover',{class:[{ visible: _vm.contact }],attrs:{"placement":"left","trigger":"hover","popper-class":"fab-contact-popover"}},[_c('div',{staticClass:"contact-container"},[_c('span',{staticStyle:{"color":"#0f0f0f"}},[_vm._v("欢迎联系我们！"),_c('br'),_vm._v("我们为您提供最全面、权威、专业的服务")]),_c('div',{staticStyle:{"padding":"10px","color":"#0f0f0f"}},[_c('span',[_vm._v("市场部-李经理")]),_c('br'),_c('img',{attrs:{"src":require("@/assets/img/dh.png")}}),_c('span',{staticStyle:{"color":"#ff6a00"}},[_vm._v("022-84370000-分机1582/18322511031")]),_c('br'),_c('img',{attrs:{"src":require("@/assets/img/yj.png")}}),_c('span',[_vm._v("liwenjuan@catarc.ac.cn")])])]),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/phone.png")}}),_c('span',[_vm._v("联系我们")])])]),_c('Investigate',{class:[{ visible: _vm.investigate }]}),(_vm.enabledAI)?_c('span',{class:['ai-service', { visible: _vm.service }],on:{"click":_vm.handleOpen}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/service-gray.svg")}}),_c('span',[_vm._v("在线问答")])]):_vm._e(),_c('span',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: 'body',
      duration: 100,
      lazy: false,
      easing: 'easing',
      force: true,
      cancelable: true,
      x: false,
      y: true,
    }),expression:"{\n      el: 'body',\n      duration: 100,\n      lazy: false,\n      easing: 'easing',\n      force: true,\n      cancelable: true,\n      x: false,\n      y: true,\n    }"}],class:['back-top', { visible: _vm.backtop }]},[_c('i',{staticClass:"icon el-icon-download"}),_c('span',[_vm._v("返回顶部")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }