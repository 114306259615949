<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-08 11:34:02
 * @LastEditors: 林子健
 * @LastEditTime: 2023-09-14 09:13:14
-->
<template>
  <PageContainer breadcrumb footer>
    <Vcontent :data="data" :props="props" is-content-two="qyjs" name="国际化资讯" :lang="lang"/>

    <template slot="right">
      <VcontentItem title="相关推荐" type="list" :list="list" @click="tohref" />
    </template>
  </PageContainer>
</template>
<script>
import {
  getInformationInfo // 获取公告数据
} from '@/api/data_new.js'
import { runLanguageDetection } from '@/api/common'
export default {
  data() {
    return {
      data: {},
      list: [],
      props: {
        tag: 'keywords',
        summary: 'introduction',
        publishDate: 'updateTime'
      },
      lang: 'zh'
    }
  },
  watch: {
    $route(to, from) {
      const id = to.params.id
      this.getInformationInfo(id)
      // 对路由变化作出响应...
    }
  },
  created() {
    const id = this.$route.params.id
    this.getInformationInfo(id)
    this.LanguageDetection(id, '国际化资讯')
  },
  methods: {
    getInformationInfo(id) {
      getInformationInfo(id).then((res) => {
        if (res.ok) {
          this.data = res.data
          this.list = res.data.recommendEOList
        }
      })
    },
    LanguageDetection(id, name) {
      const data = {
        id: id,
        name: name
      }
      runLanguageDetection(data).then((res) => {
        if (res.ok) {
          this.lang = res.data
        }
      })
    },
    // 跳转
    tohref(item) {
      if (item.tableName == 'tt_special') {
        this.$router.push('/thematicAnalysis/details/' + item.id)
      } else {
        this.$router.push('/cuttingEdgeTechnology/details/' + item.id)
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
