var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isShowStrategyPlanning)?_c('div',{staticClass:"enterpriseDetail strategyPlanning-detail",attrs:{"id":"StrategyPlanning","data-name":"战略规划"}},[_c('el-card',{attrs:{"body-style":{ paddingTop: '20px', paddingBottom: '34px' }}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"title-tag-text"},[_c('span',[_vm._v("战略规划")])]),_c('paginator',{attrs:{"total-items":_vm.totalCount,"items-per-page":3},on:{"page-change":_vm.handlePageChange}})],1),_c('div',{class:_vm.sourceData.length > 2 ? 'card-content' : 'card-content-start'},_vm._l((_vm.sourceData),function(o,a){return _c('div',{staticClass:"list-item",class:_vm.sourceData.length > 2
            ? { 'add-border': a === 1 }
            : { 'add-border-before': a === 1 }},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._l((o.label),function(i){return _c('span',{staticClass:"label"},[_vm._v(_vm._s(i))])}),_c('el-tooltip',{attrs:{"effect":"light","disabled":_vm.isDisabledTooltip,"content":o.title,"placement":"top","open-delay":300}},[_c('div',{staticClass:"title-text",on:{"mouseover":function($event){return _vm.onMouseOver($event, a)}}},[_c('span',[_vm._v(_vm._s(o.title))]),_c('div',{ref:"hiddenTitle",refInFor:true,staticClass:"hidden-title"},[_vm._v(" "+_vm._s(o.title)+" ")])])])],2),_c('div',[(o.summary.length > 280)?_c('div',{staticClass:"textInfo"},[(!_vm.toggleStates[a] || !_vm.toggleStates[a][o.summary])?_c('div',[_vm._v(" "+_vm._s(o.summary.slice(0, 280) + "...")+" "),_c('el-button',{style:({
                    fontSize: '12px',
                    color: '#3f76ea',
                    marginRight: '10px'
                  }),attrs:{"type":"text"},on:{"click":function($event){return _vm.toggle(a, o.summary)}}},[_vm._v(" 展开 "),_c('i',{staticClass:"el-icon-arrow-down",style:({ color: '#a9b9dc', fontSize: '12px' })})])],1):_c('div',[_vm._v(" "+_vm._s(o.summary)+" "),_c('el-button',{style:({
                    fontSize: '12px',
                    color: '#3f76ea',
                    marginRight: '10px'
                  }),attrs:{"type":"text"},on:{"click":function($event){return _vm.toggle(a, o.summary)}}},[_vm._v(" 收起 "),_c('i',{staticClass:"el-icon-arrow-up",style:({ color: '#a9b9dc', fontSize: '12px' })})])],1)]):_c('div',{staticClass:"textInfo"},[_vm._v(_vm._s(o.summary))])])]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(o.publishDate))])])}),0)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }