<template>
  <router-view v-if="$route.name == '政策报道内容页'" />
  <PageContainer v-else breadcrumb>
    <VlistTitle big-ttitle="政策报道" small-tip="会员专享精华内容推荐" :bgurl="bgurl" />
    <ListFiltering :list="belongAreasData" @handleChange="handleChange" @search="search" />
    <Vlist
      v-if="pageShow"
      :list="policyReportData"
      :page-data="policyReportPage"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @toHref="toHref"
    />
  </PageContainer>
</template>
<script>
import VlistTitle from '@/components/listTitle.vue'
import { policyReport } from '@/api/policy.js'
import ListFiltering from '@/components/ListFiltering.vue'
import { selectDictDataByType } from '@/api/dict.js'
import Vlist from '@/components/list.vue'
import { getUserInfo, logRecords ,openSy} from '@/utils/auth'
import { buryPoint } from '@/api/common'

export default {
  components: {
    VlistTitle,
    ListFiltering,
    Vlist
  },
  data() {
    return {
      bgurl: require('@/assets/img/bg-1.jpg'),
      policyReportData: [],
      policyReportPage: {
        pages: {
          page: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      belongAreasData: [],
      belongAreas: [],
      title: '',
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '政策',
        userId: ''
      },
      pageShow: true
    }
  },
  created() {
    this.policyReport()
    this.selectDictDataByType()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    policyReport() {
      if (!getUserInfo() && this.policyReportPage.pages.page > 1) {
        this.showLogin()
      } else {
        const form = Object.assign({}, this.policyReportPage.pages, {
          title: this.title,
          belongAreas: this.belongAreas
        })
        form.belongAreas = form.belongAreas.join(',')
        policyReport(form).then((res) => {
          if (res.ok) {
            this.policyReportData = res.data.list
            this.policyReportPage.total = res.data.count
          }
        })
      }
    },
    handleSizeChange(val) {
      this.policyReportPage.pages.pageSize = val
      this.policyReport(this.policyReportPage.pages)
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.policyReportPage.pages.page = val
        this.policyReport(this.policyReportPage.pages)
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(item) {
      const newUrl = this.$router.resolve('/policy/dynamic/contentReport/' + item.id)

      if (getUserInfo()) {
        logRecords('政策', '政策报道', '1', 'tt_policy_report', item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('政策')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    // 数据字典
    selectDictDataByType() {
      selectDictDataByType({ dictType: 'mng_zc_policy_type' }).then(
        (res) => {
          if (res.ok) {
            this.belongAreasData = res.data
          }
        }
      )
    },
    // 切换
    handleChange(e) {
      this.belongAreas = e
      this.policyReport()
    },
    search(e) {
      this.policyReportPage.pages.page = 1
      this.title = e
      logRecords('政策', '政策报道', '4', 'tt_policy_report', '', '', '', e)
      this.policyReport()
    }
  }
}
</script>
<style lang="scss" scoped>
.list-box {
  margin-top: 16px;

  li {
    background: #fff;
    padding: 24px;
    border-bottom: 1px solid #f2f2f2;

    &:last-child {
      border-bottom: none;
    }

    .list-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list-l {
        padding-right: 20px;
        flex: 1;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #141414;
      }

      .list-r {
        font-size: 16px;
        line-height: 18px;
        color: #828282;
      }
    }
  }
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
