<template>
  <router-view v-if="$route.name == '前沿技术详情'"/>
  <PageContainer v-else>
    <div class="sticky-container search-top" :class="{'h':hflag}">
      <div class="search-input-box">
        <div>
          检索方式：
          <el-radio v-model="content" label="title">按标题</el-radio>
          <el-radio v-model="content" label="htmlContent">按内容</el-radio>
        </div>
        <div class="search-btn-box">
          <el-input style="width: 650px" v-model="values"/>
          <div class="search-btn" @click="search">检索</div>
        </div>
      </div>
    </div>
    <el-tabs v-model="form.contentCategory" type="card" tab-position="left" @tab-click="tabClick"
             :before-leave="leaveTab"
             style="float: left; margin-top: 140px ;margin-left: -42px;display: flex;position: fixed;z-index: 1;">
      <el-tab-pane label="原创" name="yc" :key="'yc'"></el-tab-pane>
      <el-tab-pane label="全部" name=" " :key="'qb'"></el-tab-pane>
    </el-tabs>

    <div v-if="hflag" v-loading="loading" class="search-bottom">
      <div class="box_box_title" v-for="item in list" :key="item.id"
           @click="toHref('/cuttingEdgeTechnology/details/'+item.id,item)">
        <div style="display: flex">
          <div class="text-center" style="width: 267px; height: 150px">
            <img style="width: 267px; height: 150px" :src="item.imgUrl ? item.imgUrl : defaultUrl" alt=""/>
          </div>
          <div class="flex_col" flex="1">
            <div>
              <div flex="c" style="margin: 10px 0">
          <span style="
                  display: inline-block;
                  color: #0d57bc;
                  font-size: 14px;
                  line-height: 16px;
                  padding: 4px;
                  background: rgba(13, 87, 188, 0.08);
                  margin-right: 4px;
                " v-if="item.contentCategory == 'yc'">原创</span>
                <p class="" v-html="item.title"></p>
              </div>
              <div style="font-size: 14px; color: #828282">
                {{ item.summary }}
                <!--                    {{ item.introduction }}-->
              </div>
            </div>
            <div style="font-size: 14px; color: #828282">
              {{ item.publishTime }}
              <!--                  {{ item.publishDate }}-->
            </div>
          </div>
        </div>
        <div class="check" @click.stop="checkFun(item)" :class="{ ac: item.check }" v-if="item.fileInfo">
          <i class="fa fa-check"></i>
        </div>
      </div>
      <div class="pages">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="pageData.pageNo" :page-sizes="[10, 20, 30, 40]"
                       :page-size="pageData.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                       :total="pageData.total">
        </el-pagination>
      </div>
    </div>

    <template v-has-role="'freeusers'" slot="right">
      <div class="file-box">
        <div class="file-title">批量下载队列</div>
        <div class="file-content">
          <div class="file-item" v-for="item in filterFile(checkData)" :key="item.fileName+item.id">
            {{ item.fileName }}
          </div>
          <div class="file-btn" @click="downAll" v-if="checkData.length>0 && show && !showP ">一键下载</div>
          <div class="file-btn" @click="down" v-else>一键下载</div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
import {queryByPage} from "@/api/cuttingEdgeTechnology.js";
import fileMixin from "@/mixin/file.js";
import {getUserInfo, logRecords, openSy} from "@/utils/auth";

import {searchData} from "@/api/FullTextRetrieval.js";

export default {
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      content: "title",
      list: [],
      values: "",
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      form: {
        contentCategory: "yc",
      },
      checkData: [],
      hflag: true,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "前沿技术",
        userId: ""
      },
      show: false,
      showP: false,
      loading: false,
    };
  },
  created() {
    let title = this.$route.query.title;
    this.values = title;
    this.queryByPage();
    if (getUserInfo()) {
      this.show = true;
      if (getUserInfo().roleKey == "trialrole") {
        this.showP = true;
      }else{
        let menuName = getUserInfo().menuName;
        if (!menuName.includes('前沿技术')) {
          this.showP = true;
        }
      }
    }
  },
  methods: {
    leaveTab() {
      if (!getUserInfo()) {
        return false;
      }
    },
    tabClick(tab) {
      if (getUserInfo()) {
        this.form.contentCategory = tab.name;
        this.queryByPage();
      } else {
        // this.form.contentCategory = "yc"
        this.showLogin();
      }

    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(url,item) {
      this.formLog.eventType = "1";
      let newUrl = this.$router.resolve(url);
      if (getUserInfo()) {

        logRecords('前沿技术','技术报告','1','tt_technology',item.id,'',item.title,'')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('前沿技术')) {
            this.sqsyLogin();

            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },

    down() {
      this.formLog.eventType = "2";
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('前沿技术')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            if (getUserInfo().roleKey == "trialrole") {
              openSy();
              this.insertBuryPoint();
            }
          }
        }
      } else {
        this.showLogin();
      }
    },
    queryByPage() {let param = {
      // 搜索关键字
      keywords: this.values,
      // 页码
      pageNum: this.pageData.pages.pageNo,
      // 每页条数
      pageSize: this.pageData.pages.pageSize,
      // 标题0 全文1
      title: this.content === "title" ? "0" : "1",
      sortFile: "_score",
      categoryTys: ["前沿技术"],
      // 全部类型0 指定类型1
      all: 1,
      contentCategory: this.form.contentCategory,
      // contentCategory: null,
      // 指定类型
      typeParams: ["新能源", "智能网联", "节能减排", "安全", "其他"]
    };
      this.loading = true;
      searchData(param).then(res => {

        let list = res.data.data;
        for (let i = 0; i < list.length; i++) {
          list[i].check = false;
          if (this.checkData.length > 0) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (this.checkData[j].id == list[i].id) {
                list[i].check = true;
              }
            }
          }
        }
        this.list = list;
        this.pageData.total = res.data.total;
        this.loading = false;
      });
    },
    // queryByPage() {
    //   let form = Object.assign({}, this.pageData.pages);
    //   form[this.content] = this.values;
    //   if(this.form.contentCategory=='yc'){
    //     form["contentCategory"] = this.form.contentCategory;
    //
    //   }
    //   this.loading = true;
    //   queryByPage(form).then((res) => {
    //     let list = res.data.list;
    //     for (let i = 0; i < list.length; i++) {
    //       list[i].check = false;
    //       if (this.checkData.length > 0) {
    //         for (let j = 0; j < this.checkData.length; j++) {
    //           if (this.checkData[j].id == list[i].id) {
    //             list[i].check = true;
    //           }
    //         }
    //       }
    //     }
    //     this.list = list;
    //     this.pageData.total = res.data.count;
    //     this.loading = false;
    //   });
    // },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.queryByPage();
    },
    handleCurrentChange(val) {
      this.pageData.pages.pageNo = val;
      this.queryByPage();
    },

    search() {
      // this.hflag = true;
      logRecords('前沿技术','技术报告','4','tt_technology','','','',this.values)

      this.queryByPage();
    },
  },
  mixins: [fileMixin],
};
</script>
<style lang="scss" scoped>
.search-top {
  display: flex;
  width: 100%;
  background: #fff;
  z-index: 1;

  .search-input-box {
    margin: auto;
  }

  &.h {
    height: 130px;
  }
}

.search-btn-box {
  display: flex;
  margin-top: 9px;

  /deep/ .el-input__inner {
    height: 44px;
    line-height: 44px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .search-btn {
    width: 109px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #0d57bc;
    border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }
}

.search-bottom {
  position: relative;
  z-index: 1;
}

.box_box_title {
  position: relative;
  margin-top: 10px;
  background: #ffffff;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #000;
    font-size: 16px;
  }

  .flex_col {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.file-box {
  background: #fff;
  border-radius: 4px;
}

.file-title {
  padding: 15px 0 15px 16px;
  font-size: 16px;
  color: #0d57bc;
  border-bottom: 1px solid #f2f2f2;
}

.file-content {
  padding: 24px 16px;

  .file-item {
    line-height: 21px;
    color: #333;
    margin-bottom: 24px;
    word-wrap: break-word;
    word-break: normal;
  }
}

.file-btn {
  width: 168px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #0d57bc;
  border: 1px solid #0d57bc;
  margin: 0 auto;
  cursor: pointer;
}

.check {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(21, 67, 133, 0.2);
  width: 28px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  color: #fff;

  .fa {
    margin-right: 0;
  }

  &.ac {
    background: #0d57bc;
  }
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left.is-active {
  background-color: #0d57bc;
  color: #ffffff;
}

::v-deep .el-tabs--left.el-tabs--card .el-tabs__item.is-left {
  height: 57px;
  width: 41px;
  padding-top: 15px;
  writing-mode: vertical-lr;
  padding-left: 0;
  padding-right: 0;
}
</style>
