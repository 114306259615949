<template>
    <div class="index-banner-hot" v-if="items.length > 0">
        <div class="title-tag">
            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/newIssue.png" alt="">
                </span>{{ title }}</h3>
          <div class="more" @click="moreClick">查看更多 <i class="el-icon-arrow-right" /></div>
        </div>
        <ul class="index-new-timeLine" v-if="type == 'list'">
            <li v-for="(item) in items" :key="item.id" @click="clickFun(item)">
                <span class="ycDiv" v-if="item.contentCategory == 'yc'">原创</span>
                <TextOverTooltip :font-size="20" v-bind:title="htmlRestore(item.title)" />
                <div class="timeLine">{{ fmtDate("mm-dd", item.updateTime) }}</div>
            </li>
        </ul>

        <ul class="container-r-content" v-if="type == 'file'">
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
            <li>
                <div class="inner">国家引导，地方主导-2021年汽车产业政策展望</div>
            </li>
        </ul>
    </div>
</template>
<script>
import TextOverTooltip from "@/components/toolTip.vue";
export default {
    name: "VcontentItem",
    components: {
        TextOverTooltip,
    },
    props: {
        list: {
            type: Array,
            default: function () {
                return [];
            },
        },
        title: "",
        type: "",
    },
    data() {
        return {
            data: {},
        };
    },
    computed: {
        items() {
            return this.list || [];
        },
    },
    created() {
        this.$store.dispatch("common/SET_ListIndex");
        // if (this.items.length <= 0) {
        //     this.$store.dispatch("common/SET_ListFlagIndex");
        //        this.$store.dispatch("common/SET_ListIndex");
        // this.$store.dispatch("common/SET_ListFlag");
        // }
    },
    watch: {
        items(v) {
            if (v.length <= 0) {
                this.$store.dispatch("common/SET_ListFlagIndex");
            }
            this.$store.dispatch("common/SET_ListFlag");
        },
    },
    methods: {
        clickFun(item) {
            this.$emit("click", item);
        },
    },
};
</script>
<style lang="scss" scoped>
.index-banner-hot {
    margin-top: 10px;
    margin-bottom: 18px;
    background: #fff;

    .title-tag {
        background: linear-gradient(#f2f5fd, #fff);
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 22px 20px 10px;

        .title-tag-text {
            display: inline-flex;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 1;

            span {
                padding-right: 10px;
            }
        }
      .more {
        margin-right: -4px;
        color: #9ab7f4;
      }
    }

    .index-new-timeLine {
        padding-left: 20px;
        padding-bottom: 10px;
        overflow: hidden;

        li {
            cursor: pointer;
            padding-bottom: 14px;
            display: flex;
            // white-space: nowrap;
            justify-content: space-between;
        }

        .ycDiv {
            display: inline-block;
            color: #0d57bc;
            font-size: 12px;
            line-height: 16px;
            padding: 2px;
            background: rgba(13, 87, 188, 0.08);
            margin-right: 4px;
        }

        .text-overflow {
            flex: 1;
            cursor: pointer;
            font-size: 14px;
            line-height: 20px;
            color: #000;
            display: inline-block;
            word-break: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .timeLine {
            color: #bdbdbd;
            font-size: 14px;
            padding-left: 20px;
            padding-right: 10px;
            text-align: right;
        }
    }
}
</style>
