<template>
    <div class="box">
       <titlemore title="企业分析报告"  />
       <div class="box_box">
        <searchList  @toHref="toHref" showbutton :tableData="tableData" />
       </div>
    </div>
</template>
<script>
/**
 * @description: 企业情报动态页面
 * @param {*}
 * @return {*}
 */
import { getUserInfo, logRecords } from "@/utils/auth.js";
import { AnalysisQueryByPage } from "@/api/enterpriseAnalysis.js";
import { fmtDate } from "@/utils/date.js";
import { buryPoint } from "@/api/common";
import actionTabs from "../components/actionTabs.vue"
import titlemore from './components/titlemore.vue'
import searchList from '../analysis/searchlist.vue'
export default {
    components: {
        actionTabs,
        titlemore,
        searchList,
    },
    data() {
        return {
            defaultUrl: require("@/assets/img/default-img.png"),
            tableData: [],
            show: false,
            title: "",
            pages: {
                pageNo: 1,
                pageSize: 3,
            },
            formLog: {
                eventName: "开通卡片点击",
                eventType: "1",
                columnName: "企业情报",
                userId: ""
            },
        };
    },
    computed: {
        isInDownId() {
            return (id) => {
                return this.downId.includes(id);
            };
        },
    },
    created() {
        if (getUserInfo()) {
            this.show = true;

        } else {
            this.show = false;
        }
        this.AnalysisQueryByPage(this.pages);
    },
    methods: {
        insertBuryPoint() {
            this.formLog.userId = getUserInfo().userId;
            buryPoint(this.formLog);
        },
        //显示申请试用弹窗
        sqsyLogin() {
            this.$Vlogin3({ flag: true });
        },
        toHref(item) {
            let newUrl = this.$router.resolve("/workInformation/analysis/analysisContent/" + item.id);
            if (getUserInfo()) {
                logRecords('企业情报', '企业分析报告', '1', 'tt_enterprise_analysis', item.id, '', item.title, '')
                if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

                    let menuName = getUserInfo().menuName;
                    if (!menuName.includes('企业情报')) {
                        this.sqsyLogin();
                        this.insertBuryPoint();
                    } else {
                        window.open(newUrl.href, "_blank");

                    }
                } else {
                    window.open(newUrl.href, "_blank");
                }
            } else {
                this.showLogin();
            }
        },
        //显示登录弹窗
        showLogin() {
            this.$Vlogin2({ flag: true });
        },
        //企业分析
        AnalysisQueryByPage(parmas) {
            AnalysisQueryByPage(parmas).then((res) => {
                if (res.ok) {
                    this.tableData = res.data.list;
                    this.tableData.forEach(item => {
                        item.publishDate = fmtDate(
                            "YYYY-mm-dd",
                            item.publishDate
                        );
                    })
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.box {
    background: #ffffff;
    padding: 25px 28px 0 23px;
    .box_box {
        padding-top: 15px;
    }
    .box_box_title {
        padding-bottom: 20px;
        img {
            border-radius: 4px;
        }

        p {
            font-weight: 600;
            color: #000;
            font-size: 16px;
        }

        .flex_col {
            height: 150px;
            padding-left: 28px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .box_yc {
                display: inline-flex;
                margin-top: 2px;
                margin-right: 8px;
                padding: 3px 4px;
                color: #fff;
                font-size: 12px;
                line-height: 1;
                white-space: nowrap;
                background: #ff6e4b;
                background-image: linear-gradient(136deg, rgb(255, 142, 93) 0%, rgba(255, 47, 47, 0.99608) 100%);
                border-radius: 2px;
            }

            .box_content {
                font-size: 14px;
                color: #828282;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }

            .box_time {
                font-size: 12px;
                color: rgb(187, 187, 187);
            }

        }
    }
}

</style>