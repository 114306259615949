import Vue from "vue";
import { htmlRestore, htmlString } from "@/utils/htmlRestore.js";
Vue.mixin({
    computed: {
      rootURL() {
        const url = window.location.origin;
        return this.$router.mode === 'hash' ? `${url}/#` : url;
      },
    },
    methods: {
        htmlRestore(str) {
            return str ? htmlRestore(str) : str
        },
        htmlString(str) {
            return str ? htmlString(str) : str
        },
        //过滤时间
        fmtDate(fmt, datestr) {
            if (datestr) {
                let ret;
                let date = new Date(datestr)
                const opt = {
                    "Y+": date.getFullYear().toString(),        // 年
                    "m+": (date.getMonth() + 1).toString(),     // 月
                    "d+": date.getDate().toString(),            // 日
                    "H+": date.getHours().toString(),           // 时
                    "M+": date.getMinutes().toString(),         // 分
                    "S+": date.getSeconds().toString()          // 秒
                    // 有其他格式化字符需求可以继续添加，必须转化成字符串
                };
                for (let k in opt) {
                    ret = new RegExp("(" + k + ")").exec(fmt);
                    if (ret) {
                        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
                    };
                };
                return fmt;
            } else {
                return ""
            }
        }
    }
})
