<template>
    <div class="top_bnana" @click="showLogin5()">
        <div class="topBanna box max-container">
            <div class="text" @click.stop="handleClose()">关闭</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        showLogin5() {
            this.$Vlogin5({ flag: true })
        },
        handleClose() {
            this.$emit('click-close');
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/newDataVariables.scss";

.topBanna {
  position: relative;
  width: 100%;
  height: $top-banna-height;
  .title {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    padding-top: 10px;
    padding-right: 10px;
  }
}

.top_bnana {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: $banna-zindex;
  background: #fff;
  background: url('~@/assets/images/qyqbsy.jpg') no-repeat center center;
  background-size: cover;
  cursor: pointer;
}
</style>
