<template>
  <router-view
      v-if="$route.name == '公告公示详情' || $route.name == '公告公示选装'"
  />
  <PageContainer v-else>
    <!--      <VnotLogin-->
    <!--        v-if="!show"-->
    <!--        title="公告"-->
    <!--        text="公告栏目围绕公告本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。"-->
    <!--        tableTitle="最新公告"-->
    <!--        :showTips="showTips"-->
    <!--      />-->
    <template>
      <div class="search-form">
        <el-form class="g-form" :model="form" ref="form" label-width="96px">
          <UnfurlAndFold title="公示信息">
            <el-row>
              <el-col :span="24">
                <el-form-item prop="pc" label="公示批次：">
                  <el-radio-group v-model="form.pc" @change="pcRadio">
                    <el-radio label="">不限</el-radio>
                    <el-radio
                        :label="item"
                        v-for="item in GGPublicPc"
                        :key="item"
                    ></el-radio>
                  </el-radio-group>
                  <el-input
                      v-model="pcinput"
                      @input="pcFun"
                      size="small"
                      placeholder="输入批次"
                      style="width: 90px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="整车信息">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="qymc" label="企业名称：">
                  <el-input
                      v-model="form.qymc"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="cpmc" label="产品名称：">
                  <el-input
                      v-model="form.cpmc"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="cpxh" label="产品型号：">
                  <el-input
                      v-model="form.cpxh"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <el-form-item prop="rlzl" label="燃料种类：">
                  <el-input
                      v-model="form.rlzl"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item prop="cllx" label="车辆类型：">
                  <div style="display: flex">
                    <el-checkbox
                        label="不限"
                        v-model="cllx"
                        @change="cllxAll"
                    ></el-checkbox>
                    <el-checkbox-group
                        v-model="form.cllx"
                        @change="cllxcheck"
                    >
                      <el-checkbox label="1">载货汽车</el-checkbox>
                      <el-checkbox label="2">越野汽车</el-checkbox>
                      <el-checkbox label="3">自卸汽车</el-checkbox>
                      <el-checkbox label="4">牵引汽车</el-checkbox>
                      <el-checkbox label="5">专用汽车</el-checkbox>
                      <el-checkbox label="6">客车</el-checkbox>
                      <el-checkbox label="7">轿车</el-checkbox>
                      <el-checkbox label="9"
                      >半挂车及专用半挂汽车
                      </el-checkbox
                      >
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="底盘信息">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="mdpqy" label="底盘企业：">
                  <el-input
                      v-model="form.mdpqy"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="mdpid" label="底盘ID：">
                  <el-input
                      v-model="form.mdpid"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="mdpxh" label="底盘型号：">
                  <el-input
                      v-model="form.mdpxh"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="发动机信息">
            <el-row>
              <el-col :span="8">
                <el-form-item prop="mfqy" label="生产企业：">
                  <el-input
                      v-model="form.mfqy"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="mfdj" label="型号：">
                  <el-input
                      v-model="form.mfdj"
                      placeholder="请输入内容"
                      size="small"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>
          <UnfurlAndFold title="参数信息">
            <el-row>
              <el-col :span="8" class="small-input">
                <el-form-item label="总质量：">
                  <div flex="c">
                    <div style="text-align: center; flex: 1">
                      <el-row type="flex" align="middle">
                        <el-col :span="5"
                        >
                          <el-input
                              v-model="form.zzlStart"
                              size="small"
                              oninput="value=value.replace(/[^0-9.]/g,'')"
                          ></el-input>
                        </el-col>
                        <el-col :span="2">-</el-col>
                        <el-col :span="5"
                        >
                          <el-input
                              v-model="form.zzlEnd"
                              size="small"
                              oninput="value=value.replace(/[^0-9.]/g,'')"
                          ></el-input
                          >
                        </el-col>
                        <el-col :span="2">kg</el-col>
                      </el-row>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="small-input">
                <el-form-item label="额定载质量：">
                  <div flex="c">
                    <div style="text-align: center; flex: 1">
                      <el-row type="flex" align="middle">
                        <el-col :span="5"
                        >
                          <el-input
                              v-model="form.edzlStart"
                              size="small"
                              oninput="value=value.replace(/[^0-9.]/g,'')"
                          ></el-input
                          >
                        </el-col>
                        <el-col :span="2">-</el-col>
                        <el-col :span="5"
                        >
                          <el-input
                              v-model="form.edzlEnd"
                              size="small"
                              oninput="value=value.replace(/[^0-9.]/g,'')"
                          ></el-input
                          >
                        </el-col>
                        <el-col :span="2">kg</el-col>
                      </el-row>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" class="small-input">
                <el-form-item label="整备质量：">
                  <div flex="c">
                    <div style="text-align: center; flex: 1">
                      <el-row type="flex" align="middle">
                        <el-col :span="5"
                        >
                          <el-input
                              v-model="form.zbzlStart"
                              size="small"
                              oninput="value=value.replace(/[^0-9.]/g,'')"
                          ></el-input
                          >
                        </el-col>
                        <el-col :span="2">-</el-col>
                        <el-col :span="5"
                        >
                          <el-input
                              v-model="form.zbzlEnd"
                              size="small"
                              oninput="value=value.replace(/[^0-9.]/g,'')"
                          ></el-input
                          >
                        </el-col>
                        <el-col :span="2">kg</el-col>
                      </el-row>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </UnfurlAndFold>

          <div class="text-center" style="margin-top: 20px">
            <el-button class="reset-button" @click="reset">重置</el-button>
            <el-button class="search-button" @click="searchForm"
            >查询
            </el-button
            >
          </div>
        </el-form>
      </div>
      <div class="search-form">
        <div flex="sb">
          <span class="table_title"></span>
          <el-button type="primary" plain @click="exportc" v-has-role="'freeusers'"
          >导出（{{ exportData.length }}）
          </el-button
          >
        </div>
        <el-table :data="tableData" style="width: 100%" ref="newTable"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }" @select="selectionChange"
                  @selection-change="selectionChange" v-loading="loading" stripe @sort-change="tableSort">
          <el-table-column type="selection" width="55"/>

          <el-table-column
              sortable="custom"
              prop="qymc"
              label="企业名称"
              min-width="300"
          />
          <el-table-column prop="cpmc" label="产品名称" min-width="200"/>
          <!-- <el-table-column sortable="custom" prop="cph" label="产品号" /> -->
          <el-table-column sortable="custom" prop="cpxh" label="产品型号" min-width="200"/>
          <el-table-column sortable="custom" prop="pc" label="批次" min-width="100"/>
          <!-- <el-table-column sortable="custom" prop="sxrq" label="生效日期" /> -->
<!--            <el-table-column prop="bgkz" label="产品状态" width="100">-->
<!--              <template slot-scope="scope">-->
<!--                {{ ggSate(scope.row.bgkz,scope.row.bgkz) }}-->
<!--              </template>-->
<!--            </el-table-column>-->
          <el-table-column label="操作" align="center" min-width="100">
            <template slot-scope="scope">
              <div class="table-text-btn">
                <!-- <el-popover placement="left" width="300" trigger="hover"> -->
                <!-- <img
                  :src="scope.row.xzzp1 || defaultImg"
                  alt=""
                  width="100%"
                /> -->
                <el-button
                    type="text"

                    @click="
                      viewDetail(
                        '/announcement/search/details/' , scope.row.id
                      )
                    "
                >查看
                </el-button
                >
                <!-- </el-popover> -->
                <!-- <span class="line">|</span>
                <el-button
                  type="text"
                  @click="
                    $router.push(
                      '/announcement/search/optional/' + scope.row.id
                    )
                  "
                  >选装</el-button
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination
            v-if="pageShow"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageData.pages.page"
              :page-sizes="[10, 20, 30, 40,100]"
              :page-size="pageData.pages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 公告公示查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import UnfurlAndFold from "@/components/UnfurlAndFold.vue";
import {getUserInfo, logRecords, openSy} from "@/utils/auth.js";
import {
  getAllGGPublicPc,
  pageGGPublic,
  exportGGPublic,
} from "@/api/announcement.js";
import {download} from "@/utils/download.js";
import ggcpzt from "@/mixin/ggcpzt.js";
import {buryPoint} from "@/api/common";

export default {
  components: {
    // VnotLogin,
    UnfurlAndFold,
  },
  data() {
    return {
      defaultImg: require("@/assets/img/nonecar.jpg"),
      show: false,
      showTips: [
        {
          title: "公告动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "公告库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "公告梳理",
          text: "专家梳理整合，真正读懂公告脉络",
        },
        {
          title: "公告大数据",
          text: "用数据深度理解公告结构和趋势",
        },
      ],
      form: {
        cpmc: "", //产品名称
        cpxh: "", //产品型号
        edzlEnd: "", //额定载质量结束
        edzlStart: "", //额定载质量开始
        mdpid: "", //底盘ID
        mdpqy: "", //底盘企业
        mdpxh: "", //底盘型号
        mfdj: "", //发动机型号
        mfqy: "", //发动机生产企业
        pc: "", //批次
        qymc: "", //企业名称
        rlzl: "", //燃料种类
        zbzlEnd: "", //整备载质量结束
        zbzlStart: "", //整备载质量开始
        zzlEnd: "", //总质量结束
        zzlStart: "", //总质量开始
        cllx: [],
        orderType: ""
      },
      checkList: [],
      tableData: [],
      contrastData: [],
      exportData: [],
      GGPublicPc: [],
      pcinput: "",
      cllx: true,
      pageData: {
        pages: {
          page: 1,
          pageSize: 100,
        },
        total: 0,
      },
      loading: false,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "2",
        columnName: "公告",
        userId: ""
      },
      pageShow: true
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    if (this.$store.state.notice.pc > 0) {
      this.form.pc = this.pcinput = this.$store.state.notice.pc.toString()
    }
    this.getAllGGPublicPc();
    this.pageData.pages.page = 1;
    this.init();
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    viewDetail(path, id) {
      let newUrl = this.$router.resolve({
        path: path + id
      });
      if (getUserInfo()) {

        logRecords('公告','公告公示','3','gg_publicity','','','','','查看')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
      // this.$router.push(
      //     '/announcement/announcementSearch/VehicleDetails/' + id
      // )
    },
    //获取批次
    getAllGGPublicPc() {
      getAllGGPublicPc().then((res) => {
        if (res.ok) {
          this.GGPublicPc = res.data;
        }
      });
    },
    //批次输入
    pcFun(e) {
      this.form.pc = e;
    },
    //批次选择
    pcRadio() {
      this.pcinput = "";
    },
    //车辆型号
    cllxAll() {
      this.cllx = true;
      this.form.cllx = [];
    },
    cllxcheck(e) {
      if (e.length <= 0) {
        this.cllx = true;
      } else {
        this.cllx = false;
      }
    },

    //查询
    init() {
      let form = Object.assign({}, this.form, this.pageData.pages);
      form.cllx = form.cllx.join(",");
      // delete form.car;
      this.loading = true;
      pageGGPublic(form).then((res) => {
        this.tableData = res.data.list;
        this.pageData.total = res.data.count;
        this.loading = false;
      });
    },
    //分页
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.page = val
        this.init()
      }
    },
    //查询
    searchForm() {
      if (getUserInfo()) {

        logRecords('公告','公告公示','3','gg_publicity','','','','','查询')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.pageData.pages.page = 1;
            this.init();

          }
        } else {
          this.pageData.pages.page = 1;
          this.init();
        }
      } else {
        this.showLogin();
      }


    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    reset() {
      this.$refs.form.resetFields();
      this.pcinput = "";
      this.cllx = true;
      this.form.pc = "";
      this.form.edzlEnd = ""; //额定载质量结束
      this.form.edzlStart = ""; //额定载质量开始
      this.form.zbzlEnd = ""; //整备载质量结束
      this.form.zbzlStart = ""; //整备载质量开始
      this.form.zzlEnd = ""; //总质量结束
      this.form.zzlStart = ""; //总质量开始
      this.form.orderType = ""
      this.pageData.pages.page = 1;
      this.$refs.newTable.clearSort()
      this.init();
    },
    //选择
    selectionChange(selection) {
      this.contrastData = selection;
      this.exportData = selection;
    },

    exportc() {
      if (this.exportData.length <= 0) {
        this.$message({
          showClose: true,
          message: "请选择导出数据",
          type: "error",
        });
        return false;
      }
      if (this.exportData.length > 100) {
        this.$message({
          showClose: true,
          message: "最多选择100条数据",
          type: "error",
        });
        return false;
      }

      let ids = this.exportData.map((item) => {
        return item.id;
      });
      if (getUserInfo()) {

        logRecords('公告','公告公示','3','gg_publicity','','','','','导出')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('公告')) {
            this.sqsyLogin();

            this.insertBuryPoint();
          } else {
            if(getUserInfo().roleKey == "trialrole"){
              openSy();
              this.insertBuryPoint();
            }else {
              exportGGPublic(ids).then((res) => {
                download(res, "公告公示导出", "xls");
              });
            }
          }
        } else {
          exportGGPublic(ids).then((res) => {
            download(res, "公告公示导出", "xls");
          });
        }
      } else {
        this.showLogin();
      }

    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {
        return column.prop
      } else if (column.order == 'descending') {
        return column.prop + ' DESC'
      } else {
        return ''
      }
    }
  },
  mixins: [ggcpzt],
};
</script>
<style lang="scss" scoped>
.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}

/deep/ {
  .g-form {
    .el-radio,
    .el-checkbox {
      margin-right: 8px;
    }

    .el-input {
      width: 200px;
    }

    .small-input {
      .el-input {
        width: 100%;
      }
    }

    .el-form-item {
      margin-bottom: 0;
    }
  }
}

.table-text-btn {
  .el-button--text {
    padding: 0;
  }

  .line {
    padding: 0 5px;
  }

  .el-button--text,
  .line {
    color: #0D57BC;
  }
}
</style>

<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
