import { render, staticRenderFns } from "./ThirdWheelDetails copy.vue?vue&type=template&id=3a8d6fcb&scoped=true&"
import script from "./ThirdWheelDetails copy.vue?vue&type=script&lang=js&"
export * from "./ThirdWheelDetails copy.vue?vue&type=script&lang=js&"
import style0 from "./ThirdWheelDetails copy.vue?vue&type=style&index=0&id=3a8d6fcb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8d6fcb",
  null
  
)

export default component.exports