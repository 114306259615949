<template>
  <PageContainer>
    <!--        <div style="margin:25px 0 20px 33px;">-->
    <!--            <img src="../../../assets/img/logoSvg.svg" />-->
    <!--        </div>-->
    <div ref="content" class="content" @mousedown="contentDown" @mouseup="contentUp" @mousemove="contentMove">
      <table class="table" border="1">
        <tr>
          <td align="right" style="width: 200px; min-width: 200px" />
          <td v-for="(item, index) in tableData.ybzp" :key="item + index" style="position: relative;" :style="$route.params.hitoryPc?'':' width: 200px; min-width: 200px'">
            <img :src="item" width="200">

            <a v-if="tableData.pc.length > 1" class="del_btn" @click="del(index)"><i class="fa fa-times" /></a>
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>产品型号名称：</strong>
          </td>
          <td v-for="(item, index) in tableData.cpxhmc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>产品商标：</strong>
          </td>
          <td v-for="(item, index) in tableData.cpsb" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>企业名称：</strong>
          </td>
          <td v-for="(item, index) in tableData.qymc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>批次：</strong>
          </td>
          <td v-for="(item, index) in tableData.pc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>产品状态：</strong>
          </td>
          <td v-for="(item, index) in tableData.bgkz" :key="item + index">
            {{ ggSate(item,tableData.cxbj[index]) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>生产地址：</strong>
          </td>

          <td v-for="(item, index) in tableData.scdz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>注册地址：</strong>
          </td>
          <td v-for="(item, index) in tableData.zhcAdd" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>电话号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.telephone" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>传真号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.fax" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr class="div-info">
          <td align="right">
            <strong>邮政编码：</strong>
          </td>

          <td v-for="(item, index) in tableData.postcode" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>外形尺寸（mm）<br>长*宽*高 ：</strong>
          </td>

          <td v-for="(item, index) in tableData.ckg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>燃料种类：</strong>
          </td>

          <td v-for="(item, index) in tableData.rlzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>油耗：</strong>
          </td>
          <td v-for="(item, index) in tableData.yh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>排放依据标准：</strong>
          </td>

          <td v-for="(item, index) in tableData.yjbz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>排量（ml）/功率（kw）：</strong>
          </td>

          <td v-for="(item, index) in tableData.mfplgl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>转向型式：</strong>
          </td>

          <td v-for="(item, index) in tableData.zxxs" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>货厢栏板内尺寸（mm） <br>
              长*宽*高：</strong>
          </td>
          <td v-for="(item, index) in tableData.hxckg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轴数：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhsh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>轴距（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>钢板弹簧片数：</strong>
          </td>

          <td v-for="(item, index) in tableData.thps" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>轮胎规格：</strong>
          </td>

          <td v-for="(item, index) in tableData.ltgg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轮胎数：</strong>
          </td>

          <td v-for="(item, index) in tableData.lts" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>轮距前/后（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qhlj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>总质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>额定载质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.ghedzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>整备质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zbzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>准拖挂车总质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.gczl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>载质量利用系数：</strong>
          </td>

          <td v-for="(item, index) in tableData.zzhl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>半挂车鞍座最大允许承载质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.bgaz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>额定载客（含驾驶员）（人）：</strong>
          </td>

          <td v-for="(item, index) in tableData.edzk" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>驾驶室准乘人数（人）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qpck" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>接近角/离去角（°）：</strong>
          </td>
          <td v-for="(item, index) in tableData.jjlqj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>前悬/后悬（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qxhx" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>最高车速（km/h）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zgcs" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>轴荷（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>底盘型号、生产企业及类别：</strong>
          </td>

          <td v-for="(item, index) in tableData.mdpid" :key="item + index">
            <template v-if="item.indexOf(null) == -1 && item!=''">
              <a href="#" @click="turnXh(item)">底盘id为{{ item }}，详细参数>>></a>
            </template>
            <template v-else>{{ returNull(tableData.dpqyxh1[index]) }}</template>
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>发动机型号及生产企业： </strong>
          </td>
          <td v-for="(item, index) in tableData.mfdjqy" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>车辆识别代号（vin）：</strong>
          </td>

          <td v-for="(item, index) in tableData.sbdh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr>
          <td align="right">
            <strong>其他：</strong>
          </td>

          <td v-for="(item, index) in tableData.qt" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>操作：</strong>
          </td>

          <td v-for="(item, index) in tableData.id" :key="item + index">
            <template v-if="item != 'null'">
              <el-button type="text" @click="printFun(item)">打印技术参数</el-button>
            </template>

          </td>
        </tr>
      </table>
    </div>
  </PageContainer>
</template>
<script>
import { ggZcCompare, historyCompare, getDpByDpid } from '@/api/announcement.js'
import ggcpzt from '@/mixin/ggcpzt.js'
export default {
  mixins: [ggcpzt],
  data() {
    return {
      tableData: [],
      filterData: {},
      startObj: {
        scrollLeft: 0,
        startX: 0,
        scrollD: 0,
        moveFlag: false
      }
    }
  },
  created() {
    const hitoryPc = this.$route.params.hitoryPc
    const ids = this.$route.params.id
    if (hitoryPc == 'false') {
      this.ggZcCompare({ cpIdArrayString: ids })
    } else {
      this.historyCompare({
        cpxh: hitoryPc,
        pcArrayString: ids
      })
    }
  },
  methods: {
    turnXh(msg) {
      getDpByDpid(`/api/customerNotice/getDpByDpid`, {
        dpid: msg

      }).then((res) => {
        if (res.ok) {
          const newUrl = this.$router.resolve({
            path: '/announcement/announcementSearch/ChassisDetails/' + res.data.id
          })
          window.open(newUrl.href, '_blank')
        }
      })
    },
    // 打印printFun
    printFun(id) {
      window.open('/api/exportZcPdf/ggYzkPdf?cpId=' + id)
    },
    // 车辆比对
    ggZcCompare(parmas) {
      ggZcCompare(parmas).then((res) => {
        if (res.ok) {
          this.tableData = res.data
          // this.filterFun(res.data);
        }
      })
    },
    filterFun(data) {
      data.forEach((item) => {
        for (const key in item) {
          this.filterData[key].push(item[key])
        }
        this.filterData.ckg.push(
          item.chang ||
                        '' + '*' + item.kuan ||
                        '' + '*' + item.gao == null
            ? ''
            : item.gao || ''
        )

        this.filterData.plgl.push(
          item.mfpl || '' + '/' + item.mfgl || ''
        )

        this.filterData.hxlbckg.push(
          item.hxc || '' + '*' + item.hxk || '' + '*' + item.hxg || ''
        )

        this.filterData.ljqh.push(
          item.qlj || '' + '/' + item.hlj || ''
        )

        this.filterData.dpsclb.push(
          item.dpqyxh1 || '' + '/' + item.cplb || ''
        )

        this.filterData.fdjscqy.push(
          item.mfdj || '' + '/' + item.mfqy || ''
        )
      })
    },
    del(index) {
      const filterData = this.tableData
      for (const key in filterData) {
        filterData[key].splice(index, 1)
      }
    },
    // 车辆比对
    historyCompare(parmas) {
      historyCompare(parmas).then((res) => {
        if (res.ok) {
          this.tableData = res.data
          // this.filterFun(res.data);
        }
      })
    },
    // 按下事件
    contentDown(e) {
      this.startObj.scrollLeft = this.$refs.content.scrollLeft
      this.startObj.startX = e.pageY
      this.startObj.moveFlag = true
    },
    // 松开鼠标
    contentUp(e) {
      this.startObj.moveFlag = false
    },
    // 移动鼠标e
    contentMove(e) {
      if (this.startObj.moveFlag) {
        this.startObj.scrollD - e.clientX > 0
          ? this.startObj.scrollLeft++
          : this.startObj.scrollLeft--
        this.startObj.scrollD = e.clientX
        this.$refs.content.scrollLeft = this.startObj.scrollLeft
        e.preventDefault()
      }
    },
    returNull(str) {
      if (str) {
        const newStr = str.replace(/null/g, '-')
        return newStr
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$allcolor: #0d57bc;
.content {
    background: #fff;
}
.table {
    width: 100%;
    border-color: $allcolor;
    word-break: break-all;
    color: #000;
    border: none;
    tr {
        td {
            padding: 5px 10px;
            border-color: $allcolor;
            word-break: break-all;
        }
        &:nth-child(odd) {
            background: #bbc8da;
        }
    }
    .del_btn {
        position: absolute;
        top: 0;
        right: 0;
    }
}
td, th {
  border: 1px solid #DDD;
}
</style>
