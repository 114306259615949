<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" isContentTwo="qyjs" />

    <template slot="right">
      <Recommend title="相关推荐" type="list" :list="list" @click="tohref" />
      <ContentItem
        title="市场动态"
        type="list"
        :list="tableDataRight"
        @click="toMarketDy"
      />
    </template>
  </PageContainer>
</template>
<script>
import { queryById } from "@/api/insight.js";
import { marketDynamic } from "@/api/Index.js";
import { fmtDate } from '@/utils/date'
import Recommend from '@/components/recommends.vue'
import ContentItem from '@/components/contentItem.vue'

export default {
  components: {
    Recommend,
    ContentItem
  },
  data() {
    return {
      data: {},
      list: [],
      tableDataRight: [],
      pages: {
          page: 1,
          pageNo: 1,
          pageSize: 10,
      },
      props: {
        tag: "keywords",
        summary:"introduction",
        publishDate:"updateTime"
      },
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.queryById({ id });
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.queryById({ id });
  },
  methods: {
    queryById(params) {
      queryById(params).then((res) => {
        if (res.ok) {
          this.data = res.data;
          this.list = res.data.recommendEOList;
          this.pageRight()
        }
      });
    },
    pageRight() {
      marketDynamic(this.pages).then((res) => {
        if(res.ok){
          this.tableDataRight = res.data.list.map((item) => {
          item.updateTime = fmtDate("YYYY-mm-dd", item.updateTime);
          return item;
        });
        }
      })
    },
    //跳转
    tohref(item) {
      if (item.tableName == "tt_special") {
        this.$router.push("/thematicAnalysis/details/" + item.id);
      } else {
        this.$router.push("/marketInsight/detail/marketDynamicDetail?id=" + item.id);
      }
    },
    toMarketDy(item) {
      this.$router.push("/marketInsight/detail/marketDynamicDetail?id=" + item.id);
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
