<!--市场动态列表-->
<template>
  <PageContainer>
    <div class="container">
      <div class="top-box">
        <div class="carousel-box">
          <el-carousel
            ref="carousel"
            arrow="always"
            :autoplay="autoplay"
            :interval="3000"
            loop
            @change="carouselChange"
          >
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <div class="carousel-item-info" @click="toHref(item)">
                <img
                  v-if="item.coverUrl"
                  style="width: 100%; height: 100%"
                  :src="item.coverUrl"
                  alt=""
                >
                <div class="carousel-item-info-text">
                  {{ item.title }}
                  <!-- 小鹏汽车与滴滴战略合作 将推出A级智能电动汽车“MONA” -->
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="massage-box">
          <div
            v-for="(item, index) in bannerList"
            :key="index"
            :class="
              `massage-box-item ${
                index === carouselIndex ? 'massage-box-item-hover' : ''
              }`
            "
            @click="toHref(item)"
            @mouseover.stop="massageMouseover(item, index)"
            @mouseout="massageMouseout(item, index)"
          >
            <!-- <div class="massage-box-item-title"> -->
            <!-- <div class="massage-box-item-title-icon"></div> -->
            <!-- <div class="massage-box-item-title-text">
                {{ item.title }}
              </div> -->
            <!-- <div class="massage-box-item-title-time">
                {{ item.updateSynchronizationTime }}
              </div> -->
            <!-- </div> -->
            <div class="massage-box-item-text">
              <!-- {{ item.introduction }} -->
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="box-card-title">推荐资讯</span>
            <div class="btn-box">
              <div class="switch-box">
                <div
                  :class="`switch-box-left ${showType ? '' : 'switch-click'}`"
                  @click="showChange(0)"
                >
                  <i class="el-icon-menu" />卡片
                </div>
                <div
                  :class="`switch-box-right ${showType ? 'switch-click' : ''}`"
                  @click="showChange(1)"
                >
                  <i class="el-icon-s-operation" />列表
                </div>
              </div>
              <el-button
                size="small"
                @click="more()"
              >查看更多<i class="el-icon-arrow-right el-icon--right" /></el-button>
            </div>
          </div>
          <div v-if="!showType" class="card-item-box">
            <div
              v-for="(item, index) in cardList"
              :key="index"
              class="card-item"
              @click="toHref(item)"
            >
              <div class="care-item-img">
                <img
                  v-if="item.coverUrl"
                  style="width: 100%; height: 100%"
                  :src="item.coverUrl"
                  alt=""
                >
              </div>
              <div class="card-item-info">
                <div class="card-item-info-title">
                  {{ item.title }}
                </div>
                <div class="card-item-info-time">
                  {{ item.updateTime }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card-item-List">
            <div
              v-for="(item, index) in cardList"
              :key="index"
              class="card-list-item"
              @click="toHref(item)"
            >
              <div class="card-list-item-icon" />
              <div class="card-list-item-title">
                {{ item.title }}
              </div>
              <div class="card-list-item-time">
                {{ item.updateTime }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </PageContainer>
</template>
<script>
import { logRecords } from '@/utils/auth.js'
import {
  gethoutInformation // 获取公告数据
} from '@/api/data_new.js'
export default {
  components: {},
  data() {
    return {
      // 列表展示形式
      showType: 0,
      bannerList: [],
      cardList: [],
      carouselIndex: 0,
      autoplay: true
    }
  },
  created() {
    this.getHotData()
    this.getCardData()
  },
  methods: {
    /**
     * @description: 幻灯片切换事件
     * @param {*} e
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 10:35:26
     * @LastEditTime: Do not edit
     */

    carouselChange(e) {
      this.carouselIndex = e
    },
    /**
     * @description: 鼠标移入事件
     * @param {*} item
     * @param {*} index
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 10:35:13
     * @LastEditTime: Do not edit
     */

    massageMouseover(item, index) {
      this.carouselIndex = index
      this.autoplay = false
      this.$refs.carousel.setActiveItem(index)
    },
    /**
     * @description: 鼠标移出事件
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-20 10:43:35
     * @LastEditTime: Do not edit
     */

    massageMouseout() {
      this.autoplay = true
    },
    /**
     * @description: 页面跳转
     * @param {*} item
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:28:56
     * @LastEditTime: Do not edit
     */
    toHref(item) {
      logRecords(
        '国际化',
        '国际资讯',
        '1',
        'tt_international_information',
        item.id,
        '',
        item.title,
        ''
      )
      // debugger;
      const newUrl = this.$router.resolve('/international/details/' + item.id)
      // debugger;
      window.open(newUrl.href, '_blank')
    },
    // 获取上方热点数据
    getHotData() {
      gethoutInformation(`?pageNo=1&pageSize=4 `, {
        isHot: 1
      }).then((res) => {
        if (res.ok) {
          this.bannerList = res.data.list.map((item) => {
            item.coverUrl = item.coverUrl ? "https://www.autoinfo.org.cn" + item.coverUrl : null;
            return item;
          });
        }
      })
    },
    /**
     * @description:获取卡片数据
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:02:12
     * @LastEditTime: Do not edit
     */
    getCardData() {
      gethoutInformation(`?pageNo=1&pageSize=10 `, {
        isHot: 0
      }).then((res) => {
        if (res.ok) {
          this.cardList = res.data.list.map((item) => {

            item.coverUrl = item.coverUrl ? "https://www.autoinfo.org.cn" + item.coverUrl : null;
            return item;
          });
        }
      })
    },
    /**
     * @description: 跳转
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-05 16:26:32
     * @LastEditTime: Do not edit
     */
    more() {
      this.$router.push({ path: '/international/Information/list' })
    },
    /**
     * @description: 列表展示效果切换
     * @param {*} type
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-06 10:08:12
     * @LastEditTime: Do not edit
     */

    showChange(type) {
      this.showType = type
    }
  }
}
</script>

<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1366px) {
  .main-html {
    font-size: calc(16px * #{$BassZoom}) !important;
  }
}
</style>

<style lang="scss" scoped>
$BassZoom: 1;

.container {
  background-color: #fff;
}

.main {
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
}
.top-box {
  width: 100%;
  height: 19.5rem;
  display: flex;
  align-items: center;
  .carousel-box {
    height: 100%;
    width: 58%;

    width: auto;
    aspect-ratio: 16/9; 
    
    margin-right: 0.8333rem;
    .el-carousel,
    .el-carousel__item,
    ::v-deep .el-carousel__container {
      height: 19.5rem !important;

      
    }
    ::v-deep .el-carousel__arrow {
      top: 9.916rem;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      bottom: 3.333rem;
    }
    ::v-deep .el-carousel__indicator--horizontal {
      padding: 0 4px;
    }
    ::v-deep .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
    ::v-deep .el-carousel__indicators > .is-active {
      width: 20px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
    }
    .carousel-item-info {
      position: relative;
      width: 100%;
      height: 100%;
      background: url(~@/assets/img/overview/carousel-bg.png) no-repeat;
      background-size: 100% 100%;



      cursor: pointer;
      .carousel-item-info-text {
        position: absolute;
        width: 100%;
        height: 2.833rem;
        background: rgba(0, 0, 0, 0.4);
        bottom: 0;
        padding-left: 1rem;
        line-height: 2.833rem;
        font-size: calc(18px * #{$BassZoom});
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: calc(500 * #{$BassZoom});
        color: #ffffff;
      }
    }
  }
  .massage-box {
    width: 41%;
    // width: 28.333rem;
    height: 100%;
    padding: 0 1rem;
    background: #ffffff;
    .massage-box-item-hover {
      border-left: 5px solid #0d57bc;
    }
    .massage-box-item {
      width: 100%;
      height: 4.7rem;
      padding: 0.8rem;
      border-bottom: 1px solid #e5e6eb;
      cursor: pointer;
      display: flex;
      align-items: center;
      .massage-box-item-title {
        width: 100%;
        height: 1.083rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .massage-box-item-title-icon {
          display: inline-block;
          margin-right: 0.333rem;
          width: 1rem;
          height: 100%;
          float: left;
          background: url(~@/assets/img/overview/data-sheet-item-down-icon.png)
            no-repeat;
          background-size: 100% 100%;
        }
        .massage-box-item-title-text {
          height: 100%;
          // display: inline-block;
          // flex: 1;
          // width: 19rem * #{$BassZoom};
          overflow: hidden; /*超出的部分隐藏起来。*/
          white-space: nowrap; /*不显示的地方用省略号...代替*/
          text-overflow: ellipsis; /* 支持 IE */
          height: 100%;
          float: left;
          font-size: calc(20px * #{$BassZoom});
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: calc(800 * #{$BassZoom});
          color: #333333;
        }
        .massage-box-item-title-time {
          display: inline-block;
          float: right;
          height: 100%;
          font-size: calc(14px * #{$BassZoom});
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: calc(400 * #{$BassZoom});
          color: #828282;
        }
      }
      .massage-box-item-text {

        word-wrap: break-word;

        margin-top: 0.333rem;
        width: 100%;
        // height: 2rem;
        font-size: calc(20px * #{$BassZoom});
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: calc(800 * #{$BassZoom});
        color: #333333;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;

        overflow-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        overflow: hidden;
      }
    }
  }
}
.bottom-box {
  margin-top: 0.833rem;
  // height: 27.166rem;
  .box-card {
    height: 100%;
    // width: 69.041rem;
    ::v-deep .el-card__header {
      padding: 0.708rem 1rem;
      .box-card-title {
        font-size: calc(20px * #{$BassZoom});
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: calc(600 * #{$BassZoom});
        color: #4f4f4f;
      }
      span {
        font-size: calc(14px * #{$BassZoom});
        color: #0d57bc;
      }
      .el-button {
        padding: 7px 20px;
        border: 1px solid #0d57bc;
      }
      .btn-box {
        float: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .switch-box {
          margin-right: 1rem;
          width: 6.333rem;
          height: 1.333rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 2px solid #f0f0f0;
          border-radius: 3px 3px 3px 3px;
          .switch-box-left,
          .switch-box-right {
            width: 50%;
            height: 100%;
            line-height: 1.333rem;
            font-size: calc(14px * #{$BassZoom});
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: calc(400 * #{$BassZoom});
            text-align: center;
            background-color: #f0f0f0;
          }
          .switch-click {
            color: #0d57bc;
            background-color: #fff;
          }
        }
      }
    }
    // ::v-deep .el-card__body
    .card-item-List {
      padding: 0 1rem;
      padding-bottom: 0.833rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; // 换行
      .card-list-item {
        width: calc(98% / 2);
        height: 2.333rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-list-item-icon {
          display: inline-block;
          margin-right: 0.333rem;
          width: 1rem;
          height: 1rem;
          background: url(~@/assets/img/overview/data-sheet-item-down-icon.png)
            no-repeat;
          background-size: 100% 100%;
        }
        .card-list-item-title {
          display: inline-block;
          flex: 1;
          line-height: 2.333rem;
          height: 100%;
          font-size: calc(18px * #{$BassZoom});
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: calc(500 * #{$BassZoom});
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .card-list-item-time {
          display: inline-block;
          line-height: 2.333rem;
          float: right;
          height: 100%;
          font-size: calc(14px * #{$BassZoom});
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: calc(400 * #{$BassZoom});
          color: #828282;
        }
      }
    }
    .card-item-box {
      padding: 0 1rem;
      padding-bottom: 0.833rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; // 换行
      .card-item {
        width: calc(95% / 5);
        height: 10rem;
        margin-top: 0.7rem;
        cursor: pointer;
          // margin: 0 10px;  
        .care-item-img {
          width: 100%;
          height: 5rem;
          background: url(~@/assets/img/overview/card-item-test-1.png) no-repeat;
          background-size: 100% 100%;

          height: auto;
          aspect-ratio: 16/9; 
        }
        .card-item-info-title {
          margin-top: 0.333rem;
          font-size: calc(18px * #{$BassZoom});
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: calc(500 * #{$BassZoom});
          color: #333333;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -moz-box;
          -moz-line-clamp: 1;
          -moz-box-orient: vertical;
          overflow-wrap: break-word;
          word-break: break-all;
          white-space: normal;
          overflow: hidden;
        }
        .card-item-info-time {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}
@media screen and (min-width: 1921px) {
  .massage-box-item-text {
    -webkit-line-clamp: 3 !important;
    -moz-line-clamp: 3 !important;
  }
}
@media screen and (max-width: 1366px) {
  .carousel-item-info-text,
  .massage-box-item-title-text,
  .card-list-item-title,
  .card-item-info-title {
    font-size: 14px !important;
  }
  .massage-box-item-title-text {
    width: calc(16rem * #{$BassZoom}) !important;
  }
  .card-list-item-title {
    width: 20rem !important;
  }
  .el-button {
    padding: 5px 6px !important;
  }
  .card-item {
    // height: 9rem * #{$BassZoom} !important;
  }
  // .care-item-img{

  // }
  .massage-box-item-text,
  .card-list-item-time,
  .card-item-info-time {
    font-size: 12.8px !important;
  }
  .switch-box-left,
  .switch-box-right,
  .massage-box-item-title-time {
    font-size: 12px !important;
  }
}
</style>
