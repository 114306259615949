<template>
    <div :class="tableIndex ? 'indexBox' : 'listBox'">
        <div class="box_box_title" v-for="(item) in tableData" :key="item.id"
            @click="toHref('/thematicAnalysis/details/' + item.id, item)">
            <div style="display: flex">
                <div class="text-center">
                    <img class="indexImg" v-if="noshowDefImg && item.coverUrl" :src="item.coverUrl" alt="" />
                    <img class="listImg" v-if="noshowDefImg===false" :src="item.imgUrl ? item.imgUrl : (item.coverUrl ? item.coverUrl : defaultUrl)" alt="" />
                </div>
                <div class="flex_col" flex="1">
                    <div class="index-new-link">
                        <div flex="c" class="flexc">
                            <span class="yc" v-if="item.contentCategory == 'yc'">原创</span>
                            <Tooltip bold :fontSize="18" v-bind:title="htmlRestore(item.shortTitle ? item.shortTitle : item.title)" >
                                <template slot="img" v-if="flag==0 && item.sortWeight > 0">
                                    <i class="topicon el-icon-download"></i>
                                    <img  src="@/assets/img/fire16_16.png" alt="">
                                </template>
                            </Tooltip>
                        </div>
                        <div class="content">
                            {{ htmlRestore(item.introduction) }}
                        </div>
                    </div>
                    <div class="releaseColumn" v-if="showReleaseColumn">
                        <span :class="ColumnClass(item.releaseColumn)" v-if="item.releaseColumn">{{ item.releaseColumn }}</span>
                        <span :class="ColumnClass(item.technology)" v-if="item.technology">{{ item.technology }}</span>
                        <span :class="ColumnClass(item.keyword)" v-if="item.keyword">{{ item.keyword }}</span>
                    </div>
                    <div class="extra">
                      <div class="box">
                          <span v-if="tableUpdateTimet" class="date">{{ item.updateTime }}</span>
                          <span v-else-if="tableIndex" class="date">{{ item.updateTime }}</span>
                          <span v-else class="date">{{ htmlRestore(item.publishDate) }}</span>
                          <el-button
                            v-if="(tablespecific || updateTimedown) && item.fileInfo"
                            plain
                            :type="isInDownId(item.id) ? 'primary' : ''"
                            @click.stop="clickDown(item, item.id)"
                          >
                            <i class="el-icon-download"></i>下载
                          </el-button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pages" v-if="showPage">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :pager-count="pageData.pagerCount || 5"
                :current-page="pageData.pages.pageNo" :page-sizes="[10, 20, 30, 40]" :page-size="pageData.pages.pageSize"
                layout="total, prev, pager, next ,sizes, jumper" :total="pageData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Tooltip from './toolTip.vue';
export default {
    components: {
        Tooltip
    },
    data() {
        return {
            defaultUrl: require("@/assets/img/default-img.png"),
        };
    },
    props: {
        downId: {
            type: Array,
        },
        tableData: {
            type: Array,
        },
        pageData: {
            type: Object,
        },
        updateTimedown: {
            type: Boolean,
            default: false,
        },
        // 是否展示updateTime后下载
        tableUpdateTimet: {
            type: Boolean,
            default: false,
        },
        // 市场洞察
        flag: {
            type: String,
        },
        // 0为推荐
        tablespecific: {
            type: Boolean,
            default: false,
        },
        // 专题分析
        tableIndex: {
            type: Boolean,
            default: false,
        },
        // 是否为首页
        noshowDefImg: {
            type: Boolean,
            default: false,
        },
        // 是否展示默认图片
        showPage: {
            type: Boolean,
            default: true,
        },
        // 是否展示分页
        showReleaseColumn: {
            type: Boolean,
            default: false,
        },
         // 是否展示行业
         showBar: {
            type: Boolean,
            default: false,
        },
        // 是否有actionBar
    },
    methods: {
        clickDown(item,id){
            this.$emit('clickDown', item,id);
        },
        handleSizeChange(val) {
            this.$emit('size-change', val);
        },
        toHref(url, item) {
            this.$emit('toHref', url, item);
        },
        handleCurrentChange(val) {
            this.$emit('current-change', val);
        }
    },
    computed: {
    isInDownId() {
      return (id) => {
        return this.downId.includes(id);
       };
     },
     ColumnClass() {
        return (val) => {
            const colorMap = {
            '新能源': 'green',
            '乘用车': 'red',
            '商用车': 'blue',
            '零部件': 'gray',
            '宏观产业': 'pink',
            '智能网联': 'skyblue',
            '后市场': 'purple',
            '区域产业': 'lilac',
            '轻量化技术': 'red',
            '芯片技术': 'blue',
            '安全技术': 'gray',
            '传统技术': 'pink',
            '节能减排': 'purple',
            '电子电器': 'lilac',
            '制造与工艺': 'viridis',
            '车身附件': 'orange',
            '产业布局': 'red',
            '技术路线': 'blue',
            '产品规划': 'gray',
            '经营动态': 'pink',
            '合资合作': 'purple',
            '战略规划': 'lilac',
            '人事变动': 'viridis',
            '国际化': 'orange',
            '组织架构': 'skyblue',
            '市场动态': 'green',
        };
        return colorMap[val] || '';
       };
    }
    }
}
</script>

<style lang="scss" scoped>
.listBox {
    position: relative;
    z-index: 1;
    background: #ffffff;
    padding: 24px 22px 0 22px;
}
.indexBox {
    position: relative;
    z-index: 1;
    background: #ffffff;
}

.box_box_title {
    margin-bottom: 20px;
    cursor: pointer;
    p {
        font-weight: 500;
        color: #000;
        font-size: 18px;
        img {
            margin-top: -3px;
        }
    }
    .flex_col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .listImg {
        width: 267px;
        height: 150px;
        border-radius: 4px;
        margin-right: 26px;
    }
    .indexImg {
        width: 285px;
        height: 177px;
        border-radius: 4px;
        margin-right: 26px;
    }
    .flexc {
        margin-bottom: 6px;
        .yc {
            display: inline-flex;
            margin-top: 2px;
            margin-right: 8px;
            padding: 3px 4px;
            color: #fff;
            font-size: 12px;
            line-height: 1;
            white-space: nowrap;
            background: #ff6e4b;
            background-image: linear-gradient(136deg, rgb(255,142,93) 0%, rgba(255,47,47,0.99608) 100%);
            border-radius: 2px;
        }
    }
    .releaseColumn {
        padding: 6px 0;
        span {
          font-size: 16px;
          display: inline-flex;
          margin-right: 10px;
          padding: 3px 6px;
          line-height: 1;
          border-radius: 2px;
        }
    }
    .content {
        color: #999999;
        font-size: 14px;
        line-height: 1.8333;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .extra {
        flex: 1;
        display: flex;
        align-items: flex-end;

        .box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .date {
          display: inline-flex;
          padding: 4px 0;
          color: #bbbbbb;
          font-size: 12px;
        }

        ::v-deep .el-button {
            padding: 9px 18px;
            color: #999;
            font-size: 12px;
            border-radius: 4px;
            border-color: #f3f3f3;

            &:focus {
                border-color: #f3f3f3;
            }
            &:hover {
                border-color: #3f76ea;
            }
            &.el-button--primary {
                color: #3f76ea;
                border-color: #3f76ea;
            }
            .el-icon-download {
                margin-right: 6px;
            }
        }
    }
    .green {
        color: #8cc15c;
        background: #e3f9cf;
    }
    .red{
        color: #ff8080;
        background: #fef0f0;
    }
    .blue {
        color: #4375ee;
        background: #eaf0fd;
    }
    .skyblue {
        color: #58b2f0;
        background: #daf0ff;
    }
    .gray {
        color: #9f9fa1;
        background: #e9e8eb;
    }
    .pink {
        color: #ff5daf;
        background: #ffedf6;
    }
    .lilac {
        color: #9fb1fb;
        background: #ebeefd;
    }
    .purple {
        color: #a48be3;
        background: #ded1ff;
    }
    .viridis {
        color: #16dbcc;
        background: #dcfaf8;
    }
    .orange {
        color: #ffcb7a;
        background: #fff5d9;
    }
}
.topicon {
    color: #ef6448;
    transform: rotate(180deg);
    font-weight: bold;
}
.pages {
    margin-bottom: 0 ;
    padding: 40px 0;
    display: flex;
    justify-content: center;
}
</style>
