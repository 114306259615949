//字典
import $http from '@/plugins/http.js'
//字典
export function selectDictDataByType(params) {
    return $http.get('/api/dict/sysDictData/selectDictDataByType', params)
}

//纬度
export function ttDemension(params) {
    return $http.get('/api/dict/ttDemension', params)
}