<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="政策" text="政策栏目围绕政策本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新政策" :showTips="showTips" />-->
    <template>
      <div class="search-form">
        <el-form class="g-form" :model="form" ref="form">
          <ul class="g-form-item">
            <li>
              <el-form-item prop="tag">
                <label class="text-right">关键字：</label>
                <el-input v-model="form.tag" placeholder="请输入内容" class="g-form-input"></el-input>
              </el-form-item>
            </li>
            <li>
              <el-form-item prop="certificateUnit">
                <label class="text-right">颁布单位：</label>
                <el-input v-model="form.certificateUnit" placeholder="请输入内容" class="g-form-input"></el-input>
                <!-- <div @click="treeVisible = true">
  <el-input
    v-model="form.certificateUnit"
    placeholder="请输入内容"
    class="g-form-input"
    readonly
  ></el-input>
</div> -->
              </el-form-item>
            </li>
            <li>
              <el-form-item prop="title">
                <label class="text-right">政策名称：</label>
                <el-input v-model="form.title" placeholder="请输入内容" class="g-form-input"></el-input>
              </el-form-item>
            </li>
          </ul>
          <ul class="g-form-item">
            <li>
              <el-form-item>
                <label class="text-right">公开日期：</label>
                <el-date-picker unlink-panels v-model="publicDate" type="daterange" start-placeholder="开始日期"
                                end-placeholder="结束日期" class="g-form-input">
                </el-date-picker>
              </el-form-item>
            </li>
            <li>
              <el-form-item>
                <label class="text-right">成文日期：</label>
                <el-date-picker unlink-panels v-model="transactionDate" type="daterange" start-placeholder="开始日期"
                                end-placeholder="结束日期" class="g-form-input">
                </el-date-picker>
              </el-form-item>
            </li>
            <li>
              <el-form-item>
                <label class="text-right">实施日期：</label>
                <el-date-picker unlink-panels v-model="implementDate" type="daterange" start-placeholder="开始日期"
                                end-placeholder="结束日期" class="g-form-input">
                </el-date-picker>
              </el-form-item>
            </li>
          </ul>
          <ul class="g-form-item" style="margin-left: -52px;">
            <li style="margin-left: 50px;">
              <el-form-item prop="policyCategory">
                <label class="text-right">政策类别：</label>
                <el-cascader :options="policyCategoryData" :props="policyCategoryProps" collapse-tags clearable
                             style="height:32px;line-height:32px;margin-top:5px" class="g-form-input"
                             v-model="form.policyCategory" ref="belongAreas"></el-cascader>
                <!-- <el-select v-model="form.policyCategory" placeholder="请选择" class="g-form-input">
                    <el-option v-for="item in policyCategoryData" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                    </el-option>
                </el-select> -->
              </el-form-item>
            </li>
            <li>
              <el-form-item prop="areas">
                <div flex="c">
                  <label class="text-right">政策区域：</label>
                  <el-radio-group v-model="form.areas" @change="areasChange">
                    <el-radio label="">全部</el-radio>
                    <el-radio :label="item.dictValue" v-for="item in areas" :key="item.dictValue">{{
                        item.dictLabel
                      }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item prop="city" v-if="form.areas == 2">
                <div class="check-position">
                  <el-input v-model="form.city" placeholder="城市" class="g-form-input" style="width: 80px"></el-input>
                </div>
              </el-form-item>
            </li>
            <li>
              <el-form-item prop="publishType">
                <label class="text-right">发布类型：</label>
                <el-select v-model="form.publishType" placeholder="请选择" class="g-form-input" clearable>
                  <el-option v-for="item in sandType" :key="item.value" :label="item.lable" :value="item.value">
                  </el-option>
                </el-select>

              </el-form-item>
            </li>
          </ul>
          <ul class="g-form-item">
<!--            <li>-->
<!--              <el-form-item>-->
<!--                <label class="text-right">利好车型：</label>-->
<!--                <el-popover placement="bottom" width="350" v-model="visible">-->
<!--                  <ul class="nature-tag">-->
<!--                    <li :key="item.dictValue" v-for="item in natureData.cyc" @click="natureBtn1(item)" class="zhanwei"-->
<!--                        :class="{-->
<!--                        ac1: form.nature1.indexOf(item.dictValue) != -1,-->
<!--                        ac2: form.nature2.indexOf(item.dictValue) != -1,-->
<!--                      }">-->
<!--                      {{ item.dictLabel }}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <ul class="nature-tag">-->
<!--                    <li :key="item.dictValue" v-for="item in natureData.hc" @click="natureBtn1(item)" class="zhanwei"-->
<!--                        :class="{-->
<!--                        ac1: form.nature1.indexOf(item.dictValue) != -1,-->
<!--                        ac2: form.nature2.indexOf(item.dictValue) != -1,-->
<!--                      }">-->
<!--                      {{ item.dictLabel }}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <ul class="nature-tag">-->
<!--                    <li :key="item.dictValue" v-for="item in natureData.kc" @click="natureBtn1(item)" class="zhanwei"-->
<!--                        :class="{-->
<!--                        ac1: form.nature1.indexOf(item.dictValue) != -1,-->
<!--                        ac2: form.nature2.indexOf(item.dictValue) != -1,-->
<!--                      }">-->
<!--                      {{ item.dictLabel }}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <div style="text-align: right; margin: 0">-->
<!--                    <el-button size="mini" @click="visible = false">关闭</el-button>-->
<!--                  </div>-->
<!--                  <el-input v-model="natureStr1" placeholder="请输入内容" class="g-form-input" slot="reference"-->
<!--                            readonly></el-input>-->
<!--                </el-popover>-->
<!--              </el-form-item>-->
<!--            </li>-->
<!--            <li>-->
<!--              <el-form-item>-->
<!--                <label class="text-right">利空车型：</label>-->
<!--                <el-popover placement="bottom" width="350" v-model="visible2">-->
<!--                  <ul class="nature-tag">-->
<!--                    <li :key="item.dictValue" v-for="item in natureData.cyc" @click="natureBtn2(item)" class="zhanwei"-->
<!--                        :class="{-->
<!--                        ac2: form.nature2.indexOf(item.dictValue) != -1,-->
<!--                        ac1: form.nature1.indexOf(item.dictValue) != -1,-->
<!--                      }">-->
<!--                      {{ item.dictLabel }}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <ul class="nature-tag">-->
<!--                    <li :key="item.dictValue" v-for="item in natureData.hc" @click="natureBtn2(item)" class="zhanwei"-->
<!--                        :class="{-->
<!--                        ac2: form.nature2.indexOf(item.dictValue) != -1,-->
<!--                        ac1: form.nature1.indexOf(item.dictValue) != -1,-->
<!--                      }">-->
<!--                      {{ item.dictLabel }}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <ul class="nature-tag">-->
<!--                    <li :key="item.dictValue" v-for="item in natureData.kc" @click="natureBtn2(item)" class="zhanwei"-->
<!--                        :class="{-->
<!--                        ac2: form.nature2.indexOf(item.dictValue) != -1,-->
<!--                        ac1: form.nature1.indexOf(item.dictValue) != -1,-->
<!--                      }">-->
<!--                      {{ item.dictLabel }}-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <div style="text-align: right; margin: 0">-->
<!--                    <el-button size="mini" @click="visible2 = false">关闭</el-button>-->
<!--                  </div>-->
<!--                  <el-input v-model="natureStr2" placeholder="请输入内容" class="g-form-input" slot="reference"-->
<!--                            readonly></el-input>-->
<!--                </el-popover>-->
<!--              </el-form-item>-->
<!--            </li>-->
            <li>
              <el-form-item prop="belongAreas">
                <label class="text-right">热门领域：</label>
                <el-select v-model="form.belongAreas" multiple placeholder="请选择" size="small" class="g-form-input"
                           clearable>
                  <el-option v-for="item in belongAreasData" :key="item.dictValue" :label="item.dictLabel"
                             :value="item.dictValue">
                  </el-option>
                </el-select>
              </el-form-item>
            </li>
          </ul>
          <div class="text-center">
            <el-button class="reset-button" @click="resetForm('form')">重置</el-button>
            <el-button class="search-button" @click="searchForm">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="search-form">
<!--        <div flex="sb">-->
<!--                    <span class="table_title">{{-->
<!--                        viewSwitchFlag ? "政策对不同领域/车型影响" : ""-->
<!--                      }}</span>-->
<!--          <el-button type="primary" plain @click="viewSwitch">{{-->
<!--              viewSwitchFlag ? "概要视图" : "领域影响视图"-->
<!--            }}-->
<!--          </el-button>-->
<!--        </div>-->
        <el-table :data="selectPolicyDate" style="width: 100%"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }" v-show="viewSwitchFlag"
                  @sort-change="tableSort">
          <el-table-column prop="title" label="政策名称">
            <template slot-scope="scope">
              <div style="color: #0D57BC;cursor: pointer"
                   @click="toHref('/policy/dynamic/contentNewPolicy/' + scope.row.id,scope.row.id,scope.row.title)">
                {{ htmlRestore(scope.row.title) }}
              </div>
            </template>
          </el-table-column>

          <el-table-column sortable="custom" prop="natureCyc" label="利好">
            <template slot-scope="scope">
              <div class="nature-box lh" v-if="scope.row.nature1">
                {{ scope.row.nature1 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column sortable="custom" prop="natureKc " label="利空">
            <template slot-scope="scope">
              <div class="nature-box lk" v-if="scope.row.nature2">
                {{ scope.row.nature2 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column sortable="custom" prop="natureHc" label="中性">
            <template slot-scope="scope">
              <div class="nature-box" v-if="scope.row.nature3">
                {{ scope.row.nature3 }}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column sortable prop="date" label="车型影响">
              <template slot-scope="scope">
                  <div class="nature-box lh" v-if="scope.row.nature1">
                      {{ scope.row.nature1 }}
                  </div>
                  <div class="nature-box lk" v-if="scope.row.nature2">
                      {{ scope.row.nature2 }}
                  </div>
                  <div class="nature-box" v-if="scope.row.nature3">
                      {{ scope.row.nature3 }}
                  </div>
              </template>
          </el-table-column> -->
        </el-table>
        <el-table :data="selectPolicyDate" ref="newTable" style="width: 100%"
                  :header-cell-style="{ background: '#FAFAFA', color: '#000' }" v-show="!viewSwitchFlag" stripe
                  @sort-change="tableSort" v-loading="loading">
          <el-table-column  prop="province" label="地区" min-width	="100"/>
          <el-table-column prop="title" label="政策名称" min-width	="300">
            <template slot-scope="scope">
              <div style="color: #0D57BC;cursor: pointer"
                   @click="toHref('/policy/dynamic/contentNewPolicy/' + scope.row.id,scope.row.id,scope.row.title)">
                {{ htmlRestore(scope.row.title) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column  prop="belongAreas" label="政策领域" min-width	="150"/>
          <el-table-column sortable="custom" prop="publicDate" label="公开日期" min-widt="100"/>
          <el-table-column sortable="custom" prop="transactionDate" label="成文日期" min-width	="100"/>
          <el-table-column sortable="custom" prop="implementDate" label="实施日期" min-width	="100"/>
          <el-table-column sortable="custom" prop="endDate" label="终止日期" min-width	="100"/>
        </el-table>

        <div class="text-right">
          <el-pagination v-if="pageShow" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                         :current-page="selectPolicyPage.pages.page" :page-sizes="pageSizes"
                         :page-size="selectPolicyPage.pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
                         :total="selectPolicyPage.total">
          </el-pagination>
        </div>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 政策查询页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {selectPolicy} from "@/api/policy.js";
import {selectDictDataByType, ttDemension} from "@/api/dict.js";
import {getUserInfo, logRecords, ydlTk, openSy} from "@/utils/auth.js";

import {fmtDate} from "@/utils/date.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      visible: false,
      visible2: false,
      treeVisible: false,
      show: false,
      sandType: [
        {
          lable: "征求意见稿",
          value: "2",
        },
        {
          lable: "正式发布稿",
          value: "1",
        },
      ],
      showTips: [
        {
          title: "政策动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "政策库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "政策梳理",
          text: "专家梳理整合，真正读懂政策脉络",
        },
        {
          title: "政策大数据",
          text: "用数据深度理解政策结构和趋势",
        },
      ],
      viewSwitchFlag: false,
      form: {
        tag: "",
        input: "",
        publicDateStart: "",
        publicDateEnd: "",
        policyCategory: [],
        voidDate: "",
        certificateUnit: "",
        status: "",
        nature1: [],
        nature2: [],
        areas: "",
        city: "",
        title: "",
        implementDateStart: "",
        implementDateEnd: "",
        publishType: "",
        transactionDateStart: "",
        transactionDateEnd: "",
        belongAreas: [],
        orderType: ""
      },
      belongAreasData: [],
      implementDate: "",
      publicDate: "",
      transactionDate: "",
      //颁布单位
      certificateUnit: "",
      selectPolicyDate: [],
      selectPolicyPage: {
        pages: {
          page: 1,
          pageSize: 10,
        },
        total: 0,
      },
      pageSizes:[10, 20, 30, 40],
      natureData: {},
      natureName1: [],
      natureStr1: "",
      natureName2: [],
      natureStr2: "",
      areas: [
        {dictValue: "1", dictLabel: "国家"},
        {dictValue: "2", dictLabel: "地方"},
      ],
      policyCategoryData: [],
      policyCategoryProps: {
        multiple: true,
        value: "id",
        label: "name",
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "政策",
        userId: ""
      },
      loading: false,
      policyCategoryed: '',
      pageShow: true
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.selectPolicy(this.selectPolicyPage.pages);
    // this.getNature({code: "nature"});
    this.ttDemension({code: "policyType"});
    this.getCategory();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.policyCategory) {
        this.policyCategoryed = this.$route.query.policyCategory
        this.form.policyCategory.push(this.$route.query.policyCategory)
        this.init()
      }
    })
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    //   viewDetail(path,id){
    //     let newUrl = this.$router.resolve({
    //         path: path + id
    //     });
    //     window.open(newUrl.href, "_blank");
    // },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    toHref(url,id,title) {

      this.formLog.eventType = "1";
      let newUrl = this.$router.resolve(url);
      if (getUserInfo()) {

        logRecords('政策','政策库','1','tt_policy',id,'',title, '')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('政策')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    selectPolicy(params) {

      if(!getUserInfo()){
        this.pageSizes = [10]
      }
      if (!getUserInfo() && this.selectPolicyPage.pages.page>1) {
          this.showLogin();
      }else{
        if(this.form.title!="" && this.form.title!=null){
          logRecords('政策','政策库','4','tt_policy','','','', this.form.title)

        }
        this.loading = true
        selectPolicy(params).then((res) => {
          if (res.ok) {
            this.selectPolicyDate = res.data.list;
            this.selectPolicyDate.forEach((item) => {
              item.publishDate = fmtDate(
                  "YYYY-mm-dd",
                  item.publishDate
              );
              item.transactionDate = fmtDate(
                  "YYYY-mm-dd",
                  item.transactionDate
              );
              item.implementDate = fmtDate(
                  "YYYY-mm-dd",
                  item.implementDate
              );
              item.endDate = fmtDate("YYYY-mm-dd", item.endDate);
            });

            this.selectPolicyPage.total = res.data.count;

          }
          this.loading = false
        });
      }

    },
    ttDemension(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.policyCategoryData = res.data
          // console.log(this.policyCategoryData)
        }
      });
    },
    viewSwitch() {
      this.viewSwitchFlag = !this.viewSwitchFlag;
    },
    handleSizeChange(val) {
      this.selectPolicyPage.pages.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      // 如果是试用用户
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.selectPolicyPage.pages.page = val;
        this.init();
      }
    },
    //查询利好利空车型
    getNature(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.natureData = this.getDataGrop(res.data);
        }
      });
    },
    getDataGrop(data) {
      let datas = {
        cyc: [],
        kc: [],
        hc: [],
      };
      data.forEach((item) => {
        if (item.name == "乘用车") {
          datas.cyc.push({
            dictValue: item.id,
            dictLabel: item.name,
          });
          if (item.children && item.children.length > 0) {
            item.children.forEach((citem) => {
              datas.cyc.push({
                dictValue: citem.id,
                dictLabel: citem.name,
              });
            });
          }
        } else if (item.name == "客车") {
          datas.kc.push({
            dictValue: item.id,
            dictLabel: item.name,
          });
          if (item.children && item.children.length > 0) {
            item.children.forEach((citem) => {
              datas.kc.push({
                dictValue: citem.id,
                dictLabel: citem.name,
              });
            });
          }
        } else if (item.name == "货车") {
          datas.hc.push({
            dictValue: item.id,
            dictLabel: item.name,
          });
          if (item.children && item.children.length > 0) {
            item.children.forEach((citem) => {
              datas.hc.push({
                dictValue: citem.id,
                dictLabel: citem.name,
              });
            });
          }
        }
      });
      return datas;
    },
    //树形结构获取
    getNode(v) {
      let arr = v.nodeDataName.map((item) => {
        return item.name;
      });
      this.treeVisible = false;
    },
    natureBtn1(item) {
      if (this.form.nature1.indexOf(item.dictValue) != -1) {
        this.natureName1.splice(
            this.natureName1.indexOf(item.dictLabel),
            1
        );
        this.form.nature1.splice(
            this.form.nature1.indexOf(item.dictValue),
            1
        );
      } else {
        this.natureName1.push(item.dictLabel);
        this.form.nature1.push(item.dictValue);
        if (this.form.nature2.indexOf(item.dictValue) != -1) {
          this.natureName2.splice(
              this.natureName2.indexOf(item.dictLabel),
              1
          );
          this.form.nature2.splice(
              this.form.nature2.indexOf(item.dictValue),
              1
          );
          this.natureStr2 = this.natureName2.join(",");
        }
      }

      this.natureStr1 = this.natureName1.join(",");
    },
    natureBtn2(item) {
      if (this.form.nature2.indexOf(item.dictValue) != -1) {
        this.natureName2.splice(
            this.natureName2.indexOf(item.dictLabel),
            1
        );
        this.form.nature2.splice(
            this.form.nature2.indexOf(item.dictValue),
            1
        );
      } else {
        this.natureName2.push(item.dictLabel);
        this.form.nature2.push(item.dictValue);
        if (this.form.nature1.indexOf(item.dictValue) != -1) {
          this.natureName1.splice(
              this.natureName1.indexOf(item.dictLabel),
              1
          );
          this.form.nature1.splice(
              this.form.nature1.indexOf(item.dictValue),
              1
          );
          this.natureStr1 = this.natureName1.join(",");
        }
      }
      this.natureStr2 = this.natureName2.join(",");
    },
    //政策区域切换
    areasChange(e) {
      this.form.city = "";
    },
    //初始化
    init(val) {
      let form = Object.assign(
          {},
          this.form,
          this.selectPolicyPage.pages
      );
      form.nature1 = form.nature1.join(",");
      form.nature2 = form.nature2.join(",");
      form.implementDateStart = this.implementDate[0];
      form.implementDateEnd = this.implementDate[1];

      form.publicDateStart = this.publicDate[0];
      form.publicDateEnd = this.publicDate[1];

      form.transactionDateStart = this.transactionDate[0];
      form.transactionDateEnd = this.transactionDate[1];
      form.belongAreas = form.belongAreas.join(",")
      if (form.policyCategory) {
        form.policyCategory = this.unique(
            form.policyCategory.join(",").split(",")
        ).join(",");
      }
      this.selectPolicy(form);
    },
    //表单查询
    searchForm() {
      this.formLog.eventType = "2";
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('政策')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.selectPolicyPage.pages.page = 1;
            this.init();
          }
        } else {
          this.selectPolicyPage.pages.page = 1;
          this.init();        }
      } else {
        this.showLogin();
      }

    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.natureName1 = [];
      this.natureStr1 = "";
      this.natureName2 = [];
      this.natureStr2 = "";
      this.form.nature1 = [];
      this.form.nature2 = [];
      this.form.orderType = "";
      this.selectPolicyPage.pages.page = 1;
      this.implementDate = "";
      this.publicDate = "";
      this.transactionDate = "";
      this.$refs.newTable.clearSort()
      this.init();
      // this.selectPolicy(this.selectPolicyPage.pages);
    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //获取字典
    getCategory() {
      selectDictDataByType({dictType: "policy_hot_field"}).then(
          (res) => {
            if (res.ok) {
              this.belongAreasData = res.data;
            }
          }
      );
    },
    tableSort(column) {
      this.form.orderType = this.otherzh(column)
      this.init()
    },
    otherzh(column) {
      if (column.order == 'ascending') {


        if (column.prop == 'publicDate') {
          return 'public_date'
        } else if (column.prop == 'transactionDate') {
          return 'transaction_date'
        } else if (column.prop == 'implementDate') {
          return 'implement_date'
        } else if (column.prop == 'endDate') {
          return 'end_date'
        } else if (column.prop == 'policyCategory') {
          return 'policy_category'
        } else {
          return column.prop
        }
      } else if (column.order == 'descending') {
        if (column.prop == 'publicDate') {
          return 'public_date' + ' DESC'
        } else if (column.prop == 'transactionDate') {
          return 'transaction_date' + ' DESC'
        } else if (column.prop == 'implementDate') {
          return 'implement_date' + ' DESC'
        } else if (column.prop == 'endDate') {
          return 'end_date' + ' DESC'
        } else if (column.prop == 'policyCategory') {
          return 'policy_category' + ' DESC'
        } else {
          return column.prop + ' DESC'
        }
      } else {
        return ''
      }
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__content {
  display: flex;
  align-content: center;
}

.search-form {
  padding: 28px 40px;
  background: #fff;
  margin-bottom: 10px;
}

.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;

    label {
      display: inline-block;
      width: 85px;
    }

    /deep/ .el-radio {
      margin-right: 0;
      width: 60px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -82px;
    }

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      float: right;
    }

    .g-form-input {
      flex: 1;
      width: 230px;

      /deep/ .el-input__inner,
      /deep/ .el-input__icon,
      /deep/ &.el-date-editor .el-range-separator,
      /deep/ &.el-range-editor.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

.nature-tag {
  overflow: hidden;
  margin-left: -10px;

  li {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    padding: 0 2px;
    border: 1px solid #828282;
    color: #828282;
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    &.ac1 {
      color: #219653;
      border-color: #219653;
    }

    &.ac2 {
      color: #eb5757;
      border-color: #eb5757;
    }
  }
}

.nature-box {
  position: relative;
  padding-left: 14px;
  height: 22px;
  line-height: 22px;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    margin-top: -3px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #d9d9d9;
  }

  &.lh {
    &::after {
      background: #27ae60;
    }
  }

  &.lk {
    &::after {
      background: #eb5757;
    }
  }
}

</style>
<style>

.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}
</style>
