import { render, staticRenderFns } from "./comparedModal.vue?vue&type=template&id=27efa9af&scoped=true&"
import script from "./comparedModal.vue?vue&type=script&lang=js&"
export * from "./comparedModal.vue?vue&type=script&lang=js&"
import style0 from "./comparedModal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./comparedModal.vue?vue&type=style&index=1&id=27efa9af&lang=scss&scoped=true&"
import style2 from "../components/iconfont/iconfont.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27efa9af",
  null
  
)

export default component.exports