<!--
 * @Author: your name
 * @Date: 2021-07-16 13:29:59
 * @LastEditTime: 2021-07-20 13:47:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/policy/carding/index.vue
-->
<template>
  <PageContainer>
    <!--        <VnotLogin v-if="!show" title="标准" text="标准栏目围绕标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新标准" :showTips="showTips" />-->
    <template>
      <!--      <div flex="sb" style="height: 100%" :class="{'baes':leftShow}">-->

      <div style="flex: 1" class="carding_right">
        <el-tabs class="search-form n-tab" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.name" v-for="item in data" :key="item.id"
                       :data-item="JSON.stringify(item.children[0])">
            <el-radio-group v-model="radio" size="small">
              <div style="display: inherit;margin-right: 10px;" v-for="items in item.children" :key="items.id">
                <el-radio :label="items.name" border @click.native="radioChange($event,items)">
                  {{ items.name }}
                </el-radio>
              </div>

            </el-radio-group>
          </el-tab-pane>

        </el-tabs>
        <div class="block" v-loading="load" element-loading-text="拼命加载中"
             element-loading-background="rgba(0, 0, 0, 0)">
          <el-timeline>
            <el-timeline-item placement="top" size="large" :timestamp="item.updateTime"
                              v-for="(item,index) in standardAllList" :key="index">
              <el-card v-for="(itemA,indexA) in item.children" :key="indexA">
                <div class="carding_right_item_p">
                  <p v-html="itemA.summary" class="p"></p>
                </div>
                <div flex="sb">
                  <div class="carding_right_l">
                    <div class="tag"><img src="@/assets/img/menu-icon.png" alt="" class="icon">{{ itemA.menu }}</div>
                    <div style="color: #0D57BC;cursor: pointer" class="link-style"
                         @click="toHref('/standards/carding/details/'+itemA.standardContentId,itemA) ">{{ itemA.title }}
                    </div>

                  </div>
                </div>

                <div flex="1" style="margin-top: 2px;" v-for="(item2,index1) in itemA.children" :key="index1">
                  <div class="carding_right_l">
                    <div class="tag"><img src="@/assets/img/menu-icon.png" alt="" class="icon">{{ item2.menu }}</div>
                    <div style="color: #0D57BC;cursor: pointer" class="link-style"
                         @click="toHref('/standards/carding/details/'+item2.standardContentId,item2) ">{{ item2.title }}
                    </div>

                  </div>
                </div>

              </el-card>
            </el-timeline-item>

          </el-timeline>
        </div>
      </div>
      <!--      </div>-->
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 2021-07-20 标准梳理页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import {getUserInfo, logRecords, ydlTk} from "@/utils/auth.js";
import {htmlRestore} from "@/utils/htmlRestore.js";
import {standardTree, getStandardContent} from "@/api/standards.js";
import {fmtDate} from "@/utils/date.js";
import {buryPoint} from "@/api/common";

export default {
  // components: {
  //     VnotLogin,
  // },
  data() {
    return {
      show: false,
      showTips: [
        {
          title: "标准动态",
          text: "最新权威动向和独家原创解读",
        },
        {
          title: "标准库查询",
          text: "权威数据库，多条件筛查关联比对",
        },
        {
          title: "标准梳理",
          text: "专家梳理整合，真正读懂标准脉络",
        },
        {
          title: "标准大数据",
          text: "用数据深度理解标准结构和趋势",
        },
      ],
      activeName: '',
      radio: '',
      filterText: "",
      treeTitle: "全部",
      keywords: "",
      data: [],
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      leftShow: false,
      btnShow: false,
      load: true,
      formLog: {
        eventName: "开通卡片点击",
        eventType: "1",
        columnName: "标准",
        userId: ""
      },
      standardPage: {
        pageSize: 5,
        page: 1,
      },
      form: {
        title: "",
        treeId: "",
      },
      treeId: "",
      loadinginName: "点击加载更多",
      standardAllList: []

    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.standardTree();
  },
  methods: {
    radioChange(e, tree) {
      if (e.target.tagName != 'INPUT') {
        return
      }
      this.keywords = tree.keywords;

      this.standardAllList = [];

      this.treeId = tree.id;
      this.getStandardContent(tree.id);


    },
    handleClick(tab) {
      let tree = JSON.parse(tab.$el.dataset.item);
      this.keywords = tree.keywords;
      this.radio = tree.name;


      this.standardAllList = [];

      this.treeId = tree.id;
      this.getStandardContent(tree.id);
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({flag: true});
    },
    //加载更多
    jzMoreBtn() {
      if (getUserInfo()) {

        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('标准')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            this.standardPage.page++;
            this.getStandardContent(this.treeId);

          }
        } else {
          this.standardPage.page++;
          this.getStandardContent(this.treeId);
        }
      } else {
        this.showLogin()
      }

    },
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId;
      buryPoint(this.formLog);
    },
    toHref(url,item) {
      let newUrl = this.$router.resolve(url);
      if (getUserInfo()) {

        logRecords('标准','标准梳理','1','tt_standard',item.standardContentId,'',item.title,'')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('标准')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({flag: true});
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    standardTree() {
      standardTree().then((res) => {
        if (res.ok) {
          this.data = res.data;
          let id = this.data[0].children[0].id;
          this.treeTitle = this.data[0].name;
          this.keywords = this.data[0].keywords;
          this.activeName = this.data[0].name;
          this.radio = this.data[0].children[0].name;
          this.treeId = id;

          this.getStandardContent(id);
        }
      });
    },
    handleNodeClick(data) {
      let id = data.id;
      this.treeTitle = data.name;
      this.keywords = data.keywords;

      this.treeData = []
      this.load = true
      this.getStandardContent(id);
    },
    getStandardContent(id) {
      this.load = true

      let form = Object.assign(
          {},
          this.form,
      );
      form.treeId = id;

      getStandardContent(form).then((res) => {
        if (res.ok) {
          let standardContentList = res.data.standardContentList;
          let standardContentDateList = res.data.standardContentDateList;

          standardContentList.forEach((item) => {
            item.updateTime = fmtDate(
                "YYYY-mm-dd",
                item.updateTime
            );
          });

          let zhuTiList = standardContentList.filter((item) => item.pid == null)
          let qiTaList = standardContentList.filter((item) => item.pid != null)
          let standardList = [];
          zhuTiList.forEach((zhuTi) => {
            let children = []
            qiTaList.forEach((qiTa) => {
              if (zhuTi.menu == '标准主体' && zhuTi.policyContentId == qiTa.pid) {
                children.push(qiTa)
              }
            });
            zhuTi.children = children;
            standardList.push(zhuTi);
          });
          standardContentDateList.forEach((item) => {
            let children = []
            let curr = [];
            standardList.forEach((item2) => {
              if (item == item2.updateTime) {
                children.push(item2)
              }
            });
            curr.children = children;
            curr.updateTime = item;
            this.standardAllList.push(curr);
          });
          this.load = false

        }
      });

    },
    allgetStandardContent() {
      this.treeTitle = "全部";
      this.getStandardContent();
    },
    hidenLeft() {
      this.leftShow = !this.leftShow;
    },
  },
};
</script>
<style lang="scss" scoped>
//.search-form {
//  padding: 28px 40px;
//  background: #fff;
//  margin-bottom: 10px;
//}
.n-tab {
  ::v-deep {
     .el-tabs__item {
      font-size: 16px;
      height: 61px;
      line-height: 61px;
    }
    .el-tabs__nav-scroll {
      padding: 0 24px 3px;
      background: linear-gradient(to right, rgb(245, 245, 255) 0%, rgba(246, 248, 255, 0.1) 100%), url("~@/assets/img/gradientImg.png") no-repeat;
      background-position: 100% 100%;
      background-size: cover;
    }
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 24px;
    }
  } 
}
.baes {
  align-items: baseline;
}

.block {
  position: relative;
  z-index: 1;
}

.carding_left {
  display: flex;
  flex-direction: column;
  // padding: 28px 20px;
  background: #fff;
  width: 207px;
  margin-right: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  &_title {
    font-size: 18px;
    padding-top: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }

  .filter-tree {
    flex: 1;
    overflow-y: auto;
  }

  .menuFold {
    border: 1px solid #f0f0f0;
    padding: 14px 0 14px 14px;

    img {
      cursor: pointer;
    }
  }

  &.leftShow {
    width: 50px;
    height: initial;

    .filter-tree,
    .showBtn,
    .carding_left_title {
      display: none;
    }
  }
}

.carding_right {
  .table_title {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 30px;
  }

  &_item_p {
    border-bottom: 1px solid #efefef;
    padding: 15px 5px 17px 5px;
    margin-bottom: 10px;

    .p {
      padding: 0 16px;
    }
  }

  .carding_right_l {
    display: flex;
    align-items: center;
    padding: 0 14px;
    flex: 1;

    .icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 7px;
    }

    .tag {
      padding: 7px;
      background: rgba(13, 87, 188, 0.08);
      border-radius: 2px;
      margin-right: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #0d57bc;
      font-weight: bold;
    }

    .link-style {
      flex: 1;
      font-size: 14px;
      line-height: 22px;
      color: #154385;
    }
  }
}

.outer-layer-box {
  display: flex;
  flex-direction: column;

  .iframe-box {
    flex: 1;
    background: #fff;
  }
}

::v-deep .el-timeline-item__node {
  border: 1px solid #0d57bc;
  background: #fff;
}

::v-deep .el-timeline-item__tail {
  border-left: 2px solid #6f8ab2;
}

::v-deep .el-date-editor {
  width: 100%;
}

.table-title {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

::v-deep .el-radio__inner {
  display: none;
}

::v-deep .el-timeline-item__node--large {
  left: -1px;

}

::v-deep .el-radio__label {
  padding: 0;
}

::v-deep .el-radio {
  margin-right: 0;
}

.el-dropdown-link {
  cursor: pointer;
  color: #0d57bc;
}

.el-icon-arrow-down {
  font-size: 12px;
}

[flex="sb"] {
  .title {
    font-size: 30px;
    color: #0d57bc;
  }
}

.box {
  width: 100%;
  background: #fff;
  padding: 20px;
  cursor: pointer;
}

.is_active {
  background-image: linear-gradient(#eaeef6, #ffffff);
}

.search-form {
  background: #fff;
  margin-bottom: 10px;

  ::v-deep .el-tab-pane {
    padding: 0 24px 24px;
  }
}

.g-form-item {
  position: relative;
  overflow: hidden;
  clear: both;

  li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    label {
      width: 85px;
    }

    .check-position {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -100px;
    }

    &:nth-child(1) {
      float: left;
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(3) {
      float: right;
    }

    .g-form-input {
      flex: 1;
      width: 185px;

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }
}

.more-icon {
  width: 15px
}

::v-deep .el-timeline-item__timestamp {
  //background: url("../../../assets/img/jt.png")  no-repeat;
  color: #303133;
  line-height: 1;
  font-size: 16px;
  font-weight: bold;
  padding-top: 2px;
  margin-bottom: 20px;
}
</style>
<style>
.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}

.jz-more {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0d57bc;
  padding: 12px 0;
  background: url(../../../assets/img/more-gd.png) no-repeat center;
  cursor: pointer;
}
</style>
