<template>
  <router-view v-if="$route.name == '标准动态列表' || $route.name == '标准动态内容页'" />
  <PageContainer v-else>
    <!--            <VnotLogin v-if="!show" title="标准" text="标准栏目围绕标准本身从深度和广度全方位展开，无论是查询还是梳理，皆为展示真正价值。" tableTitle="最新标准" :showTips="showTips"> </VnotLogin>-->
    <template>
      <el-row style="margin-bottom:16px">
        <el-col :span="14">
          <newlistHead title="最新标准" url="assets/img/gradientImg.png" href="/standards/dynamic/newStandards/list" />
          <el-row style="width: 100%;background: #fff;">
            <!--              <el-col :span="24">-->
            <!--                <div style="margin: 20px 20px 10px 30px">-->
            <!--                  <div class="zcTitle">标准类别</div>-->
            <!--                  <el-radio-group size="small" style="height: 84px">-->
            <!--                    <div style="display: inherit;margin-right: 10px;margin-bottom: 10px;" v-for="item in standardType"-->
            <!--                         :key="item.dictValue">-->
            <!--                      <el-radio :label="item.dictLabel" border v-if="item.dictLabel!='无'"-->
            <!--                                @click.native="radioChange($event,item,'/standards/dynamic/newStandards/list?title='+item.dictValue)">-->
            <!--                        {{ item.dictLabel }}-->
            <!--                      </el-radio>-->
            <!--                    </div>-->

            <!--                  </el-radio-group>-->
            <!--                </div>-->

            <!--              </el-col>-->
            <el-col :span="24">
              <div style="margin: 20px 20px 20px 30px">
                <div class="zcTitle" style="margin-top: 0;">热门领域</div>
                <el-radio-group size="small">
                  <div
                    v-for="(item,index) in belongAreas"
                    :key="item.id"
                    style="display: inherit;margin-right: 10px;margin-bottom: 10px;"
                  >
                    <el-radio
                      :label="item.name"
                      border
                      @click.native="radioChange($event,item,'/standards/dynamic/newStandards/list?ind='+index)"
                    >
                      {{ item.name }}
                    </el-radio>
                  </div>

                </el-radio-group>
              </div>

            </el-col>
          </el-row>
          <div class="box">
            <ul class="tab-box">
              <li
                v-for="item in state"
                :key="item.dictValue"
                class="tab-list"
                :class="{'ac':ac == item.dictValue}"
                @click="tabBtn(item.dictValue)"
              >{{ item.dictLabel }}
              </li>

            </ul>

          </div>
          <ul v-loading="loading" class="newUl" style="padding-top: 25px">
            <li v-for="item in newStandardData" :key="item.id">

              <div class="newUl-link" @click="toHref('/standards/dynamic/newStandards/details/'+item.id,item,'最新标准','tt_standard')">
                <template v-if="item.state">
                  <span v-if="item.state == '未实施'" class="state">{{ item.state }}</span>
                  <span v-else-if="item.state == '作废'" class="state red">{{ item.state }}</span>
                  <span v-else-if="item.state == '现行'" class="state gre">{{ item.state }}</span>
                  <span v-else-if="item.state == '被替代'" class="state blue">{{ item.state }}</span>
                </template>
                <template v-if="item.belongAreas">
                  <div v-for="item in strSplit(item.belongAreas)" :key="item" class="newUl-tag">{{ item }}</div>
                </template>
                <!--                  <el-tooltip class="item" effect="light" :content="htmlRestore(item.title)" placement="top-start">-->

                <!--                  <div class="newUl-title" >-->

                <!--                    {{ htmlRestore(item.title) }}-->
                <!--                  </div>-->
                <!--                  </el-tooltip>-->
                <TextOverTooltip ref-name="testName1" class-name="newUl-title" :content="item.title" />

                <div class="newUl-time">{{ item.publishDate }}</div>
              </div>

            </li>
          </ul>
        </el-col>
        <el-col :span="10" style="float: right">
          <newlistHead title="征求意见稿" url="assets/img/gradientImg.png" @click="toClick" />
          <ul class="newUl ">
            <li v-for="item in solicitCommentsData" :key="item.id">
              <div class="newUl-link" @click="toHref('/standards/dynamic/newComments/details/'+item.id,item,'征求意见稿','tt_solicit_comments')">

                <template v-if="item.belongAreas">
                  <div v-for="item in strSplit(item.belongAreas)" :key="item" class="newUl-tag">{{ item }}</div>
                </template>
                <!--                  <el-tooltip class="item" effect="light" :content="htmlRestore(item.title)" placement="top-start">-->

                <!--                  <div class="newUl-title">-->

                <!--                    {{ htmlRestore(item.title) }}-->
                <!--                  </div>-->
                <!--                  </el-tooltip>-->
                <TextOverTooltip ref-name="testName2" class-name="newUl-title" :content="item.title" />

                <div class="newUl-time">{{ item.updateTime }}</div>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
      <!--        <el-row :gutter="16" style="margin-bottom:16px">-->
      <!--          <el-col :span="12">-->
      <!--            <listHead title="未实施标准" url="assets/img/11153157zuln.png" href="/standards/dynamic/newStandards/list"/>-->
      <!--            <ul class="newUl">-->
      <!--              <li v-for="item in newStandardData" :key="item.id">-->
      <!--                <div style="cursor: pointer" @click="toHref('/standards/dynamic/newStandards/details/' + item.id)"-->
      <!--                   class="newUl-link">-->
      <!--                  <template v-if="item.belongAreas">-->
      <!--                    <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>-->
      <!--                  </template>-->
      <!--                  <div class="newUl-title">{{ htmlRestore(item.title) }}</div>-->
      <!--                  <div class="newUl-time">{{ item.implementDate }}</div>-->
      <!--                </div>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </el-col>-->
      <!--          <el-col :span="12">-->
      <!--            <listHead title="征求/报批意见稿" url="assets/img/6377233672246183546.png" @click="toClick"/>-->
      <!--            <ul class="newUl">-->
      <!--              <li v-for="item in solicitCommentsData" :key="item.id">-->
      <!--                <div style="cursor: pointer" @click="toHref('/standards/dynamic/newComments/details/' + item.id)"-->
      <!--                   class="newUl-link">-->
      <!--                  <template v-if="item.belongAreas">-->
      <!--                    <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>-->
      <!--                  </template>-->
      <!--                  <div class="newUl-title">{{ htmlRestore(item.title) }}</div>-->
      <!--                  <div class="newUl-time">{{ item.updateTime }}</div>-->
      <!--                </div>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </el-col>-->
      <!--        </el-row>-->

      <!--        <el-row :gutter="16">-->
      <!--          <el-col :span="12">-->
      <!--            <listHead title="标准动态" url="assets/img/v2_3809b9f933e445dd8f3254a095f3dae4_img_000.png"-->
      <!--                      href="/standards/dynamic/listDynamic"/>-->
      <!--            <ul class="newUl">-->
      <!--              <li v-for="item in standardDynamicData" :key="item.id">-->
      <!--                <div style="cursor: pointer" @click="toHref('/standards/dynamic/contentDynamic/' + item.id)"-->
      <!--                   class="newUl-link">-->
      <!--                  <template v-if="item.belongAreas">-->
      <!--                    <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>-->
      <!--                  </template>-->
      <!--                  <div class="newUl-title">{{ htmlRestore(item.title) }}</div>-->
      <!--                  <div class="newUl-time">{{ item.updateTime }}</div>-->
      <!--                </div>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </el-col>-->
      <!--          <el-col :span="12">-->
      <!--            <listHead title="标准解读" url="assets/img/charlie-deets-D1W5QenBzlU-unsplash1.png"-->
      <!--                      href="/standards/dynamic/listUnscramble"/>-->
      <!--            <ul class="newUl">-->
      <!--              <li v-for="item in standardUnscrambleData" :key="item.id">-->
      <!--                <div style="cursor: pointer" @click="toHref('/standards/dynamic/contentUnscramble/' + item.id)"-->
      <!--                   class="newUl-link">-->

      <!--                  <template v-if="item.belongAreas">-->
      <!--                    <div class="newUl-tag" v-for="item in strSplit(item.belongAreas)" :key="item">{{ item }}</div>-->
      <!--                  </template>-->
      <!--                  <div class="newUl-title">{{ htmlRestore(item.title) }}</div>-->
      <!--                  <div class="newUl-time">{{ item.updateTime }}</div>-->
      <!--                </div>-->
      <!--              </li>-->
      <!--            </ul>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 标准动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { getUserInfo, logRecords, ydlTk } from '@/utils/auth.js'
import {
  standardDynamic,
  standardUnscramble,
  solicitComments,
  newStandard
} from '@/api/standards.js'
import { fmtDate } from '@/utils/date.js'
import { buryPoint } from '@/api/common'
import { selectDictDataByType, ttDemension } from '@/api/dict'
import TextOverTooltip from '@/components/title/index'
export default {
  components: {
    TextOverTooltip
  },
  data() {
    return {
      showTips: [
        {
          title: '标准动态',
          text: '最新权威动向和独家原创解读'
        },
        {
          title: '标准库查询',
          text: '权威标准库，多条件筛查关联比对'
        },
        {
          title: '标准梳理',
          text: '专家梳理整合，真正读懂标准脉络'
        },
        {
          title: '标准大数据',
          text: '用数据深度理解标准结构和趋势'
        }
      ],
      standardDynamicData: [],
      standardUnscrambleData: [],
      solicitCommentsData: [],
      newStandardData: [],
      loading: false,
      tableData: [],
      state: [],
      standardType: [],
      pagination: {},
      currentPage: 0,
      show: false,
      ac: '',
      belongAreas: [],
      newStandardPage: {
        pages: {
          page: 1,
          pageSize: 14
        },
        total: 0,
        currentPage: 0
      },
      formLog: {
        eventName: '开通卡片点击',
        eventType: '1',
        columnName: '标准',
        userId: ''
      }
    }
  },
  computed: {
    newDate() {
      return fmtDate('YY年mm月', new Date())
    }
  },
  created() {
    if (getUserInfo()) {
      this.show = true
    } else {
      this.show = false
    }

    this.init()
  },
  methods: {
    insertBuryPoint() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
    },
    // 显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true })
    },
    toHref(url, item, column2, tableName) {
      const newUrl = this.$router.resolve(url)
      if (getUserInfo()) {
        logRecords('标准', column2, '1', tableName, item.id, '', item.title, '')
        if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
          const menuName = getUserInfo().menuName
          if (!menuName.includes('标准')) {
            this.sqsyLogin()
            this.insertBuryPoint()
          } else {
            window.open(newUrl.href, '_blank')
          }
        } else {
          window.open(newUrl.href, '_blank')
        }
      } else {
        this.showLogin()
      }
    },
    ttDemension(params) {
      ttDemension(params).then((res) => {
        if (res.ok) {
          this.belongAreas = res.data
        }
      })
    },
    radioChange(e, tree, url) {
      if (e.target.tagName != 'INPUT') {
        return
      }
      this.carryCurrentRowCode = tree.children

      this.$router.push({
        path: url,
        query: {
          carryCurrentRowCode: this.carryCurrentRowCode
        }
      })
      // this.toHref(url);
    },
    // 标准状态
    getState() {
      selectDictDataByType({ dictType: 'mng_bz_standard_state' }).then(
        (res) => {
          if (res.ok) {
            res.data.unshift({ 'dictLabel': '全部', 'dictValue': '' })
            this.state = res.data
          }
        }
      )
    },
    // 获取字典
    getCategory() {
      selectDictDataByType({ dictType: 'standard_type' }).then(
        (res) => {
          if (res.ok) {
            this.standardType = res.data
          }
        }
      )
    },
    tabBtn(e) {
      if (getUserInfo()) {
        this.ac = e
        this.newStandard()
      } else {
        this.showLogin()
      }
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
    init() {
      this.getState()
      // this.getCategory()
      this.newStandard()
      // this.standardDynamic({page: 1, pageSize: 6});
      // this.standardUnscramble({page: 1, pageSize: 6});
      this.solicitComments({ page: 1, pageSize: 18 })
      this.ttDemension({ code: 'standardTagsThree' })
    },
    // 标准动态
    standardDynamic(params) {
      standardDynamic(params).then((res) => {
        if (res.ok) {
          this.standardDynamicData = res.data.list
        }
      })
    },
    // 标准解读
    standardUnscramble(params) {
      standardUnscramble(params).then((res) => {
        if (res.ok) {
          this.standardUnscrambleData = res.data.list
        }
      })
    },
    // 标准征求意见/报批稿
    solicitComments(params) {
      solicitComments(params).then((res) => {
        if (res.ok) {
          this.solicitCommentsData = res.data.list
        }
      })
    },
    // 最新标准
    newStandard() {
      this.loading = true
      const form = {
        ...this.newStandardPage.pages,
        state: this.ac
      }
      newStandard(form).then((res) => {
        if (res.ok) {
          this.newStandardData = res.data.list
          this.newStandardPage.total = res.data.count
          this.loading = false
        }
      })
    },
    toClick() {
      // this.$store.dispatch("common/SET_qiehuan", "zqyjg");
      this.$router.push('/standards/dynamic/newComments/list')
    },
    // 字符串转数组
    strSplit(str) {
      return str.split(',')
    }
  }
}
</script>
<style lang="scss" scoped>
.newUl-link {
  cursor: pointer;
}

.el-col-14 {
  width: 58%;
}
.radioGroup {
  display: flex;
  flex-wrap: wrap;
}
.zcTitle {
  margin-top: 10px;
  white-space: nowrap;
  padding: 8px 10px 0 0;
  font-weight: bold;
  font-size: 16px
}
.el-radio--small.is-bordered {
  margin-top: 10px;
  padding: 8px 10px 0 10px;
}
::v-deep .el-radio__inner {
  display: none;
}

::v-deep .el-timeline-item__node--large {
  left: -1px;

}

::v-deep .el-radio__label {
  padding: 0;
}

::v-deep .el-radio {
  margin-right: 0;
}

.state {
  //padding: 4px;
  //background: #f2c94c;
  //border-radius: 2px;
  //font-size: 14px;
  //line-height: 22px;
  //color: #000;
  //font-weight: bold;
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  padding: 4px;
  background: #ffd450;
  margin-right: 4px;
  border-radius: 2px;
}

.state.gre {
  background: #27ae60;
  color: #fff;
}

.state.red {
  background: #eb5757;
  color: #fff;
}

.state.blue {
  background: #0D57BC;
  color: #fff;
}

.box {
  padding: 0 30px;
  background: #fff;
}

.tab-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;

  .tab-list {
    cursor: pointer;
    font-size: 15px;
    //line-height: 10px;
    color: #000000;
    margin-right: 24px;
    margin-bottom: 18px;

    &.ac {
      position: relative;
      font-weight: bold;
      color: #0d57bc;

      &::after {
        display: block;
        content: "";
        position: absolute;
        right: 0;
        bottom: -18px;
        width: 100%;
        height: 3px;
        background: #0d57bc;
      }
    }
  }

  ::v-deep .el-input__inner {
    width: 235px;
    border-radius: 20px;
  }
}

</style>
<style>
.el-message-box {
  width: 440px;
}
.el-message-box__title {
  font-size: 16px;

}
.el-message-box__message p {
  line-height: 14px;
}
</style>
