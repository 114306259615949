import cookie from '@/plugins/cookie.js'
import Vue from 'vue'
const TokenKey = 'information-network-client'
import Element from 'element-ui'
import { accessRecords } from '@/api/common'
// 获取getToken
export function getToken() {
  return localStorage.getItem(TokenKey)
}
// 设置getToken
export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}
// 删除getToken
export function removeToken() {
  return localStorage.removeItem(TokenKey)
}
// 获取用户信息
export function getUserInfo() {
  return JSON.parse(cookie.get('userInfo'))
}
// 获取用户信息
export function getUserInfoNew() {
  let flag = false
  if (getUserInfo()) {
    if (
      getUserInfo().roleKey == 'paidrole' ||
      getUserInfo().roleKey == 'trialrole'
    ) {
      if (getUserInfo().menuName.includes('商用车')) {
        flag = true
      }
    }
  }
  return flag
}

// 获取用户信息
export function ifHidden() {
  let flag = 0
  if (getUserInfo()) {
    if (
      getUserInfo().roleKey == 'paidrole' ||
      getUserInfo().roleKey == 'trialrole'
    ) {
      if (getUserInfo().userName == 'shfule') {
        flag = 1
      }
    }
  }
  return flag
}
// 设置用户信息
// export function setUserInfo(data) {
//     return cookie.set('userInfo', JSON.stringify(data),{ expires: "7D" })
// }
export function setUserInfo(data) {
  return cookie.set('userInfo', JSON.stringify(data))
}
// 临时保存用户信息
export function restUserInfo(data) {
  return cookie.set('userInfo', JSON.stringify(data))
}
// 删除用户信息
export function removetUserInfo() {
  return cookie.delete('userInfo')
}
// 获取用户登陆状态
export function getUserState() {
  return Boolean(cookie.get('userState'))
}
// 设置用户登陆状态
export function setUserState(state) {
  return cookie.set('userState', Boolean(state), { expires: '7D' })
}
// 临时用户登陆状态
export function restUserState(state) {
  return cookie.set('userState', state)
}
// 删除用户登陆状态
export function removeUserState(state) {
  return cookie.delete('userState', state)
}
// 保存账号密码
export function setSecrecy(data) {
  return cookie.set('secrecy', JSON.stringify(data), { expires: '7D' })
}
// 获取
export function getSecrecy() {
  return JSON.parse(cookie.get('secrecy'))
}
// 临时保存
export function restSecrecy(data) {
  return cookie.set('secrecy', JSON.stringify(data))
}
// 获取面包屑的值
export function breadcrumbData(data) {
  const breadcrumbstr = data.meta.breadcrumb // 面包屑
  const lastBreadcrumbstr = breadcrumbstr[breadcrumbstr.length - 1].name + '' // 面包屑最后一项文字
  const column1 = breadcrumbstr[0].name // 面包屑第一项文字
  let column2
  if (lastBreadcrumbstr.indexOf('内容页') > -1) {
    column2 = lastBreadcrumbstr.slice(0, lastBreadcrumbstr.indexOf('内容页'))
    // console.log('内容页：', column2)
  } else if (lastBreadcrumbstr.indexOf('详情') > -1) {
    column2 = lastBreadcrumbstr.slice(0, lastBreadcrumbstr.indexOf('详情'))
    // console.log('详情：', column2)
  } else if (lastBreadcrumbstr.indexOf('内容') > -1) {
    column2 = lastBreadcrumbstr.slice(0, lastBreadcrumbstr.indexOf('内容'))
    // console.log('内容：', column2)
  }
  return { column1, column2 }
}
export function ydlTk() {
  const menuName = getUserInfo().menuName
  Vue.prototype.$alert(
    '<div style="font-size: 16px;margin-top: -15px">您的账号暂未正式开通此栏目，可申请<span style="font-weight: bold">试用/正式开通</span></div></br><div>电话：022-84379145/18846828756</div></br><div>邮箱：liwenjuan@catarc.ac.cn</div></br><div">本账号已开通<span style="font-weight: bold;color: green">' +
      menuName +
      '</span>等栏目</div>',
    {
      confirmButtonText: '知道了',
      showClose: false,
      dangerouslyUseHTMLString: true
    }
  )
}
export function ydlTk2() {
  const menuName = getUserInfo().menuName
  Vue.prototype.$alert(
    '<div style="font-size: 17px;margin-top: -15px">【提示】此栏目需要正式开通该栏目后操作，可以联系</div></br><div style="padding-left: 9px;padding-top: 4px;">电话：022-84379145/18846828756</div></br><div style="padding-left: 9px;">邮箱：liwenjuan@catarc.ac.cn</div></br><div ">本账号已开通<span style="font-weight: bold;color: green">' +
      menuName +
      '</span>等栏目</div>',
    {
      confirmButtonText: '知道了',
      showClose: false,
      dangerouslyUseHTMLString: true
    }
  )
}

export function openSy() {
  // const h = Vue.prototype.$createElement;
  // Vue.prototype.$alert({
  //     title: '提示：此操作需要正式开通该栏目后操作',
  //     message: h('p', null, [
  //         h('span', null, '电话：022-84379145/18846828756 '),
  //         h('p', null, '邮箱：yuhuiying@catarc.ac.cn ')
  //     ]),
  //     showCancelButton: false,
  //     confirmButtonText: '知道了',
  // })
  Vue.prototype.$alert(
    '<div style="font-size: 17px;margin-top: -15px">【提示】此操作需要正式开通该栏目后操作</div></br><div style="padding-left: 9px;padding-top: 4px;">电话：022-84379145/18846828756</div></br><div style="padding-left: 9px;">邮箱：liwenjuan@catarc.ac.cn</div>',
    {
      confirmButtonText: '知道了',
      showClose: false,
      dangerouslyUseHTMLString: true
    }
  )
}
export function logRecords(
  column1,
  column2,
  type,
  tableName,
  articleId,
  attachName,
  articleName,
  inputBox,
  buttonName
) {
  if (getUserInfo()) {
    console.log(getUserInfo())
    // if (
    //   getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole' || getUserInfo().roleKey == 'business' || getUserInfo().roleKey == 'market'
    // ) {
      const form = {}
      form.column1 = column1
      form.column2 = column2
      form.type = type
      form.tableName = tableName
      form.articleId = articleId
      form.attachName = attachName
      form.articleName = articleName
      form.inputBox = inputBox
      form.userId = getUserInfo().userId
      form.roleKey = getUserInfo().roleKey
      form.buttonName = buttonName
      form.userIp = getUserInfo().userIp

      accessRecords(form)
    // }
  }
}
