<template>
  <div class="login-mask" v-if="flag">
    <div class="login-box">
      <div class="close-btn" @click="close('form')">
        <i class="el-icon-close"></i>
      </div>
      <div style="display: flex">
          <img src="@/assets/img/xcx.jpg"  >
        <span style="margin-top: 65px;margin-left: 120px;font-size: 18px;font-weight: 500;"><span style="font-weight: bold;">//信息网上线小程序</span><br>欢迎您扫码体验小程序</span>

      </div>
        <div style="background-color: #CDE1FD;height: auto;margin: 0 20px;padding: 22px 10px;color:#333333;font-size: 16px;">
          <div>▶8月29日-9月10日</div>
          <div>信息网网员使用<span style="color: red">账号密码</span>登录小程序，可限时免费体验全网内容。其他用户手机号注册也可以体验部分内容</div>
          <div>▶9月10日以后</div>
          <div>信息网网员通过账号密码登录可免费使用对应PC端已开通栏目，其他栏目支持部分体验</div>
          <div style="color:grey;font-size: 15px;">
            <div>
              如果您想直接通过手机号登录
            </div>
            <div>
              1）可以在PC端登录账号密码绑定手机号，操作路径：右侧头像下拉->绑定手机号
            </div>
            <div>
              2）联系我们进行手机号绑定。
            </div>
            <div>
              注意 一个账号仅支持绑定一个手机号，如需绑定多个可联系我们协助办理
            </div>
            <div>
              我们可以免费给您升级为1企业x个账号，每个账号1个并发，每个账号都可以绑定1个手机号
            </div>
          </div>
        </div>
        <div >
          <el-button style="float: right;margin-right: 20px;margin-top: 10px;"  type="primary"  @click="close('form')">
            知道了
          </el-button>
        </div>


    </div>
  </div>
</template>
<script>
import {login} from "@/api/login.js";
import {
  setUserInfo,
  getUserInfo,
  restUserInfo,
  setUserState,
  restUserState,
  getUserState,
  getSecrecy,
  setSecrecy,
  restSecrecy
} from "@/utils/auth.js";
import store from "@/store";
import router from "@/router";

export default {
  name: "Vlogin",
  data() {
    var validateUsername = (rule, value, callback) => {
      if (this.swicthName == "手机号登录") {
        if (value === "") {
          callback(new Error("请输入帐号"));
        } else {
          callback();
        }
      }
    };

    var validatePassword = (rule, value, callback) => {
      if (this.swicthName == "手机号登录") {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          callback();
        }
      }
    };
    var validateUserPhone = (rule, value, callback) => {
      if (this.swicthName == "账号密码登录") {
        if (value === "") {
          callback(new Error("请输入手机号码"));
        } else if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
          callback(new Error("手机号码格式不正确"));
        } else {
          callback();
        }
      }
    };
    var validateYzm = (rule, value, callback) => {
      if (this.swicthName == "账号密码登录") {
        if (value === "") {
          callback(new Error("请输入验证码"));
        } else {
          callback();
        }
      }
    };
    // username: "jyc",
    //   password: "123456",
    return {
      form: {
        username: "",
        password: "",
        phone: "",
        yzm: "",
        remember: false,
      },
      rules: {
        username: [{validator: validateUsername, trigger: "blur"}],
        password: [{validator: validatePassword, trigger: "blur"}],
        phone: [{validator: validateUserPhone, trigger: "blur"}],
        yzm: [{validator: validateYzm, trigger: "blur"}],
      },
      flag: false,
      loading: false,
      swicthName: "手机号登录",
      menuName: ""
    };
  },
  created() {
    if(getUserInfo()){
      if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
        this.menuName = getUserInfo().menuName;
      }
    }

    // this.form.remember = userInfo?userInfo.remember:false;
    this.form.remember = getUserState();
    if (getUserState()) {
      this.form.username = getSecrecy().username;
      this.form.password = getSecrecy().password;
    }
  },
  methods: {
    toHref(url) {


      let newUrl = router.resolve(url);

      window.open(newUrl.href, "_blank");


    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let form = {};
          if (this.swicthName == "手机号登录") {
            form.username = this.form.username;
            form.password = this.form.password;
            form.rememberMe = this.form.remember;
          } else {
            form.phone = this.form.phone;
            form.yzm = this.form.yzm;
          }
          form.browserId = store.state.common.browserId
          login(form).then((res) => {
            if (res.ok) {
              this.$message({
                showClose: true,
                message: "登录成功",
                type: "success",
              });
              sessionStorage.removeItem('version')
              form.remember = this.form.remember;
              // let data = Object.assign(form, res.data);
              if (form.remember) {
                setUserInfo(res.data);
                setUserState(true)
                setSecrecy(form)
              } else {
                restUserInfo(res.data)
                restUserState(true)
                restSecrecy(form)
              }
              this.flag = false;
              window.location.reload();
            }
            this.loading = false;
          });
        }
      });
    },
    close() {
      this.flag = false;
      this.$emit("close");
    },
    show() {
      this.flag = true;
    },
    loginSwitch() {
      this.swicthName =
          this.swicthName == "手机号登录" ? "账号密码登录" : "手机号登录";
      this.form.yzm = ''
      this.$refs.form.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.login-mask {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}

.login-box {
  position: relative;
  width: 700px;
  background: #ffffff;
  padding-bottom: 20px;
  border-radius: 8px;
  margin: auto;

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  img {
    width: 150px;

    margin:20px;
  }

  .login-form {
    width: 360px;
    margin: 0 auto;

    .yzm-btn {
      position: absolute;
      right: 9px;
    }
  }
}
</style>