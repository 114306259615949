<template>
  <PageContainer breadcrumb>
    <Vcontent :data="data" :props="props" isContentTwo="qyjs" />

    <template slot="right">
      <Recommend title="相关推荐" type="list" :list="list" @click="tohref" />
      <ContentItem
        :title="tagText"
        type="list"
        :list="tableDataRight"
        @click="tohref"
      />
    </template>
  </PageContainer>
</template>
<script>
import { queryById, queryByPage } from "@/api/cuttingEdgeTechnology.js";
import { fmtDate } from '@/utils/date'
import Recommend from '@/components/recommends.vue'
import ContentItem from '@/components/contentItem.vue'
export default {
  components: {
    Recommend,
    ContentItem
  },
  data() {
    return {
      data: {},
      list: [],
      tagText: '最新发布',
      tableDataRight: [],
      props: {
        tag: "keywords",
        summary:"introduction",
        publishDate:"updateTime"
      },
      pageData: {
        pages: {
          page: 1,
          pageSize: 10
        },
        form: {
          contentCategory: ''
        }

      }
    };
  },
  watch: {
    $route(to, from) {
      let id = to.params.id;
      this.queryById({ id });
      // 对路由变化作出响应...
    },
  },
  created() {
    let id = this.$route.params.id;
    this.queryById({ id });
  },
  methods: {
    queryById(params) {
      queryById(params).then((res) => {
        if (res.ok) {
          this.pageData.form.contentCategory = res.data.contentCategory === 'yc'? ' ':'yc'
          if (res.data.contentCategory !== 'yc') {
            this.tagText = '原创推荐'
          } else if (res.data.contentCategory === 'yc') {
            this.tagText = '最新发布'
          }
          this.pageRight()
          this.data = res.data;
          this.list = res.data.recommendEOList;
        }
      });
    },
    pageRight() {
        let form = Object.assign({}, this.pageData.pages, this.pageData.form);
        console.log(form);
        queryByPage(form).then((res) => {
          this.tableDataRight = res.data.list.map((item) => {

            item.updateTime = fmtDate(
              "mm-dd",
              item.updateTime
            );
            return item;
          });
        });
    },
    //跳转
    tohref(item) {
      if (item.tableName == "tt_special") {
        this.$router.push("/thematicAnalysis/details/" + item.id);
      } else {
        this.$router.push("/cuttingEdgeTechnology/details/" + item.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
