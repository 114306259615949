<template>
  <PageContainer>
    <!--        <div style="margin:25px 0 20px 33px;">-->
    <!--            <img src="../../../assets/img/logoSvg.svg" />-->
    <!--        </div>-->
    <div ref="content" class="content" @mousedown="contentDown" @mouseup="contentUp" @mousemove="contentMove">
      <table class="table" border="1">
        <tr>
          <td align="right" style="width: 200px; min-width: 200px">
            <el-checkbox v-model="checked">高亮差异项</el-checkbox>

          </td>
          <td
            v-for="(item, index) in tableData.ybzp"
            :key="item + index"
            style="position: relative; min-width: 200px"
            align="center"
          >
            <img :src="item" width="200">

            <a v-if="tableData.pc.length > 1" class="del_btn" @click="del(index)"><i class="fa fa-times" /></a>
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.cpxhmc) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.cpxhmc) ? 'red' : ''">产品型号名称：</strong>
          </td>
          <td v-for="(item, index) in tableData.cpxhmc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.cpsb) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.cpsb) ? 'red' : ''">产品商标：</strong>
          </td>
          <td v-for="(item, index) in tableData.cpsb" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.qymc) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.qymc) ? 'red' : ''">企业名称：</strong>
          </td>
          <td v-for="(item, index) in tableData.qymc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.pc) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.pc) ? 'red' : ''">批次：</strong>
          </td>
          <td v-for="(item, index) in tableData.pc" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.bgkz) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.bgkz) ? 'red' : ''">产品状态：</strong>
          </td>
          <td v-for="(item, index) in tableData.bgkz" :key="item + index">
            {{ ggSate(item, tableData.cxbj[index]) }}

          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.scdz) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.scdz) ? 'red' : ''">生产地址：</strong>
          </td>

          <td v-for="(item, index) in tableData.scdz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.zhcAdd) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zhcAdd) ? 'red' : ''">注册地址：</strong>
          </td>
          <td v-for="(item, index) in tableData.zhcAdd" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.telephone) ? 'red' : ''" class="div-info">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.telephone) ? 'red' : ''">电话号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.telephone" :key="item + index">
            {{ returNull(item) }}

          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.fax) ? 'red' : ''" class="div-info">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.fax) ? 'red' : ''">传真号码：</strong>
          </td>

          <td v-for="(item, index) in tableData.fax" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.postcode) ? 'red' : ''" class="div-info">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.postcode) ? 'red' : ''">邮政编码：</strong>
          </td>

          <td v-for="(item, index) in tableData.postcode" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.ckg) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.ckg) ? 'red' : ''">外形尺寸（mm）<br>长*宽*高 ：</strong>
          </td>

          <td v-for="(item, index) in tableData.ckg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.rlzl) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.rlzl) ? 'red' : ''">燃料种类：</strong>
          </td>

          <td v-for="(item, index) in tableData.rlzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.yh) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.yh) ? 'red' : ''">油耗：</strong>
          </td>
          <td v-for="(item, index) in tableData.yh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.yjbz) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.yjbz) ? 'red' : ''">排放依据标准：</strong>
          </td>

          <td v-for="(item, index) in tableData.yjbz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.mfplgl) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.mfplgl) ? 'red' : ''">排量（ml）/功率（kw）：</strong>
          </td>

          <td v-for="(item, index) in tableData.mfplgl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.zxxs) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zxxs) ? 'red' : ''">转向型式：</strong>
          </td>

          <td v-for="(item, index) in tableData.zxxs" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.hxckg) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.hxckg) ? 'red' : ''">货厢栏板内尺寸（mm） <br>
              长*宽*高：</strong>
          </td>
          <td v-for="(item, index) in tableData.hxckg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.zhsh) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zhsh) ? 'red' : ''">轴数：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhsh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.zhj) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zhj) ? 'red' : ''">轴距（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.thps) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.thps) ? 'red' : ''">钢板弹簧片数：</strong>
          </td>

          <td v-for="(item, index) in tableData.thps" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.ltgg) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.ltgg) ? 'red' : ''">轮胎规格：</strong>
          </td>

          <td v-for="(item, index) in tableData.ltgg" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.lts) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.lts) ? 'red' : ''">轮胎数：</strong>
          </td>

          <td v-for="(item, index) in tableData.lts" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.qhlj) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.qhlj) ? 'red' : ''">轮距前/后（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qhlj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.zzl) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zzl) ? 'red' : ''">总质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.edzl) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.edzl) ? 'red' : ''">额定载质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.edzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.zbzl) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zbzl) ? 'red' : ''">整备质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zbzl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.gczl) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.gczl) ? 'red' : ''">准拖挂车总质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.gczl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.zzhl) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zzhl) ? 'red' : ''">载质量利用系数：</strong>
          </td>

          <td v-for="(item, index) in tableData.zzhl" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.bgaz) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.bgaz) ? 'red' : ''">半挂车鞍座最大允许承载质量（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.bgaz" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.edzk) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.edzk) ? 'red' : ''">额定载客（含驾驶员）（人）：</strong>
          </td>

          <td v-for="(item, index) in tableData.edzk" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.qpck) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.qpck) ? 'red' : ''">驾驶室准乘人数（人）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qpck" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.jjlqj) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.jjlqj) ? 'red' : ''">接近角/离去角（°）：</strong>
          </td>
          <td v-for="(item, index) in tableData.jjlqj" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.qxhx) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.qxhx) ? 'red' : ''">前悬/后悬（mm）：</strong>
          </td>

          <td v-for="(item, index) in tableData.qxhx" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.zgcs) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zgcs) ? 'red' : ''">最高车速（km/h）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zgcs" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.zhh) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.zhh) ? 'red' : ''">轴荷（kg）：</strong>
          </td>

          <td v-for="(item, index) in tableData.zhh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.dpqyxh1) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.dpqyxh1) ? 'red' : ''">底盘型号、生产企业及类别：</strong>
          </td>

          <td v-for="(item, index) in tableData.mdpid" :key="item + index">
            <template v-if="item.indexOf(null) == -1 && item != ''">
              <a href="#" @click="turnXh(item)">底盘id为{{ item }}，详细参数>>></a>
            </template>
            <template v-else>{{ returNull(tableData.dpqyxh1[index]) }}</template>
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.mfdjqy) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.mfdjqy) ? 'red' : ''">发动机型号及生产企业： </strong>
          </td>
          <td v-for="(item, index) in tableData.mfdjqy" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr :class="checked && gaoLiang(tableData.sbdh) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.sbdh) ? 'red' : ''">车辆识别代号（vin）：</strong>
          </td>

          <td v-for="(item, index) in tableData.sbdh" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>

        <tr :class="checked && gaoLiang(tableData.qt) ? 'red' : ''">
          <td align="right">
            <strong :class="checked && gaoLiang(tableData.qt) ? 'red' : ''">其他：</strong>
          </td>

          <td v-for="(item, index) in tableData.qt" :key="item + index">
            {{ returNull(item) }}
          </td>
        </tr>
        <tr>
          <td align="right">
            <strong>操作：</strong>
          </td>

          <td v-for="(item, index) in tableData.id" :key="item + index">
            <template v-if="item != 'null'">
              <el-button type="text" @click="printFun(item)">打印技术参数</el-button>
            </template>

          </td>
        </tr>
      </table>
    </div>
  </PageContainer>

</template>
<script>
import { ggZcCompare, historyCompare, getDpByDpid } from '@/api/announcement.js'
import ggcpzt from '@/mixin/ggcpzt.js'
export default {
  mixins: [ggcpzt],
  data() {
    return {
      checked: false,
      tableData: [],
      filterData: {},
      startObj: {
        scrollLeft: 0,
        startX: 0,
        scrollD: 0,
        moveFlag: false
      }
    }
  },
  created() {
    const hitoryPc = this.$route.query.hitoryPc
    const ids = this.$route.query.id
    if (hitoryPc == 'false') {
      this.ggZcCompare({ cpIdArrayString: ids })
    } else {
      this.historyCompare({
        cpxh: hitoryPc,
        pcArrayString: ids
      })
    }
  },
  methods: {
    turnXh(msg) {
      getDpByDpid(`/api/customerNotice/getDpByDpid`, {
        dpid: msg

      }).then((res) => {
        if (res.ok) {
          const newUrl = this.$router.resolve({
            path: '/announcement/announcementSearch/ChassisDetails/' + res.data.id
          })
          window.open(newUrl.href, '_blank')
        }
      })
    },
    // 打印printFun
    printFun(id) {
      window.open('/api/exportZcPdf/ggYzkPdf?cpId=' + id)
    },
    gaoLiang(data) {
      const arr1 = [...new Set(data)]
      if (arr1.length == 1) {
        return false
      } else {
        return true
      }
    },
    // 车辆比对
    ggZcCompare(parmas) {
      ggZcCompare(parmas).then((res) => {
        if (res.ok) {
          this.tableData = res.data
          // this.filterFun(res.data);
        }
      })
    },
    filterFun(data) {
      data.forEach((item) => {
        for (const key in item) {
          this.filterData[key].push(item[key])
        }
        this.filterData.ckg.push(
          item.chang ||
						'' + '*' + item.kuan ||
						'' + '*' + item.gao == null
            ? ''
            : item.gao || ''
        )

        this.filterData.plgl.push(
          item.mfpl || '' + '/' + item.mfgl || ''
        )

        this.filterData.hxlbckg.push(
          item.hxc || '' + '*' + item.hxk || '' + '*' + item.hxg || ''
        )

        this.filterData.ljqh.push(
          item.qlj || '' + '/' + item.hlj || ''
        )

        this.filterData.dpsclb.push(
          item.dpqyxh1 || '' + '/' + item.cplb || ''
        )

        this.filterData.fdjscqy.push(
          item.mfdj || '' + '/' + item.mfqy || ''
        )
      })
    },
    del(index) {
      const filterData = this.filterData
      for (const key in filterData) {
        filterData[key].splice(index, 1)
      }
    },
    // 车辆比对
    historyCompare(parmas) {
      historyCompare(parmas).then((res) => {
        if (res.ok) {
          this.tableData = res.data
          console.log(this.tableData)
          // this.filterFun(res.data);
        }
      })
    },
    // 按下事件
    contentDown(e) {
      this.startObj.scrollLeft = this.$refs.content.scrollLeft
      this.startObj.startX = e.pageY
      this.startObj.moveFlag = true
    },
    // 松开鼠标
    contentUp(e) {
      this.startObj.moveFlag = false
    },
    // 移动鼠标e
    contentMove(e) {
      if (this.startObj.moveFlag) {
        if (this.startObj.scrollD - e.clientX > 0) {
          this.startObj.scrollLeft = this.startObj.scrollLeft + 20
        } else if (this.startObj.scrollD - e.clientX < 0) {
          this.startObj.scrollLeft = this.startObj.scrollLeft - 20
        }
        this.startObj.scrollD = e.clientX
        this.$refs.content.scrollLeft = this.startObj.scrollLeft
        e.preventDefault()
      }
    },
    returNull(str) {
      if (str) {
        const newStr = str.replace(/null/g, '-')
        return newStr
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$allcolor: #0d57bc;

.content {
  background: #fff;
}
.table {
  width: 100%;
  border-color: $allcolor;
  word-break: break-all;
  color: #000;
  border: none;
  tr {
    td {
      padding: 5px 10px;
      border-color: $allcolor;
      word-break: break-all;
    }
    &:nth-child(odd) {
      background: #bbc8da;
    }
  }
  .del_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
td, th {
  border: 1px solid #DDD;
}

.red {
	color: red !important;
}
</style>
