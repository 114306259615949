<template>
  <div>
    <template v-for="item in menu">
      <!-- 下面的这个 v-if 是用来处理 数据栏目下的 数据表下载 这个菜单的 -->
      <el-menu-item
        v-if="item.children && item.children.length === 1 && !item.alwaysShow"
        :key="item.path"
        :index="item.children[0].path"
        @click="handleClickMenu(item.children[0])"
      >
        <template slot="title">
          <img class="active-icon" :src="require(`@/assets/img/newData/sidebar/${item.meta.activeIcon}.png`)" alt="">
          <img class="deactive-icon" :src="require(`@/assets/img/newData/sidebar/${item.meta.deactiveIcon}.png`)" alt="">
          <div class="submenu-title-inner">
            <div class="title">{{ item.children[0].name }}</div>
            <div v-if="showSubMenuLimitedFree(item.children)" class="limited-free">限时免费</div>
          </div>
        </template>
      </el-menu-item>
      <!-- 处理嵌套菜单 -->
      <el-submenu
        v-else-if="item.children && item.children.length > 0"
        :key="item.path"
        :index="item.path"
        :collapse="false"
        :title="item.name"
      >
        <template slot="title">
          <img class="active-icon" :src="require(`@/assets/img/newData/sidebar/${item.meta.activeIcon}.png`)" alt="">
          <img class="deactive-icon" :src="require(`@/assets/img/newData/sidebar/${item.meta.deactiveIcon}.png`)" alt="">
          <div class="submenu-title-inner">
            <div class="title">{{ item.name }}</div>
            <div v-if="showSubMenuLimitedFree(item.children)" class="limited-free">限时免费</div>
          </div>
        </template>
        <el-menu-item-group>
          <!-- 生成嵌套菜单的子级菜单 -->
          <el-menu-item
            v-for="child in item.children"
            :key="child.path"
            :index="child.path"
            class="my-nested-el-menu-item"
            @click="handleClickMenu(child)"
          >
            <el-tooltip
              :disabled="!child.meta.toolTip"
              placement="top"
              popper-class="menu-icon-popper"
            >
              <div slot="content">
                <div v-for="tooltipItem in child.meta.toolTip" :key="tooltipItem" class="tooltip-item">
                  ● <span class="tooltip-content">{{ tooltipItem }}</span>
                </div>
              </div>
              <i
                style="margin-right: 12px;"
                :class="[
                  child.meta.icon,
                  child.meta.icon ? '' : 'opacity-icon',
                  child.meta.toolTip && child.meta.toolTip.length === 0 ? 'hidden' : ''
                ]"
              />
            </el-tooltip>
            <div
              slot="title"
              :class="['menu-name-wrapper', child.meta.noAuthToView ? 'no-auth-to-view' : '']"
              :title="child.name"
            >
              <div class="menu-name">{{ child.name }}</div>
              <div v-if="child.meta.isLimitedFree" class="limited-free">限时免费</div>
            </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </template>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/auth.js'
import { menuRecordLog } from '@/api/common.js'

export default {
  name: 'MenuItem',
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  methods: {
    // 点击左侧菜单的导航
    handleClickMenu(item) {
      this.$router.push(item.path)
      this.menuRecordLog(item)
    },
    showSubMenuLimitedFree(children) {
      return children.some(item => item.meta.isLimitedFree)
    },
    // 访问记录
    menuRecordLog(item) {
      if (getUserInfo()) {
        const form = {
          userId: getUserInfo().userId,
          firstMenu: item.meta.acName,
          secondMenu: item.name
        }
        menuRecordLog(form).then(res => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.active-icon, .deactive-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.active-icon {
  display: none;
}

.submenu-title-inner {
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 15px;

  .title {
    flex: 1;
    width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

::v-deep {
  .el-submenu .el-menu-item {
    padding-right: 35px;
  }
  .el-submenu__title {
    display: flex;
    align-items: center;
  }

  .el-submenu.is-active {
    .el-submenu__title {
      color: #0d57bc !important;

      i {
        color: #0d57bc !important;
      }
    }

    .active-icon {
      display: inline-block;
    }

    .deactive-icon {
      display: none;
    }
  }

  .el-menu-item {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-right: 10px;

    &.is-active, &:hover {
      background: rgba($color: #0d57bc, $alpha: 0.03);
      color: #0d57bc;
    }

    &.is-active {
      .active-icon {
        display: inline-block;
      }

      .deactive-icon {
        display: none;
      }
    }
  }
}

.limited-free {
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  padding: 0 6px;
  text-align: center;
  background-color: #FF3B3B;
  color: rgba(255,255,255,0.9);
  font-size: 11px;
}

.nested-menu {
  display: flex;
  align-items: center;
}

.no-auth-to-view {
  color: gray;
}

.my-nested-el-menu-item {
  display: flex;
  align-items: center;

  .menu-name-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-name {
      flex: 1;
      width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  ::v-deep .el-tooltip.hidden {
    visibility: hidden;
  }
}

.opacity-icon {
  opacity: 0;
}

</style>
