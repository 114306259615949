<template>
  <div class="special-container">
    <div class="special">
      <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in items" :key="item.id">
          <a @click="handleClick(item)">
            <img :src="images[index]" :alt="item.title" />
            <span><span class="yc">原创报告</span></span>
            <span class="date"></span>
            <span class="title">{{ item.title }}</span>
          </a>
        </swiper-slide>

        <swiper-slide key="more" class="more">
          <router-link
            to="/thematicAnalysis/index"
            target="_blank"
          >
            <img src="@/assets/images/more.png" />
            <span>查看更多</span>
          </router-link>
        </swiper-slide>
      </swiper>

      <div class="swiper-button-prev swiper-special-prev">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="swiper-button-next swiper-special-next">
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import OfficialSwiper, { Autoplay, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';

import { getUserInfo } from '@/utils/auth';
import { page } from '@/api/thematicAnalysis';

import img01 from '@/assets/images/special-01.png';
import img02 from '@/assets/images/special-02.png';
import img03 from '@/assets/images/special-03.png';

OfficialSwiper.use([Autoplay, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 15,
        navigation: {
          nextEl: '.swiper-special-next',
          prevEl: '.swiper-special-prev',
        },
        // autoplay: {
        //   delay: 3500,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // },
      },
      images: [img01, img02, img03],
      items: [],
      logined: false,
    };
  },
  created() {
    const userInfo = getUserInfo();
    if (userInfo) {
      this.logined = true;
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      page({
        page: 1,
        pageSize: 3,
        contentCategory: 'yc',
      }).then((res) => {
        if (res.ok) {
          this.items = [...res.data.list];
        }
      });
    },
    formatter(date) {
      return moment(date).format('YYYY年MM月DD日');
    },
    handleClick(item) {
      if (!this.logined) {
        this.showLogin();
        return;
      }

      const { href } = this.$router.resolve(`/thematicAnalysis/details/${item.id}`);
      window.open(href, '_blank');
    },
    // 显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true })
    },
  },
};
</script>

<style lang="scss" scoped>
.special-container {
  position: relative;
  z-index: 1;
  padding-top: 16.6667%;
  margin-bottom: 32px;
  .special {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ::v-deep .swiper-container {
      height: 100%;
      .swiper-slide {
        width: calc(33.3333% - 10px);
        background-color: #fbfbfb;
        a {
          display: flex;
          flex-direction: column;
          padding: 8.5% 9%;
          width: 100%;
          height: 100%;
          color: #666;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          .date,
          .title {
            position: relative;
            color: #fff;
            font-size: 16px;
          }
          .yc {
            position: relative;
            color: #fff;
            font-size: 14px;
            padding: 3px 4px;
            line-height: 1;
            white-space: nowrap;
            background: #ff6e4b;
            background-image: linear-gradient(136deg, rgb(255,142,93) 0%, rgba(255,47,47,0.99608) 100%);
            border-radius: 2px;
           }
          .date {
            margin: 6px 0;
          }
        }

        &.more {
          width: calc(17% - 10px);
          a {
            justify-content: center;
            align-items: center;
            img {
              position: static;
              margin-bottom: 6px;
              width: auto;
              height: auto;
            }
          }
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      opacity: 0;
      transition: opacity .12s ease-out;
      pointer-events: none;
    }
    &:hover {
      .swiper-button-next,
      .swiper-button-prev {
        opacity: 1;
        pointer-events: auto;
        &.swiper-button-disabled {
          opacity: 0.35;
        }
      }
    }
  }
}
</style>
