<template>
  <PageContainer breadcrumb>
    <h1 class="title">{{ tableData.ggYzkEO.cpmc }}</h1>
    <div class="img-grop">
      <div class="img-box">
        <h2 class="img-title">右部照片</h2>
        <div class="img-list">
          <img :src="tableData.ggYzkEO.ybzp || defaultImg" alt=""/>
        </div>
      </div>
      <div class="img-box">
        <h2 class="img-title">后部照片</h2>
        <div class="img-list">
          <img :src="tableData.ggYzkEO.hbzp || defaultImg" alt=""/>
        </div>
      </div>
      <div class="img-box">
        <h2 class="img-title">选装照片</h2>
        <div class="img-list">
          <img :src="tableData.ggYzkEO.xzzp1 || defaultImg" alt=""/>
        </div>
      </div>
      <div class="img-box">
        <h2 class="img-title">选装照片</h2>
        <div class="img-list">
          <img :src="tableData.ggYzkEO.xzzp2 || defaultImg" alt=""/>
        </div>
      </div>
      <div class="img-box">
        <h2 class="img-title">选装照片</h2>
        <div class="img-list">
          <img :src="tableData.ggYzkEO.xzzp3 || defaultImg" alt=""/>
        </div>
      </div>
      <div class="img-box">
        <h2 class="img-title">其他照片</h2>
        <div class="img-list">
          <img :src="tableData.ggYzkEO.chfhzp || defaultImg" alt=""/>
        </div>
      </div>
    </div>
  </PageContainer>
</template>
<script>
import {ggYzk} from "@/api/announcement.js";

export default {
  data() {
    return {
      defaultImg: require("@/assets/img/nonecar.jpg"),
      tableData: {},
    };
  },
  created() {
    this.ggYzk();
  },
  methods: {
    ggYzk() {
      let id = this.$route.params.id;
      ggYzk(id).then((res) => {
        this.tableData = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
  color: #333;
  margin: 16px 0 32px;
}

.img-grop {
  overflow: hidden;
  margin-left: -16px;

  .img-box {
    width: 572px;
    height: 395px;
    float: left;
    padding: 16px;
    background: #fff;
    margin-left: 16px;
    margin-bottom: 15px;

    .img-list {
      text-align: center;
      width: 540px;
      height: 316px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .img-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #333;
      margin-bottom: 32px;
    }
  }
}
</style>
