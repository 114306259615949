/*
 * @Author: your name
 * @Date: 2021-07-23 18:07:36
 * @LastEditTime: 2021-07-23 18:07:47
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/store/modules/permission.js
 */
import {
  asyncRoutes,
  constantRoutes,
  dataQueryConstantRoutesMap,
  dataReportConstantRoute,
  dataTableDownloadConstantRoute,
  oldDataRoutes,
  resetRouter
} from '@/router'
import router from '@/router'
import { getUserInfo } from '@/utils/auth'
// import NewDataLayout from '@/layout/newDataLayout/index'
import RouterView from '@/layout/RouterView'
import Layout from '@/layout'

export const menuSub = [
  '数据',
  '二手车',
  '预测'
]
export const menuOrder = [
  '首页',
  '数据-MENU',
  ...menuSub,
  '专题分析',
  '前沿技术',
  '市场洞察',
  '国际化',
  '政策',
  '标准',
  '公告',
  '企业情报'
]

export function filterAsyncRouters(routerlist) {
  const routers = []
  const filterRouter = routerlist.filter(item => {
    if (item.visible == '0') {
      return item
    }
  })
  filterRouter.forEach(item => {
    asyncRoutes.forEach(citem => {
      if (item.name == citem.name) {
        citem.meta.id = item.id
        routers.push(citem)
      }
    })
  })
  // 最后添加404
  routers.push(
    {
      path: '/404',
      name: 'index',
      component: Layout,
      redirect: '/404',
      children: [
        {
          path: '/404',
          component: () => import('@/views/error-page/404'),
          name: '404',
          meta: {
            hidden: 1
          }
        }
      ]
    },
    {
      path: '*', redirect: '/404', meta: {
        hidden: 1
      }
    }
  )
  return routers
}

/**
 *用后端返回的数据查询菜单列表，生成并返回 新版数据栏目下所有的路由列表
 *
 * @author sk
 * @date 30/08/2023
 * @param {Array} dataQueryMenus 后端返回的新版数据栏目的数据查询下的菜单列表
 * @return {Array} 生成后的 新版数据栏目 下所有的路由列表
 */
function generateNewDataRoutes(dataQueryMenus) {
  // 遍历 数据查询 下的所有数据主题，生成 数据查询 下的所有路由表
  const dataQueryRoutes = dataQueryMenus.map(themeMenuItem => {
    // 定义当前数据主题下的所有子路由列表
    const dataThemeChildrenRoutes = []
    // 当前主题菜单将要重定向到的路由 path
    let themeMenuRedirectPath
    // 遍历当前数据主题下的子菜单列表
    themeMenuItem.children.forEach(dataThemeChild => {
      const {
        title,
        toolTip,
        isDragging,
        draggingTableName,
        isLimitedFree,
        noAuthToView,
        isDefaultOpen
      } = dataThemeChild
      const meta = {
        toolTip,
        hidden: 1,
        acName: '数据',
        isLimitedFree,
        noAuthToView,
        icon: 'el-icon-warning-outline'
      }
      // 定义该子菜单所对应的路由路径
      let dataThemeChildRoutePath
      // 如果该子菜单为 拖拉拽查询 菜单
      if (isDragging) {
        dataThemeChildRoutePath = `/data/draggingQuery/${themeMenuItem.frontPath}/${draggingTableName}`
        // 将该菜单对应的路由添加为当前数据主题下的子路由
        dataThemeChildrenRoutes.push({
          name: title,
          meta,
          props: {
            draggingTableName: draggingTableName
          },
          // 所有拖拉拽查询的菜单，都指向同一个页面组件，用path中的 tableName 进行区分
          path: dataThemeChildRoutePath,
          component: () => import('@/views/newData/draggingQuery')
        })
      } else { // 否则就是那些非动态的、仅用来展示的菜单
        const key = `${themeMenuItem.title}-${title}`
        // 如果有该菜单的路由配置
        if (dataQueryConstantRoutesMap[key]) {
          const { path, component } = dataQueryConstantRoutesMap[key]
          dataThemeChildRoutePath = path
          // 将该菜单对应的路由添加为当前数据主题下的子路由
          dataThemeChildrenRoutes.push({
            name: title,
            meta,
            path,
            component
          })
        }
      }
      // 如果还没找到当前数据主题下默认打开的子菜单，且当前子菜单是要默认打开的
      if (!themeMenuRedirectPath && isDefaultOpen) {
        themeMenuRedirectPath = dataThemeChildRoutePath
      }
    })
    // 返回生成好的 数据主题 路由
    return {
      path: `/${themeMenuItem.frontPath}`,
      // 如果该属性为 true，那么当只有一个子菜单时，也能显示出父级菜单
      alwaysShow: false,
      name: themeMenuItem.title,
      // component: NewDataLayout,
      component: RouterView,
      hasDefaultOpenChildMenu: !!themeMenuRedirectPath,
      redirect: themeMenuRedirectPath || dataThemeChildrenRoutes[0]?.path,
      meta: {
        activeIcon: 'chart-active',
        deactiveIcon: 'chart-deactive',
        moduleName: '数据查询'
      },
      children: dataThemeChildrenRoutes
    }
  })
  // 将数据报告、数据查询、数据表下载的路由一起返回
  return [dataReportConstantRoute, ...dataQueryRoutes, dataTableDownloadConstantRoute]
}

const state = {
  routes: [],
  addRoutes: [],
  pRouterId: '',
  // 新版数据栏目的路由
  newDataRoutes: []
}
const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_ROUTER_ID: (state, id) => {
    state.pRouterId = id
  },
  SET_NEW_DATA_ROUTES: (state, newDataRoutes) => {
    state.newDataRoutes = newDataRoutes
  }
}
const actions = {
  // 生成并保存基本路由，然后添加路由列表
  generateRoutes({ commit }, asyncRouters) {
    let routes = filterAsyncRouters(asyncRouters)

    if (process.env.VUE_APP_CHECKLOGIN !== 'zgqcgyxxw') {
      commit('SET_ROUTES', routes)
      router.addRoutes(routes)
      return
    }

    const userInfo = getUserInfo()
    const userMenus = userInfo?.menuName?.split(',') || []
    const isInsider = userInfo && !['paidrole', 'trialrole'].includes(userInfo?.roleKey)

    const paidMenus = new Set(menuOrder)
    const unpaidMenus = new Set()

    const sub = []
    routes.forEach((route) => {
      route.meta = route.meta || {}
      const menuName = route.name
      if (!userInfo || isInsider || userMenus.indexOf(menuName) !== -1) { // 限时免费临时加
        route.meta.paid = true
      } else if (menuName) {
        paidMenus.delete(menuName)
        unpaidMenus.add(menuName)
      }

      if (menuSub.indexOf(route.name) !== -1) {
        route.meta.hidden = 1
        sub.push(route)
      }
    })

    if (sub.length > 0) {
      const paid = !userInfo || isInsider || sub.some((item) => item.meta.paid === true)

      if (!paid) {
        paidMenus.delete('数据-MENU')
        unpaidMenus.add('数据-MENU')
      }

      const menu = {
        name: '数据-MENU',
        meta: { title: '数据', hidden: 0, sub: true, paid },
        component: RouterView,
        path: '/data-menu',
        children: sub
      }
      const index = routes.findIndex((route) => route.name === '首页')
      if (index !== -1) {
        routes.splice(index, 0, menu)
      } else {
        routes = [menu, ...routes]
      }
    }

    const unpaidOrder = [...unpaidMenus].sort((a, b) => {
      const ai = menuOrder.indexOf(a)
      const bi = menuOrder.indexOf(b)
      return ai - bi
    })
    const order = [...paidMenus, ...unpaidOrder]
    routes = routes.sort((a, b) => {
      const ai = order.indexOf(a.name)
      const bi = order.indexOf(b.name)
      return ai - bi
    })

    commit('SET_ROUTES', routes)
    router.addRoutes(routes)
  },
  getRouter({ commit, state }, item) {
    const id = state.routes.filter(citem => citem.name == item)
    commit('SET_ROUTER_ID', id[0].meta && id[0].meta.id ? id[0].meta.id : 0)
  },
  /**
   *生成并保存新版数据栏目的路由
   * @author sk
   * @date 30/08/2023
   * @param {*} { commit }
   * @param {Array} dataQueryMenus 后端返回的新版数据栏目的数据查询模块的菜单列表
   */
  saveDataQueryMenus({ commit }, dataQueryMenus) {
    const newDataRoutes = generateNewDataRoutes(dataQueryMenus)
    // 找到 数据栏目 的路由
    const dataRoute = state.routes.find(item => item.name === '数据')
    if (!dataRoute) {
      return
    }
    commit('SET_NEW_DATA_ROUTES', newDataRoutes)
  },
  /**
   *将当前系统的数据栏目 切换为新版数据栏目
   * @author sk
   * @date 30/08/2023
   * @param {*} { commit }
   */
  changeToNewDataRoute({ state }, isDefaultOpenFirstRoute) {
    // 找到 数据栏目 的路由
    const dataRoute = state.routes.find(item => item.name === '数据')
    if (!dataRoute) {
      return
    }
    // 将数据栏目的redirect改成新版数据栏目的第一个页面的path
    // 将新版路由都保存到路由表中
    dataRoute.children = state.newDataRoutes
    dataRoute.redirect = state.newDataRoutes[0].path
    // 必须调用该方法，才能生效
    resetRouter(state.routes)
    setTimeout(() => {
      if (isDefaultOpenFirstRoute) {
        router.replace(dataRoute.redirect)
      }
    }, 10)
  },
  /**
   *将当前系统的数据栏目 切换为旧版数据栏目
   * @author sk
   * @date 30/08/2023
   */
  changeToOldDataRoute({ state }) {
    // 找到 数据栏目 的路由
    const dataRoute = state.routes.find(item => item.name === '数据')
    if (!dataRoute) {
      return
    }
    dataRoute.children = oldDataRoutes
    // 将数据栏目的redirect改成旧版数据栏目的第一个页面的path
    dataRoute.redirect = oldDataRoutes[0].path
    // 必须调用该方法，才能生效
    resetRouter(state.routes)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
