<template>
  <div class="content">
    <table class="table">
      <tr>
        <td align="right" style="width: 200px;"></td>
        <td style="position: relative;" align="center">
          <img :src="tableData.ybzp" width="200"/>
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>产品型号名称：</strong>
        </td>
        <td>
          {{ returNull(tableData.cpxhmc) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>产品商标：</strong>
        </td>
        <td>
          {{ returNull(tableData.cpsb) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>企业名称：</strong>
        </td>
        <td>
          {{ returNull(tableData.qymc) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>批次：</strong>
        </td>
        <td>
          {{ returNull(tableData.pc) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>产品状态：</strong>
        </td>
        <td>
          {{ ggSate(tableData.bgkz,tableData.cxbj) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>生产地址：</strong>
        </td>
        <td>
          {{ returNull(tableData.scdz) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>注册地址：</strong>
        </td>
        <td>
          {{ returNull(tableData.zhcAdd) }}
        </td>
      </tr>
      <tr class="div-info">
        <td align="right">
          <strong>电话号码：</strong>
        </td>

        <td>
          {{ returNull(tableData.telephone) }}
        </td>
      </tr>
      <tr class="div-info">
        <td align="right">
          <strong>传真号码：</strong>
        </td>

        <td>
          {{ returNull(tableData.fax) }}
        </td>
      </tr>
      <tr class="div-info">
        <td align="right">
          <strong>邮政编码：</strong>
        </td>

        <td>
          {{ returNull(tableData.postcode) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>外形尺寸（mm）<br/>长*宽*高：</strong>
        </td>

        <td v-if="tableData.chang!='' && tableData.chang!=null">
          {{ tableData.chang }} * {{ tableData.kuan }}* {{ tableData.gao }}
        </td>
        <td v-else>
          -
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>燃料种类：</strong>
        </td>

        <td>
          {{  returNull(tableData.rlzl) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>油耗：</strong>
        </td>
        <td>
          {{  returNull(tableData.yh) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>排放依据标准：</strong>
        </td>

        <td>
          {{  returNull(tableData.yjbz) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>排量（ml）/功率（kw）：</strong>
        </td>
        <td v-if="tableData.mfpl!='' && tableData.mfgl!='' && tableData.mfpl!=null && tableData.mfgl!=null">{{ tableData.mfpl }}/{{ tableData.mfgl }}</td>
        <td v-else>-</td>
      </tr>

      <tr>
        <td align="right">
          <strong>转向型式：</strong>
        </td>
        <td>
          {{ returNull(tableData.zxxs) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>货厢栏板内尺寸（mm） <br/>
            长*宽*高：</strong>
        </td>
        <td v-if="tableData.hxc!='' && tableData.hxc!=null">{{ tableData.hxc }}* {{ tableData.hxk }}* {{ tableData.hxg }}</td>
        <td v-else>-</td>
      </tr>
      <tr>
        <td align="right">
          <strong>轴数：</strong>
        </td>

        <td>
          {{ returNull(tableData.zhsh) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>轴距（mm）：</strong>
        </td>

        <td>
          {{ returNull(tableData.zhj) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>钢板弹簧片数：</strong>
        </td>

        <td>
          {{ returNull(tableData.thps) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>轮胎规格：</strong>
        </td>

        <td>
          {{ returNull(tableData.ltgg) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>轮胎数：</strong>
        </td>

        <td>
          {{ returNull(tableData.lts) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>轮距前/后（mm）：</strong>
        </td>
        <td v-if="tableData.qlj!='' && tableData.qlj!=null">
          {{ tableData.qlj }}/ {{ tableData.hlj }}
        </td>
        <td v-else>-</td>
      </tr>
      <tr>
        <td align="right">
          <strong>总质量（kg）：</strong>
        </td>

        <td>
          {{ returNull(tableData.zzl) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>额定载质量（kg）：</strong>
        </td>

        <td>
          {{ returNull(tableData.edzl) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>整备质量（kg）：</strong>
        </td>

        <td>
          {{ returNull(tableData.zbzl) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>准拖挂车总质量（kg）：</strong>
        </td>

        <td>
          {{ returNull(tableData.gczl) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>载质量利用系数：</strong>
        </td>

        <td>
          {{ returNull(tableData.zzhl) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>半挂车鞍座最大允许承载质量（kg）：</strong>
        </td>

        <td>
          {{ returNull(tableData.bgaz) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>额定载客（含驾驶员）（人）：</strong>
        </td>

        <td>
          {{ returNull(tableData.edzk) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>驾驶室准乘人数（人）：</strong>
        </td>

        <td>
          {{ returNull(tableData.qpck) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>接近角/离去角（°）：</strong>
        </td>
        <td>
          {{ returNull(tableData.jjlqj) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>前悬/后悬（mm）：</strong>
        </td>
        <td>
          {{ returNull(tableData.qxhx) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>最高车速（km/h）：</strong>
        </td>

        <td>
          {{ returNull(tableData.zgcs) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>轴荷（kg）：</strong>
        </td>

        <td>
          {{ returNull(tableData.zhh) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>底盘型号、生产企业及类别：</strong>
        </td>

        <td @click="turnXh(tableData)" v-if="tableData.mdpid!=null && tableData.mdpid!=''">
          <a href="#">底盘id为{{ tableData.mdpid }}，详细参数>>></a>
        </td>
        <td v-else>
          {{ returNull(tableData.dpqyxh1) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>发动机型号及生产企业： </strong>
        </td>
        <td v-if="tableData.mfdj!='' && tableData.mfdj!=null">
          {{ tableData.mfdj }}/{{ tableData.mfqy }}
        </td>
        <td v-else>
          -
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>车辆识别代号（vin）：</strong>
        </td>

        <td>
          {{ returNull(tableData.sbdh) }}
        </td>
      </tr>

      <tr>
        <td align="right">
          <strong>其他：</strong>
        </td>

        <td>
          {{ returNull(tableData.qt) }}
        </td>
      </tr>
      <tr>
        <td align="right">
          <strong>操作：</strong>
        </td>

        <td>
          <template>
            <el-button type="text" @click="printFun(tableData.id )">打印技术参数</el-button>
          </template>

        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import {getDpByDpid} from "@/api/announcement.js";
import ggcpzt from "@/mixin/ggcpzt.js";
export default {
  props: {
    tableData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mixins: [ggcpzt],
  data() {
    return {};
  },
  created() {
  },
  methods: {
    //打印printFun
    printFun(id) {
      window.open("/api/exportZcPdf/ggYzkPdf?cpId=" + id);
    },
    turnXh(msg) {
      getDpByDpid(`/api/customerNotice/getDpByDpid`, {
        dpid: msg.mdpid

      }).then((res) => {
        if (res.ok) {
          let newUrl = this.$router.resolve({
            path: '/announcement/announcementSearch/ChassisDetails/' + res.data.id
          });
          window.open(newUrl.href, "_blank");
        }
      });
    },
    returNull(str) {
      if (str) {
        let newStr = str.replace(/null/g, "-");
        return newStr;
      } else {
        return "-";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$allcolor: #0d57bc;
.content {
  width: 100%;
  margin: 0 auto 20px;
  background: #fff;
  overflow-y: auto;
}

.table {
  width: 100%;
  border: none;
  border-right: none;
  color: #000;

  tr {
    border: none;

    td {
      padding: 5px 10px;
      border-color: $allcolor;
      word-break: break-all;
    }

    &:nth-child(odd) {
      background: #bbc8da;
    }
  }

  .del_btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  td,
  th {
    border: 1px solid #ddd;
  }
}
</style>
