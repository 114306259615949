<!--行业资讯
 * @Author: your name
 * @Date: 2021-07-22 15:27:51
 * @LastEditTime: 2021-07-22 15:41:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /information-vue/code/src/views/insightReport/index.vue
-->

<template>
  <PageContainer>
    <template slot="actionNav">
      <div class="tabs">
        <el-tabs v-model="tagText" @tab-click="handleClick" :before-leave="leaveTab">
          <el-tab-pane label="洞察报告" name="洞察报告" />
          <el-tab-pane label="市场动态" name="市场动态"></el-tab-pane>
        </el-tabs>
      </div>
    </template>
    <div class="box">
      <div class="title_box">
        <div flex="c">
          <div class="lable-box">
            <el-checkbox-button class="labelCheck allBtn" label="全部" border size="small" @change="kckAll"
              v-model="keywordAll">
            </el-checkbox-button>
          </div>
          <el-checkbox-group class="lable-box" v-model="form.keywords" size="small" @change="kcheck">
            <el-checkbox-button class="labelCheck" :label="item.dictValue" border v-for="item in keywords"
              :key="item.dictValue">
              {{ item.dictLabel }}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
        <div class="search-btn-box">
          <el-input v-model="title" class="search-controller" size="medium" clearable prefix-icon="el-icon-search"
            placeholder="请输入关键字" @clear="search" @keyup.enter.native="search">
            <el-select v-model="selectVal" slot="prepend" placeholder="请选择">
              <el-option label="标题" value="title"></el-option>
              <el-option label="内容" value="htmlContent"></el-option>
            </el-select>
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
      </div>
      <searchList v-if="pageShow" v-loading="loading" showBar :downId="downId" :updateTimedown="updateTimedown"
        :tableUpdateTimet="tableUpdateTimet" :table-data="list" :page-data="pageData" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" @toHref="toHref" @clickDown="clickDown">
      </searchList>
    </div>
    <template slot="right">
      <div class="index-banner-title">
        <div class="index-banner-new">
          <div class="title-tag">
            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/marketSurvey.png" alt=""></span>{{ tagText
              === '洞察报告' ? '市场动态' : '洞察报告' }}</h3>
            <div @click="moreClick" class="more">查看更多 <i class="el-icon-arrow-right"></i></div>
          </div>
          <ul class="index-new-timeLine">
            <li v-for="item in tableDataRight" :key="item.id">
              <div class="index-new-timeLine-content">
                <div style="cursor: pointer" @click="toHref(item.id, item, true)" class="index-new-link">
                  <div flex="c">
                    <div class="ycDiv" v-if="item.contentCategory == 'yc'">原创</div>
                    <!-- <div class="text-overflow" style=" padding-left: 10px;font-size: 14px;line-height: 20px;">-->
                    <TextOverTooltip refName="testName1" className="newUl-title" :content="item.title"></TextOverTooltip>
                    <!-- </div>-->
                    <div class="updateTime">{{ item.updateTime }}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="index-banner-hot" v-if="tagText === '洞察报告'">
          <div class="title-tag">
            <h3 class="title-tag-text" style=""><span><img src="@/assets/img/rankingList.png" alt=""></span>月榜排行</h3>
          </div>
          <ul class="index-new-timeLine">
            <li v-for="(item, index) in selectToList" :key="item.id">
              <div class="index-new-timeLine-content">
                <div style="cursor: pointer"
                  @click="rankingtoHref('/marketInsight/detail/insightReportDetail/' + item.article_id, item)"
                  class="index-new-link">
                  <div flex="c">
                    <span class="one" v-if="index === 0">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span class="two" v-else-if="index === 1">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span class="three" v-else-if="index === 2">{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <span class="ranking" v-else>{{ (index + 1).toString().padStart(2, '0') }}</span>
                    <TextOverTooltip refName="testName1" className="newUl-title" :content="item.article_name">
                    </TextOverTooltip>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template slot="actionBar">
      <div class="downBox" v-if="downReport > 0">
        <span> 已选中<span class="sum">{{ downReport }}</span> 篇报告（{{ downReport >= 10 ? '每次最多下载10篇' : '可继续添加' }}）</span>
        <el-button type="primary" :disabled="downdisable" @click="downAll('市场洞察','洞察报告')" :icon="downIcon">{{ downText }}</el-button>
      </div>
    </template>
  </PageContainer>
</template>
<script>
/**
 * @description: 洞察报告动态页面
 * @param {*}
 * @return {*}
 */
// import VnotLogin from "@/components/notLogin.vue";
import { fmtDate } from "@/utils/date.js";
import { getUserInfo, logRecords, ydlTk,openSy } from "@/utils/auth.js";
import { getKeyWords, queryByPage } from "@/api/insight.js";
import { marketDynamic } from "@/api/Index.js";
import { buryPoint } from "@/api/common";
import { Message } from "element-ui";
import fileMixin from '@/mixin/newFile.js'
import searchList from "@/components/searchList.vue";
import TextOverTooltip from "@/components/title/index";
import { selectDictDataByType } from "@/api/dict.js";
import { selectTop } from "@/api/thematicAnalysis.js";
export default {
  components: {
    // VnotLogin,
    TextOverTooltip,
    searchList,
  },
  data() {
    return {
      defaultUrl: require("@/assets/img/default-img.png"),
      title: "",
      downText: '立即下载',
      downdisable:false,
      downIcon:'el-icon-download',
      selectVal: 'title',
      tagText: "洞察报告",
      downReport: 0,
      downId: [],
      checkData: [],
      tableUpdateTimet: true,
      updateTimedown: true,
      showTips: [
        {
          title: "市场动态",
          text: "实时跟踪、全面扫描，最新权威市场动向",
        },
        {
          title: "洞察报告",
          text: "原创分析、深度洞察，AUTOINFO独家视点",
        },
        {
          title: "查看下载",
          text: "在线查看、下载编辑，支持原创报告PPT无限量下载",
        },
        {
          title: "全文检索",
          text: "精准检索、高效查询，支持标题、内容多种检索方式",
        }
      ],
      loading: false,
      tableDataRight: [],
      list: [],
      checkData: [],
      show: false,
      form: {
        keywords: [],
      },
      formLog: {
        eventName: "开通卡片点击",
        eventType: "",
        columnName: "市场洞察",
        userId: ""
      },
      keywords: [],
      selectToList: [],
      pageData: {
        pages: {
          page: 1,
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
      keywordAll: true,
      pageShow: true
    };
  },
  created() {
    if (getUserInfo()) {
      this.show = true;

    } else {
      this.show = false;
    }
    this.selectTop();
    this.getKeyWords();
    this.pageRight()
  },
  mixins: [fileMixin],
  watch: {
    downId: {
      deep: true,
      // immediate: true,
      handler(newVal, oldVal) {
        this.downReport = newVal.length
      },
    },
    "form.keywords": {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.keywordAll = newVal.length > 0 ? false : true
      },
    },
    tagText: {
      deep: true,
      // immediate: true,
      handler(newVal, oldVal) {
        if (newVal === '市场动态') {
          selectDictDataByType({ dictType: "release_column" }).then((res) => {
            if (res.ok) {
              this.keywords = res.data;
            }
          });
        } else {
          getKeyWords().then((res) => {
            if (res.ok) {
              this.keywords = res.data;
            }
          });
        }
        this.reset()
        this.pageRight();
      },
    },
    selectVal: {
      deep: true,
      handler(newVal, oldVal) {
        this.queryByPage();
      },
    },
  },
  methods: {
    selectTop() {
      selectTop({ column1: "市场洞察", column2: "洞察报告" }).then(res => {
        this.selectToList = res.data.filter(item => item !== null && item !== undefined);
      })
    },
    leaveTab(activeName, oldActiveName) {
      // if (!getUserInfo()) {
      //   return false;
      // }
    },
    handleClick(tab, event) {
      // if (getUserInfo()) {
      // this.pageRight();
      // } else {
      // this.form.contentCategory = "yc"
      // this.showLogin();
      // }
    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    reset() {
      this.form.keywords = [];
      this.pageData = {
        pages: {
          page: 1,
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        currentPage: 0,
      },
        this.title = ""
      this.downId = []
    },
    moreClick(tab, event) {
      // if (getUserInfo()) {
      this.tagText = this.tagText === '市场动态' ? '洞察报告' : '市场动态'
      // } else {
      // this.form.contentCategory = "yc"
      // this.showLogin();
      // }
    },
    insertBuryPoint() {
      if (getUserInfo()) {
        this.formLog.userId = getUserInfo().userId;
        buryPoint(this.formLog);
      } else {
        this.showLogin();
      }
    },
    //搜索
    search() {
      if (this.title) {
        logRecords('市场洞察', this.tagText, '4', 'tt_market_insight', '', '', '', this.title)
      }
      this.pageData.pages.pageNo = 1;
      this.pageData.pages.page = 1;
      this.queryByPage();
    },
    //显示申请试用弹窗
    sqsyLogin() {
      this.$Vlogin3({ flag: true });
    },
    clickDown(item, id) {
      if (this.downReport >= 10 && item.check !== true) {
        Message({
          showClose: true,
          message: '每次最多下载10篇',
          type: "error",
        });
        return
      } else {
        if (!this.downId.includes(id)) {
          this.downId.push(id);
        } else {
          const index = this.downId.indexOf(id);
          if (index > -1) {
            this.downId.splice(index, 1);
          }
        }
        this.checkFun(item)
      }
    },
    //获取右侧列表
    pageRight() {
      let form = Object.assign({}, this.form, this.pageData.pages);
      form.articleCategory == "全部" ? "" : form.articleCategory;
      // form.categoryIds = form.categoryIds.join(",");
      form.keywords = form.keywords.join(",");
      if (form.contentCategory == "yc") {
        form.contentCategory = "";
      } else if (form.contentCategory == " ") {
        form.contentCategory = "yc";
      }
      const fetchData = (form) => {
        const api = this.tagText === '洞察报告' ? marketDynamic : queryByPage;
        return api(form);
      };
      fetchData(form).then((res) => {
        this.tableDataRight = res.data.list.map((item) => {
          item.updateTime = fmtDate("YYYY-mm-dd", item.updateTime);
          return item;
        });
      })
      this.queryByPage();
    },
    toHref(url, item, flag) {
      this.formLog.eventType = "1";
      let newUrl = "";
      const insightReportUrl = this.$router.resolve("/marketInsight/detail/insightReportDetail/" + item.id);
      const marketDynamicUrl = this.$router.resolve("/marketInsight/detail/marketDynamicDetail?id=" + item.id);
      if (flag) {
        newUrl = this.tagText === "市场动态" ? insightReportUrl : marketDynamicUrl
      } else {
        newUrl = this.tagText === "洞察报告" ? insightReportUrl : marketDynamicUrl
      }

      if (getUserInfo()) {

        logRecords('市场洞察', this.tagText, '1', 'tt_market_insight', item.id, '', item.title, '')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {

          let menuName = getUserInfo().menuName;
          if (!menuName.includes('市场洞察')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }

    },
    rankingtoHref(url, item) {
      const newUrl = this.$router.resolve(url);
      if (getUserInfo()) {

        logRecords('市场洞察', this.tagText, '1', 'tt_market_insight', item.article_id, '', item.article_name, '')
        if (getUserInfo().roleKey == "paidrole" || getUserInfo().roleKey == "trialrole") {
          let menuName = getUserInfo().menuName;
          if (!menuName.includes('市场洞察')) {
            this.sqsyLogin();
            this.insertBuryPoint();
          } else {
            window.open(newUrl.href, "_blank");

          }
        } else {
          window.open(newUrl.href, "_blank");
        }
      } else {
        this.showLogin();
      }

    },
    //显示登录弹窗
    showLogin() {
      this.$Vlogin2({ flag: true });
    },
    handleSizeChange(val) {
      this.pageData.pages.pageSize = val;
      this.queryByPage();
    },
    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.pageNo = val;
        this.pageData.pages.page = val
        this.queryByPage();
      }
    },
    //查询所有关键字
    getKeyWords() {
      getKeyWords().then((res) => {
        if (res.ok) {
          this.keywords = res.data;
        }
      });
    },
    //关键字
    kcheck() {
      this.queryByPage();
    },
    //关键字全选
    kckAll() {
      this.form.keywords = [];
      this.queryByPage();
    },
    //列表查询
    queryByPage() {
      let form = Object.assign({}, this.form, this.pageData.pages, {  [this.selectVal]: this.title });
      if (this.tagText === '市场动态') {
        form.releaseColumn = form.keywords.join(",");
        form.keywords = []
      } else {
        form.keywords = form.keywords.join(",");
      }
      const fetchData = (form) => {
        const api = this.tagText === '市场动态' ? marketDynamic : queryByPage;
        return api(form);
      };
      this.loading = true;
      fetchData(form).then((res) => {
        this.list = res.data.list;
        this.pageData.total = res.data.count;
        this.loading = false;
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "洞察报告详情") {
      // 详情页路由
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  ::v-deep {
    .el-tabs__nav-wrap::after {
      width: 0;
    }

    .el-tabs__header {
      margin: 0;
    }

    .el-tabs__item {
      padding: 0 24px;
      color: #666;
      height: 48px;
      line-height: 48px;
      font-weight: normal;

      &:hover {
        font-weight: normal;
      }

      &.is-active {
        color: #3f76ea;
        font-weight: bold;
      }
    }

    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 0;
    }
  }
}

::v-deep {
  .el-checkbox__input {
    display: none;
  }

  .el-checkbox {
    margin-right: 8px;
    margin-left: 0;
  }

  .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0;
  }
}

.box {

  /*padding: 0 10%;*/
  // background-color: #ffffff;
  // padding-left: 10px;
  // padding-right: 320px;
  ::v-deep {
    .search-btn-box .el-input__inner {
      min-width: 200px;
    }

    .allBtn .el-checkbox-button__inner {
      padding: 8px 18px;
      border-radius: 4px;
    }

    .is-checked .el-checkbox-button__inner {
      background: #3f76ea;
      font-weight: normal;
      border: 0;
    }

    .title_box .el-select .el-input__inner {
      text-align: center;
      background: #fff;
      min-width: 80px;
      padding: 0 30px 0 12px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  .title_box {
    padding-bottom: 16px;
    overflow-x: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #f3f5f7;

    ::v-deep {
      .allBtn .el-checkbox-button__inner {
        padding: 8px 18px;
        border-radius: 4px;
      }
    }

    [flex="c"] {
      align-items: flex-start;
      padding-top: 3px;
    }
  }

}

.lable-box {
  margin-top: -8px;

  ::v-deep .labelCheck {
    margin-right: 10px;
    margin-top: 8px;

    .el-checkbox-button__inner {
      color: #333;
      border: 0;
      border-radius: 4px;
    }

    &.is-checked {
      .el-checkbox-button__inner {
        color: #fff;
      }
    }
  }
}

.search-btn-box {
  display: flex;
}

.search-controller {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.02);

  ::v-deep .el-input__inner {
    border-color: #fff;
  }

  ::v-deep .el-input-group__prepend {
    padding-left: 12px;
    padding-right: 12px;
    color: #333;
    background-color: #fff;
    border-color: #fff;

    .el-select {
      position: relative;
      margin-left: -12px;
      margin-right: -12px;

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 0;
        width: 1px;
        background-color: #f3f5f7;
      }

      .el-input__suffix {
        right: 3px;
      }
    }
  }

  ::v-deep .el-input__prefix {
    font-size: 16px;
  }

  ::v-deep .el-input-group__append {
    padding-left: 16px;
    padding-right: 16px;
    color: #fff;
    background-color: #3f76ea;
    border-color: #3f76ea;

    .el-button {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      border: none;
      font-weight: normal;
    }
  }
}

.el-message-box {
  width: 440px;
}

.el-message-box__title {
  font-size: 16px;

}

.el-message-box__message p {
  line-height: 14px;
}

.ycDiv {
  display: inline-flex;
  margin-top: 2px;
  margin-right: 4px;
  padding: 3px 4px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
  background: #ff6e4b;
  background-image: linear-gradient(136deg, rgb(255, 142, 93) 0%, rgba(255, 47, 47, 0.99608) 100%);
  border-radius: 2px;
}

.index-banner-title {
  .index-banner-new {
    background: #fff;
    padding-bottom: 18px;

    .title-tag {
      background: linear-gradient(#f2f5fd, #fff);
    }
  }

  .index-banner-hot {
    margin-top: 10px;
    padding-bottom: 18px;
    background: #fff;

    .title-tag {
      background: linear-gradient(#fff3ee, #fffbf9);
    }
  }
}

.index-new-timeLine,
.index-new-timeLine li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.index-new-timeLine {
  margin-left: 20px;
  margin-right: 20px;

  li {

    // position: relative;
    // &::before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   top: 7px;
    //   width: 8px;
    //   height: 8px;
    //   border-radius: 50%;
    //   background: #fff;
    //   border: 2px solid #0d57bc;
    //   z-index: 1;
    // }
    .index-new-timeLine-content {
      position: relative;

      .index-new-link {
        padding: 8px 0;
        display: block;
        color: #000;

        // padding-left: 20px;
        .publishDate {
          color: #e6e6e6;
        }
        .updateTime {
          color: #e6e6e6;
        }
        span {
          font-size: 16px;
          padding-right: 10px;
          font-weight: 600;
        }

        .one {
          color: #ff0000;
        }

        .two {
          color: #ff6000;
        }

        .three {
          color: #fdd58a;
        }

        .ranking {
          color: #cccccc;
        }

        div {
          span {
            font-size: 14px;
            line-height: 16px;

            &:nth-child(2) {
              flex: 1;
            }

            &:nth-child(3) {
              color: #bdbdbd;
              padding-left: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      padding-bottom: 0px;

      &::after {
        top: 0;
      }

      .index-new-timeLine-content {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.newUl-title {
  flex: 1;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  padding-right: 24px;
}

.title-tag {
  margin-bottom: 12px;
  padding: 22px 20px 0;
  background: linear-gradient(#f2f5fd, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-tag-text {
    display: inline-flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    span {
      padding-right: 10px;
      font-weight: bold;
      color: #0d57bc;
    }
  }

  .more {
    margin-right: -2px;
    color: #9ab7f4;
  }
}</style>
