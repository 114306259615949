<template>
    <div :class="bold? 'remark bold':'remark'" @mouseover="onMouseOver()">
        <slot name="img" />
        <el-tooltip :disabled="isShowTooltip" class="item" effect="dark" :content="title" placement="top">
           <span ref="remark"> {{ title }}</span>
        </el-tooltip>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        title: {
            type: String,
        },
        bold: {
            type: Boolean,
            default: false,
        },
        fontSize: {
            type: Number,
            default:9
        },
    },
    data() {
        return {
            isShowTooltip:false,
        }
    },
    computed: {},
    methods: {
        onMouseOver() {
      		const parentWidth = this.$refs.remark.parentNode.offsetWidth
      		const contentWidth = this.$refs.remark.offsetWidth
      		this.isShowTooltip = parentWidth - this.fontSize > contentWidth
    	}
    },
    created() {

    },
    mounted() {

    },
}
</script>
<style scoped lang="scss">
    .bold {
        display: -webkit-box;
        flex: 1;
        font-weight: 500;
        color: #000;
        font-size: 18px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .remark {
        display: -webkit-box;
        flex: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>